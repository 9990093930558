import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RedtailContact, RedtailSearchModel } from 'src/app/shared/models/redtail-contact.models';

@Injectable({
  providedIn: 'root'
})
export class RedtailApiService {

  constructor(private http: HttpClient) { }

  authenticate(username: string, password: string): Observable<string> {
    return this.http.post<string>('/api/Redtail/Api/Authentication', { Username: username, Password: password });
  }

  removeAuthentication(): Observable<boolean>{
    return this.http.delete<boolean>('/api/Redtail/UserKey');
  }

  getRedtailContacts(): Observable<RedtailContact[]> {
    return this.http.get<RedtailContact[]>('/api/Redtail/Api/Contacts');
  }

  isUserAuthenticated(): Observable<boolean> {
    return this.http.get<boolean>('/api/Redtail/UserKey/IsAuthenticated');
  }

  searchRedtailContacts(model: RedtailSearchModel): Observable<RedtailContact[]> {
    return this.http.post<RedtailContact[]>('/api/Redtail/Api/Contacts/Search', model);
  }

}
