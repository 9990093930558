/** This list is incomplete. Display statuses only */
export enum FirelightDisplayStatusEnum {
  complete = 'Complete',
  dataEntry = 'Data Entry',
  eDelivery = 'eDelivery',
  purged = 'Activity has been purged',
}

export enum FirelightActivityCodeEnum {
  ActiveApplication = 'ActiveApplication',
  Approved = 'Approved',
  Complete = 'Complete',
  DataEntry = 'DataEntry',
  Locked = 'Locked',
  PendingApprovals = 'PendingApprovals',
  PendingReview = 'PendingReview',
  PendingSignatures = 'PendingSignatures',
  Submitted = 'Submitted',
  UnderReview = 'UnderReview',
}