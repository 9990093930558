import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EntryResponse } from 'src/app/shared/models/generic.models';
import { UserSettingsModel } from 'src/app/shared/models/user-settings.model';

@Injectable({
  providedIn: 'root'
})
export class UserSettingsApiService {

  constructor(private http:HttpClient) { }

  get(): Observable<UserSettingsModel> {
    return this.http.get<UserSettingsModel>('/api/UserSettings');
  }

  put(userSettings:UserSettingsModel): Observable<UserSettingsModel> {
    return this.http.put<EntryResponse<UserSettingsModel>>('/api/UserSettings', userSettings)
      .pipe(map((x: EntryResponse<UserSettingsModel>) => x.Entry));

  }

}
