import { Component, Input, Output, EventEmitter } from '@angular/core';
import { fadeInOut } from 'src/app/animations';


@Component({
  selector: 'app-inline-snackbar',
  templateUrl: './inline-snackbar.component.html',
  animations: [fadeInOut]
})
export class InlineSnackbarComponent {
  @Input() data!: { class: 'is-success' | 'is-error', message: string };
  @Output() inlineSnackbarClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  close(): void {
    this.inlineSnackbarClose.emit(true);
  }

}
