<section class="flex items-center flex-none p-20 sm:p-30 border-b border-gray-lighter">
    <div class="flex justify-between items-center w-full">
        <h1 class="text-gray-600 text-xl font-bold">
            CRM Integrations
        </h1>
        <button mat-button
                matTooltip="Close Dialog"
                type="button"
                mat-dialog-close
                class="ml-auto icon-button is-primary is-inverted">
            <i class="material-icons">close</i>
        </button>
    </div>
</section>
<mat-sidenav-container appCyData="manageIntegrationsModal" class="w-full h-full" autosize>
    <mat-sidenav class="w-full h-full xs:w-1/2 sm:w-220 dark:border-gray-darker" position="start" [disableClose]="true"
                 #manageIntegrationsSidenav [mode]="manageIntegrationsConfig.Mode" [opened]="manageIntegrationsConfig.IsOpened">
        <div class="flex flex-col w-full h-full p-20 gap-y-5">
            @for (component of integrationComponents; track component){
                <button type="button" mat-button class="p-10 w-full text-sm text-left transition-colors duration-150 rounded hover:bg-opacity-50 hover:bg-gray-lighter"
                        [matTooltip]="component.Name" mat-tooltip-position="above"
                        [class]="this.selectedIntegration ===  component ? 'bg-blue-50 text-blue-dark': 'text-gray-dark'"
                        (click)="selectIntegration(component)"
                        [amplitudeEvent]="'Manage Integrations Menu: Edit ' + component.Name"
                        [id]="component.Id">
                    <img class="w-auto h-20 mr-10" src="./assets/images/icons/{{component.Img}}" attr.aria-label="{{component.Name}} Image" alt="{{component.Name}} logo">
                    <span class="w-full leading-none text-base font-normal" [innerHTML]="component.Name + ' CRM'"></span>
                </button>
            }
        </div>
    </mat-sidenav>
    <mat-sidenav-content class="flex flex-col h-full overflow-hidden bg-white dark:bg-gray-darkest" @fadeIn>
        <section class="flex flex-col flex-grow overflow-auto" [ngSwitch]="selectedIntegration?.Id">
            <app-redtail-integration class="flex h-full" *ngSwitchCase="integrationId.Redtail"></app-redtail-integration>
            <app-wealthbox-integration class="flex h-full" *ngSwitchCase="integrationId.Wealthbox"></app-wealthbox-integration>
            <app-salesforce-integration class="flex h-full" *ngSwitchCase="integrationId.Salesforce"></app-salesforce-integration>
            <p class="m-20 text-lg md:m-30" *ngIf="!selectedIntegration">No integrations available.</p>
        </section>
    </mat-sidenav-content>
</mat-sidenav-container>