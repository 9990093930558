import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserGroup } from '../../../shared/models/usergroups.models';
import { Observable } from 'rxjs';
import { EntryResponse } from 'src/app/shared/models/generic.models';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class UserGroupApiService {
  constructor(private http: HttpClient) { }

  get(userGroupId: string): Observable<UserGroup> {
    return this.http.get<EntryResponse<UserGroup>>(`/api/UserGroups/${userGroupId}`)
      .pipe(
        map((x: EntryResponse<UserGroup>) => x.Entry)
      );
  }

  getLogo(userGroupId: string): Observable<Blob> {
    return this.http.get(`/api/UserGroups/${userGroupId}/Logo`, { responseType: 'blob' });
  }
}
