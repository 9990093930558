// 06-2023 event: any

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Inject, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { forkJoin } from 'rxjs';
import { EnrollmentFormApiService } from 'src/app/core/services/http/enrollment-form-api.service';
import { states } from 'src/app/shared/constants/states.constants';
import { EnrollmentFormParameters, RadioList } from 'src/app/shared/models/enrollment.models';
import { StateBasicModel } from 'src/app/shared/models/generic.models';
import { SalesConsultantModel } from 'src/app/shared/models/salesConsultant.models';
import { SurveyAgentAnswerModel, SurveyModel, SurveyQuestion } from 'src/app/shared/models/survey.models';
import { fadeIn, fadeInOut } from '../../../animations';

@Component({
  selector: 'app-enrollment-form',
  templateUrl: './enrollment-form.component.html',
  styles: [
    `
      :host {
        display: block;
        min-height: 64rem;
      }
    `
  ],
  animations: [fadeInOut, fadeIn]
})
export class EnrollmentFormComponent implements OnInit {
  toggleNewForm = false;
  answers: SurveyAgentAnswerModel[] = [];
  canSubmit = false;
  inputFormModels: { [index: number]: string } = [];
  targetEvent!: any;
  disclaimer!: string | null;
  loading!: boolean;
  isSubmitting = false;
  radioLists: RadioList = {};
  radioListQuestions: EnrollmentFormQuestion[] = [];
  requiredQuestionCount!: number;
  salesConsultants!: SalesConsultantModel[];
  states: StateBasicModel[] = states;
  survey!: SurveyModel;
  surveyId!: number;
  questions: EnrollmentFormQuestion[] = [];
  questionType = QuestionType;

  constructor(
    private dialog: MatDialog,
    private enrollmentFormApiService: EnrollmentFormApiService,
    @Inject(MAT_DIALOG_DATA) private formParameters: EnrollmentFormParameters,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<EnrollmentFormComponent>) { }

  ngOnInit(): void {
    this.loading = true;
    this.surveyId = this.formParameters.SurveyId;
    this.disclaimer = this.formParameters.Disclaimer;
    this.targetEvent = this.formParameters.Event;

    forkJoin({ survey: this.enrollmentFormApiService.getSurvey(this.surveyId), salesConsultants: this.enrollmentFormApiService.getSalesConsultants() })
      .subscribe(response => {
        console.dir(response);
        this.survey = response.survey;
        this.salesConsultants = response.salesConsultants;
        this.configureQuestions(this.survey.Questions);
        this.requiredQuestionCount = this.questions.filter(x => x.IsRequired).length;
      }, (error) => {
        console.log('Error', error);
      }, () => {
        this.loading = false;
      });
  }

  addRadioQuestion(enrollmentFormQuestion: EnrollmentFormQuestion, questionText: string, textDescription: string | null): void {
    if (!this.radioLists[questionText]) {
      this.radioLists[questionText] = [];
    }

    const listItem = this.radioListQuestions.find(item => item.SurveyQuestionText === questionText);
    if (!listItem) {
      enrollmentFormQuestion.QuestionIds = [enrollmentFormQuestion.SurveyQuestionId];
      enrollmentFormQuestion.SurveyQuestionText = questionText;
      this.radioListQuestions.push(enrollmentFormQuestion);
    } else {
      listItem.QuestionIds.push(enrollmentFormQuestion.SurveyQuestionId);
    }

    this.radioLists[questionText].push({
      SurveyQuestionId: enrollmentFormQuestion.SurveyQuestionId,
      SurveyQuestionText: questionText,
      SurveyQuestionTextDescription: textDescription,
      PossibleAnswers: enrollmentFormQuestion.PossibleAnswers,
      IsRequired: enrollmentFormQuestion.IsRequired
    });

    this.questions.push(enrollmentFormQuestion);
  }

  addTextQuestion(enrollmentFormQuestion: EnrollmentFormQuestion, questionText: string, textDescription: string | null): void {
    enrollmentFormQuestion.Question = questionText;
    enrollmentFormQuestion.TextDescription = textDescription;

    this.questions.push(enrollmentFormQuestion);
  }

  checkboxAnswered(questionId: number, event: MatCheckboxChange): void {
    const id = parseInt(event.source.value);
    const existingAnswer = this.answers.find(answer => answer.SurveyQuestionId === questionId && answer.SurveyQuestionPossibleAnswerId === id);

    if (existingAnswer) {
      this.answers.splice(this.answers.indexOf(existingAnswer), 1);
    } else {
      this.answers.push({
        SurveyQuestionId: questionId,
        SurveyQuestionPossibleAnswerId: id,
        TextValue: 'default'
      });
    }

    this.setCanSubmit();
  }

  checkboxRequiredAnswer(questionId: number): boolean {
    const answer = this.answers.find(answer => answer.SurveyQuestionId === questionId);
    return answer ? true : false;
  }

  configureQuestions(questions: SurveyQuestion[]): void {
    questions.forEach(question => {
      const enrollmentFormQuestion: EnrollmentFormQuestion = { ...question, ...{ TextDescription: '', Question: '', QuestionIds: [] } };
      const values = enrollmentFormQuestion.SurveyQuestionText.split('|');
      const questionText = values[0];
      const textDescription = values[1] ? values[1] : null;

      if (enrollmentFormQuestion.SurveyQuestionType === 'Textarea') this.addTextQuestion(enrollmentFormQuestion, questionText, textDescription);
      else if (enrollmentFormQuestion.SurveyQuestionType === 'RadioList') this.addRadioQuestion(enrollmentFormQuestion, questionText, textDescription);
      else this.questions.push(enrollmentFormQuestion);
    });

    this.questions.sort((a, b) => a.Order - b.Order);
  }

  getClass(question: EnrollmentFormQuestion): object {
    return {
      'col-md-6': ['Address', 'City', 'State', 'Zip Code', 'Which program are you interested in', 'What is your current ESTIMATED annual gross production?'].indexOf(question.SurveyQuestionText) === -1,
      'col-md-12': question.SurveyQuestionText === 'Which program are you interested in' || question.SurveyQuestionText === 'Address',
      'col-md-6 col-md-offset-3': question.SurveyQuestionText === 'What is your current ESTIMATED annual gross production?'
    };
  }

  questionAnswered(questionId: number, id: number, answer: string): void {
    const existingAnswer = this.answers.find(answer => answer.SurveyQuestionId === questionId && answer.SurveyQuestionPossibleAnswerId === id);

    if (existingAnswer) {
      existingAnswer.SurveyQuestionPossibleAnswerId = id;
      existingAnswer.TextValue = answer;
    } else {
      this.answers.push({
        SurveyQuestionId: questionId,
        SurveyQuestionPossibleAnswerId: id,
        TextValue: answer
      });
    }

    this.setCanSubmit();
  }

  questionAnsweredNoText(questionId: number, id: number): void {
    this.questionAnswered(questionId, id, 'default');
  }

  setCanSubmit(): boolean {
    const requiredQuestions = this.questions.filter(x => x.IsRequired);
    const answersForRequiredQuestion = [];

    requiredQuestions.forEach(question => {
      const answeredQuestion = this.answers.find(x => x.SurveyQuestionId === question.SurveyQuestionId);
      if (answeredQuestion) answersForRequiredQuestion.push(answeredQuestion);
    });

    if (requiredQuestions.length === answersForRequiredQuestion.length) this.canSubmit = true;
    else this.canSubmit = false;

    return this.canSubmit;
  }

  submit(): void {
    this.isSubmitting = true;

    this.answers.forEach(answer => delete answer.SurveyQuestionId);

    this.enrollmentFormApiService.submit(this.surveyId, this.answers)
      .subscribe({
        next: () => {
          this.closeModal(true);
        },
        error: () => {
          this.snackBar.open('Form submission failed', 'Close', {
            panelClass: ['is-error']
          });
        }
      })
      .add(() => {
        this.isSubmitting = false;
      });
  }

  submitForm(): void {
    //TODO implement proper form with validation
  }

  textInputAnswered(questionId: number, id: number, event: any): void {
    const element = event.currentTarget as HTMLInputElement;
    const value = element.value;
    this.questionAnswered(questionId, id, value);
  }

  closeModal(hasEnrolled: boolean): void {
    this.dialogRef.close({ hasCompletedEnrollment: hasEnrolled });
  }
}

interface EnrollmentFormQuestion extends SurveyQuestion {
  Question: string;
  TextDescription: string | null;
  QuestionIds: number[];
}

enum QuestionType {
  Text = 1,
  SalesConsultant = 2,
  State = 3,
  Radio = 4,
  Textarea = 5,
  Checkbox = 6,
  RadioList = 7,
  FigProduct = 8,
}