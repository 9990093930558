<form class="relative w-full">
  <i class="absolute mx-10 mt-5 transform material-icons text-gray">search</i>
  <input [formControl]="parentCtrl" class="w-full px-10 pl-40 capitalize input" type="text"
         aria-label="Search Contacts" placeholder="Search Contacts"
         [matAutocomplete]="redtailSearchAuto" #trigger="matAutocompleteTrigger" />
  <mat-autocomplete #redtailSearchAuto="matAutocomplete"
                    class="flex flex-col p-10 bg-white gap-y-5 select min-h-256 is-inverted md-no-underline wrap-shadow-none"
                    [displayWith]="setDisplayValue">
    @if (isSearching) {
    <mat-option class="text-center" [value]="null">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </mat-option>
    } @else {

    @for (c of contacts; track c) {
    <mat-option class="flex justify-between w-full p-10 overflow-visible custom-mat-option" [value]="c" (click)="trigger.closePanel();">
      <div class="flex justify-between">
        <span>
          <span class="capitalize" [innerText]="c.FullName.toLowerCase()"></span>&nbsp;
          <span class="text-gray">{{c.Dob ? ' - (' + (c.Dob|date:'M/d/yyyy') + ') ' : ''}}</span>&nbsp;
          <span class="text-gray" [innerText]="c.PrimaryAddress1 ? ' - ' + c.PrimaryAddressCity + ', ' + c.PrimaryAddressState: ''"></span>
        </span>
        <span>
          @switch (c.Crm){
          @case ('Redtail') {
          <span matTooltip="Redtail" matTooltipPosition="right"><img class="ml-5 w-18 h-18" src="assets/images/icons/redtail-icon.svg" alt="Redtail logo" /></span>
          }
          @case ('Wealthbox') {
          <span matTooltip="Wealthbox" matTooltipPosition="right"><img class="ml-5 w-18 h-18" src="assets/images/icons/wealthbox-icon.svg" alt="Wealthbox logo" /></span>
          }
          @case ('Salesforce') {
          <span matTooltip="Salesforce" matTooltipPosition="right"><img class="ml-5 w-18 h-18" src="assets/images/icons/salesforce-icon.svg" alt="Salesforce logo" /></span>
          }
          }
        </span>
      </div>
    </mat-option>
    } @empty {

    @if(noContactFound) {
    <mat-option class="flex w-full h-full p-10 overflow-visible rounded custom-mat-option"
                (click)="trigger.closePanel();">
      <span class="flex w-full p-10 overflow-visible cursor-pointer hover:bg-blue-lightest custom-mat-option bg-gray-lightest" (click)="requestManualInput()" (keypress)="requestManualInput()">
        No results found. Click here to manually enter.
      </span>
    </mat-option>
    }

    @else if(hasError) {
    <mat-option class="flex w-full h-full p-10 overflow-visible rounded custom-mat-option"
                (click)="trigger.closePanel();">
      <span class="flex w-full p-10 overflow-visible cursor-pointer hover:bg-blue-lightest custom-mat-option bg-gray-lightest" (click)="requestManualInput()" (keypress)="requestManualInput()">
        An error occurred. Click here to manually enter.
      </span>
    </mat-option>
    }
    }
    }
  </mat-autocomplete>
  @if (isContact) {
  <span class="absolute right-0 flex items-center justify-center h-24 px-10 mx-10 text-sm text-white transform -translate-y-1/2 rounded-full bg-green gap-x-5 top-1/2">
    <i class="material-icons mi-18">check</i>
    <span>Contact</span>
  </span>
  }
</form>
<div class="flex flex-col mt-5 gap-y-5">
  @if (parentCtrl.touched && parentCtrl.errors?.required) {
  <span class="text-xs text-red">This field is required</span>
  }
  @if (parentCtrl.touched && parentCtrl.errors?.invalidType) {
  <span class="text-xs text-red">Please enter a valid contact</span>
  }
</div>