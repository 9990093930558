import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RiaAccount } from 'src/app/portal/components/business/components/investment-policy-list/models/riaAccount.model';
import { RiaAgent } from 'src/app/shared/models/ria.models';

@Injectable({
  providedIn: 'root'
})
export class RiaApiService {

  constructor(private http:HttpClient) { }

  getAcmAgent():Observable<RiaAgent>{
    return this.http.get<RiaAgent>('/api/Ria/Acm/Agent');
  }

  getAcmAccountsByAgentCRD(agentCRD:string):Observable<RiaAccount[]>{
    return this.http.get<RiaAccount[]>(`/api/Ria/Acm/Agents/${agentCRD}/Accounts`)
      .pipe(map((x: RiaAccount[]) => x.map(p => {
        p.DateOpened = p.DateOpened ? new Date(p.DateOpened) : null;
        return p;
      })));
  }

  exportRiaAccounts(accounts:RiaAccount[]): Observable<Blob> {
    return this.http.post<Blob>('/api/Ria/Export', accounts, {responseType: 'blob' as 'json'} );
  }
}
