
/**
 * Check if a given `value` is of a specific type `T`
 * @param value the value we're checking
 * @param prop the property that we're expecting the value of that type to have. Ex: `{person: firstName?: string; lastName: string}`. `person` should always have `person.lastName` while `person.firstName` is optional. `prop` is `'lastName'` in this case
 * @returns `true` if the type matches and `false` if not
 */
export function typeGuard<T>(value: T | undefined | null, prop: keyof T): value is T {
  if (value && (value[prop] as unknown as T)) return true;
  else return false;
}