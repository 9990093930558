import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { fadeIn } from 'src/app/animations';
import { ReportsApiService } from 'src/app/core/services/http/reports-api.service';
import { PastPolicyListState } from 'src/app/shared/models/pastPolicyListState.model';
import { InformalPolicy, InformalPolicyDetails } from 'src/app/shared/models/policy.models';

@Component({
  selector: 'app-informal-policy-details',
  templateUrl: './informal-policy-details.component.html',
  animations: [fadeIn],
})
export class InformalPolicyDetailsComponent implements OnInit {
  componentUrl = '';
  informalPolicy: InformalPolicy;
  informalPolicyDetails!: InformalPolicyDetails;
  loadingRows = false;
  pastPolicyListState!: PastPolicyListState | null;
  policyGuid!: string;

  constructor(private reportsApiService: ReportsApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute) {
    this.informalPolicy = this.router.getCurrentNavigation()?.extras?.state?.InformalPolicy;
    this.pastPolicyListState = this.router.getCurrentNavigation()?.extras?.state?.PastPolicyListState;
  }

  ngOnInit(): void {
    this.loadingRows = true;
    this.policyGuid = this.activatedRoute.snapshot.paramMap.get('policyGuid');
    this.reportsApiService.getInformalDetail(this.policyGuid).subscribe({

      next: res => {
        this.informalPolicyDetails = res;
      },
      complete: () => {
        this.loadingRows = false;
      }
    });
    this.componentUrl = this.getComponent('/Portal/Business/');
  }

  getComponent(homeUrl: string): string {
    const path: string[] = this.router.url.split(homeUrl);
    return path[1];
  }

  goBack(): void {
    window.history.back();
  }

  goToPolicyList(): void {
    if (this.pastPolicyListState != null) {
      this.router.navigate([this.pastPolicyListState.Url]);
    } else {
      this.router.navigate(['/Portal/MyBusiness/PolicyList']);
    }
  }
}
