import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SalesforceSsoService {

  constructor(private http: HttpClient) { }

  isConnected(): Observable<boolean> {
    return this.http.get<boolean>('/api/Salesforce/Sso/IsConnected');
  }

  removeAuthentication(): Observable<boolean>{
    return this.http.delete<boolean>('/api/Salesforce/Sso/Disconnect');
  }

  searchContacts(searchString: string): Observable<unknown> {
    return this.http.get<unknown>(`/api/Salesforce/Api/ContactSearch?searchString=${searchString}`);
  }

}
