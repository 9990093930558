@if (formCtrl && displayProperty) {
  <div class="flex flex-col gap-y-5">
    <label [for]="formCtrl" class="text-sm font-semibold text-gray">{{label}}</label>
    <input type="text" class="input" [formControl]="formCtrl" [matAutocomplete]="matAuto" placeholder="Select a {{label}}">
    <mat-autocomplete #matAuto="matAutocomplete" [displayWith]="getDisplayValue">
      @for (item of items | orderBy:displayProperty; track item) {
        <mat-option [value]="item">
          {{item[displayProperty]}}
        </mat-option>
      } @empty {
        <mat-option disabled>No matching results available</mat-option>
      }
    </mat-autocomplete>
    <!-- TODO: This can probably be refactored to be a bit more robust in handling different error types -->
    @if (formCtrl.touched && formCtrl.errors?.required) {
      <span class="text-xs text-red">This field is required</span>
    }
    @if (formCtrl.touched && formCtrl.errors?.invalidType) {
      <span class="text-xs text-red">Please enter a valid {{labelLower ? labelLower : 'value'}}</span>
    }
  </div>
}