import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { fadeIn, fadeInOut } from 'src/app/animations';
import { ExploreTileSizesEnum } from 'src/app/portal/components/explore/enums/explore.enum';
import { scrollToId } from 'src/app/shared/helpers/anchor-scroll.helper';
import { ExploreCategory, ExploreTile, SearchFormGroup } from 'src/app/portal/components/explore/models/explore.models';
import orderBy from 'lodash-es/orderBy';
import { delay } from 'rxjs/operators';
import { hasAll } from 'src/app/shared/helpers/search.helpers';

@Component({
  animations: [fadeIn, fadeInOut],
  selector: 'app-explore-grid-view',
  templateUrl: './explore-grid-view.component.html',
  styles: [],
  standalone: false
})
export class ExploreGridViewComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() categories: ExploreCategory[] = [];
  @Input() tiles: ExploreTile[] = [];
  filteredTiles: ExploreTile[] = [];
  exploreTileSizesEnum = ExploreTileSizesEnum;
  showFilteredTiles = false;
  searchTextSubscription = new Subscription();
  searchForm = this.fb.group({
    searchText: [null],
  }) as SearchFormGroup;
  selectedCategory?: ExploreCategory;

  constructor(
    private fb: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.subscribeToSearchTextValueChange();
  }

  ngAfterViewInit(): void {
    this.activatedRoute.fragment
      .pipe(delay(500))
      .subscribe({
        next: (fragment: string | null) => {
          if (fragment) scrollToId(fragment);
        }
      });
  }

  ngOnDestroy(): void {
    this.searchTextSubscription.unsubscribe();
  }

  subscribeToSearchTextValueChange(): void {
    this.searchTextSubscription = this.searchForm.controls.searchText.valueChanges.subscribe({
      next: res => {
        if (res) {
          this.searchTile();
        } else {
          this.showFilteredTiles = false;
        }
      }
    });
  }

  searchTile(): void {
    if (this.searchForm.invalid) return;
    const searchText = this.searchForm.value.searchText;
    this.filteredTiles = orderBy(this.tiles.filter(tile => hasAll(tile.Fields, searchText)), ['Fields.Title'], 'asc') as ExploreTile[];
    this.showFilteredTiles = true;
  }
}