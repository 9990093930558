import { Injectable } from '@angular/core';
import { FeatureToggleService } from '../featuretoggle.service';
import { AmplitudeApiService } from './amplitude-api.service';
import { PermissionService } from '../auth/permission.service';
import { Router } from '@angular/router';
import { countMapString } from 'src/app/shared/helpers/count-map.helper';
import { AmplitudeEventTypeListEnum } from 'src/app/shared/enums/amplitude.enum';
import { AmplitudeStartEAppFunnelModel, PaperAppUploadAmplitudeModel } from 'src/app/shared/models/amplitude.models';
import { SessionStoreService } from '../stores/session-store.service';
import { EAppPreferenceEnum } from 'src/app/shared/models/user-settings.model';
import { AmplitudeSurveyModel } from 'src/app/shared/models/amplitude-survey.models';
import * as amplitude from '@amplitude/analytics-browser';
import { environment } from 'src/environments/environment';
import { AmplitudeEventV2Service } from './amplitude-event-v2.service';
import { BreakpointObserver } from '@angular/cdk/layout';

@Injectable({
  providedIn: 'root'
})
export class AmplitudeEventService {
  private instance = amplitude.createInstance();
  private instanceName = 'v1';
  private eventTypeList = AmplitudeEventTypeListEnum;
  private toState?: string;
  private fromState?: string;
  private routeParamFilterList = [
    'PolicyDetailsV2',
    'PolicyDetails',
  ];
  constructor(
    private amplitudeApiService: AmplitudeApiService,
    private featureToggleService: FeatureToggleService,
    private permissionService: PermissionService,
    private sessionStore: SessionStoreService,
    private router: Router,
    private amplitudeEventV2Service: AmplitudeEventV2Service,
    private breakpointObserver: BreakpointObserver,
  ) {
  }

  /**
   * Format path to remove the first `/`
   * @param path Input path should look something like `/Portal/Path/To/State`
   * @returns  Output path should look something like `Portal/Path/To/State`
   */
  private formatPath(path?: string): string | undefined {
    return path ? path.split('/').slice(1).join('/') : undefined;
  }

  /**
   * Track navigation in Amplitude. Used for new navigation only
   */
  trackNavigationV2(): void {
    // Remove query params
    let fromState = this.router.getCurrentNavigation()?.previousNavigation?.finalUrl?.toString().split('?')[0];
    let toState = this.router.getCurrentNavigation()?.finalUrl?.toString().split('?')[0];
    const matchFromState = fromState ? this.routeParamFilterList.find(string => fromState.includes(string)) : undefined;
    const matchToState = toState ? this.routeParamFilterList.find(string => toState.includes(string)) : undefined;

    // Remove the policyId from the route since it's generating a crazy amount of unique events in Amplitude
    if (matchFromState) {
      fromState = `${fromState.split(matchFromState)[0]}${matchFromState}`;
    }
    if (matchToState) {
      toState = `${toState.split(matchToState)[0]}${matchToState}`;
    }
    this.fromState = this.formatPath(fromState);
    this.toState = this.formatPath(toState);

    if ((!!this.fromState && !!this.toState) && this.fromState !== this.toState) {
      this.logNavigationEvent({ from_state: this.fromState, to_state: this.toState }, false);
    }
  }

  /** The beginning of my masterplan to convince others for a dark mode in Portal */
  private checkDarkMode(): boolean {
    return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  }

  private sendEvent(clickTarget: string | boolean, eventType: string | boolean, eventObj: object | boolean, userProperties: object | false): void {

    const event = {
      event_type: eventType || this.eventTypeList.undefined,
      event_properties: Object.assign(
        {
          click_target: clickTarget || undefined,
          current_state: this.toState,
          logged_in_user_name: `${this.sessionStore.User?.FirstName} ${this.sessionStore.User?.LastName}`,
          logged_in_user_email: this.sessionStore.User?.Email,
          logged_in_user_type: (this.sessionStore.User.IsArcUser || this.sessionStore.User?.Email?.includes('@figmarketing.com')) ? 'fig' : this.sessionStore.OnBehalfOfUser ? 'back_office' : 'self',
          version: 3
        }, eventObj),
      user_properties: Object.assign({
        environment: this.featureToggleService.getEnvironment(),
        hasElevate: this.permissionService.hasOnePermission('Elevate:Access') ? true : false,
        hasElevateForLife: this.permissionService.hasOnePermission('ElevateForLife:Access') ? true : false,
        hasEitherElevate: this.permissionService.hasOnePermission('ElevateForLife:Access|Elevate:Access') ? true : false,
        eAppPreference: this.sessionStore?.UserSettings?.EAppPreference || EAppPreferenceEnum.None,
        isRedtailUser: !!this.sessionStore.User.IsRedtailUser,
        isWealthboxUser: !!this.sessionStore.IsWealthboxUser,
        isSalesforceuser: !!this.sessionStore.IsSalesforceUser,
        isDarkMode: this.sessionStore.OnBehalfOfUser ? null : this.checkDarkMode(),
        isToTUser: !!this.sessionStore.User.IsToTUser,
        salesTeam: this.sessionStore.SalesTeam || undefined,
        userName: this.sessionStore.OnBehalfOfUser ? `${this.sessionStore.OnBehalfOfUser?.FirstName} ${this.sessionStore.OnBehalfOfUser?.LastName}` : `${this.sessionStore.User?.FirstName} ${this.sessionStore.User?.LastName}`, // on behalf of
        userEmail: this.sessionStore.OnBehalfOfUser ? this.sessionStore.OnBehalfOfUser.Email : this.sessionStore.User.Email,
      }, userProperties)
    };
    // TODO:
    // This service is deprecated & will soon be removed end of year (2024).
    // Currently using this service as a shell to reroute all event capture to v2 but will soon relace with the latest service & delete
    if (environment.qa) {
      console.info(event);
    }
  }


  /**
   * Logs a simple "Click" `event_type`
   * @param clickTarget specify the interaction element `click_target` that user can click on for querying
   * @param eventProperties additional event properties to pass into the event
   */
  logClickEvent(clickTarget: string, eventProperties?: object): void {
    this.sendEvent(clickTarget, this.eventTypeList.click, eventProperties || false, false);
    this.amplitudeEventV2Service.logClickEvent(clickTarget, eventProperties);
  }

  /**
   * Logs a custom event
   * @param clickTarget specify the interaction element `click_target` that user can click on for querying
   * @param eventType specify the `event_type` for querying. This is what you search for in Amplitude so choose your name wisely
   * @param eventProperties additional custom event properties for querying
   */
  logCustomEvent(clickTarget: string, eventType: string, eventProperties: object | false, userProperties: object | false): void {
    this.sendEvent(clickTarget, eventType, eventProperties, userProperties);
    this.amplitudeEventV2Service.logCustomEvent(eventType, clickTarget, eventProperties, userProperties);
  }

  /**
   * Logs a "Navigation" `event_type`, contains state/routing information for querying
   * @param eventObj additional custom event properties for querying. Use snake_casing to ensure consistency in Amplitude. I.e. `obj_property`
   */
  logNavigationEvent(eventObj: { from_state: string; to_state: string }, userProperties: object | false): void {
    this.sendEvent(false, this.eventTypeList.navigation, eventObj, userProperties);
  }

  /**
   * Logs a custom `event_type` called "Business Reporting by Report Type"
   * @param reportType the product type of the policy list. Ex: Fixed, Variable, etc.
   * @param policyListType The status if the plicy list. Ex: Pending, Inforce, Lifecycle, etc.
   * @param policyCount the number of policy, which will then be group into several pre-defined thresholds. I.e. '0-100', '101-200', etc.
   */
  logBusinessReporting(reportType: string, policyListType: string, policyCount: number,): void {
    this.sendEvent(false, this.eventTypeList.businessReportingByReportType, {
      report_type: reportType,
      policy_list_type: policyListType,
      policy_count: countMapString(policyCount)
    }, false);
    this.amplitudeEventV2Service.logBusinessReporting(reportType, policyListType, policyCount);
  }

  /**
   * Logs a custom `event_type` called "Paper App Upload - `model.step`"
   * @param model model specifying the `step` in the upload, `click_target`, `file_count`
   */
  logPaperAppUpload(model: PaperAppUploadAmplitudeModel): void {
    this.sendEvent(String(model.click_target), `${this.eventTypeList.paperAppUpload} - ${model.step}`, model, false);
    this.amplitudeEventV2Service.logPaperAppUpload(model);
  }

  updateUserProperties(userProperties: object): void {
    this.sendEvent(false, 'Update User Properties', false, userProperties);
    this.amplitudeEventV2Service.updateUserProperties(userProperties);
  }

  /**
   * Used for funnel chart tracking percentage of fallout users when going through the Start E-App workflow.
   * This thing is used in various places within the E-App process so please Ctrl+F & make sure nothing breaks because nobody will notice until it's too late!
   * @param stepName Each step in the funnel workflow
   * @param formValue Extrapolated `start-application.form.component` form value
   */
  logEAppFunnel(stepName: 'Start Workflow' | 'Complete Form' | 'Submit Application' | 'End Workflow Success' | 'Abandon Workflow', formValue: AmplitudeStartEAppFunnelModel): void {
    this.sendEvent(undefined, `E-App Funnel - ${stepName}`, formValue, false);

    this.amplitudeEventV2Service.logEAppFunnel(stepName, formValue);
  }

  logSurveyAnswer(surveyName: string, surveyModel: AmplitudeSurveyModel): void {
    this.sendEvent(undefined, `Survey - ${surveyName}`, surveyModel, false);

    this.amplitudeEventV2Service.logSurveyAnswer(surveyName, surveyModel);
  }
}