
import { AmplitudeEventService } from 'src/app/core/services/amplitude/amplitude-event.service';
import { Directive, Input } from '@angular/core';
import { HostListener } from '@angular/core';
import { AmplitudeEventTypeListEnum } from '../enums/amplitude.enum';
import { AmplitudeDirectiveObject } from '../models/amplitude.models';

@Directive({
  // disabling as the rule won't fit our existing, and legacy applications of the directive
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[amplitudeEvent]'
})
export class AmplitudeEventDirective {

  eventType: string = AmplitudeEventTypeListEnum.click;
  clickTarget = '';
  eventObject: object = {};
  //HTML element example:
  //<element... [amplitudeEvent]="'Header Eapp Menu: Firelight' + tile.Name" ...>
  //<element... amplitudeEvent="DashButton" [amplitudeEventOptions]="{'EventType': 'Custom', 'EventObject':{'FakeLabel': getComponent()}}" ...>

  constructor(
    private amplitudeEventService: AmplitudeEventService) { }

  @HostListener('click', ['$event']) onClick(): void {
    if (!this.eventType || this.eventType === AmplitudeEventTypeListEnum.click) {
      this.amplitudeEventService.logClickEvent(this.clickTarget);
    } else {
      this.amplitudeEventService.logCustomEvent(this.clickTarget, this.eventType, this.eventObject, false);
    }
  }

  //Default directive
  @Input() set amplitudeEvent(clickTarget: string) {
    this.clickTarget = clickTarget ? clickTarget : undefined;
  }

  //set optional options for amplitude event
  @Input() set amplitudeEventOptions(amplitudeObject: AmplitudeDirectiveObject) {
    this.eventType = amplitudeObject.EventType ? amplitudeObject.EventType : AmplitudeEventTypeListEnum.click;
    this.eventObject = amplitudeObject.EventObject ? amplitudeObject.EventObject : {};
  }
}
