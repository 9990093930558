<section class="flex flex-col">
  <section class="flex p-60 bg-gradient-to-br from-blue-50 to-blue-200">
    <img src="/assets/images/misc/annual-statement-graphics.svg" alt="Account Statement Graphics" class="w-full h-full m-auto">
  </section>
  <section class="flex py-60 px-30">
    <div class="flex flex-col items-center gap-y-20">
      <span class="flex w-48 h-48 rounded-full bg-blue-50">
        <i class="m-auto text-blue-500 material-icons">star</i>
      </span>
      <h1 class="text-2xl font-bold">Lifecycle Just Got an Upgrade!</h1>
      <p class="text-lg min-w-460">Download the latest annual statement for Allianz annuity policies with just one click! Simply click <strong>Download Annual Statement</strong> within a specific policy to view.</p>
    </div>
  </section>
  <section class="flex border-t border-gray-200 p-30">
    <button class="ml-auto button is-primary" mat-button type="button" (click)="goTo()">Go to Lifecycle</button>
  </section>
</section>