import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AmplitudeApiService {

  constructor(private http:HttpClient) { }

  logEvent(eventObj: unknown):Observable<unknown>{
    return this.http.post<unknown>('/api/amplitude', eventObj);
  }
}
