import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { StartApplicationFormComponent } from 'src/app/portal/features/e-application/components/start-application-form/start-application-form.component';

@Component({
  selector: 'app-start-crm-eapp',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './start-crm-eapp.component.html',
})
export class StartCrmEappComponent {
  @Input() crmName?: string;

  constructor(
    private dialog: MatDialog
  ){}

  openStartEAppModal(): void {
    this.dialog.open<StartApplicationFormComponent, { isModalView: boolean }>(StartApplicationFormComponent, {
      data: {
        isModalView: true,
      }
    });
  }
}
