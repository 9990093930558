import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import orderBy from 'lodash-es/orderBy';
import { Note } from 'src/app/shared/models/policy.models';
@Component({
  selector: 'app-case-status-notes',
  templateUrl: './case-status-notes.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CaseStatusNotesComponent {
  caseStatusNotes: Note[] = this.data.notes;
  orderBy = orderBy;
  constructor(@Inject(MAT_DIALOG_DATA) private data: { notes: Note[] }) { }

  filterExternalUsers(note: Note): boolean {
    return note.IsExternalUser === false;
  }
}

