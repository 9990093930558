import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FastTrackToken, IcomplyTransaction, IcomplyUserIsActive } from 'src/app/shared/models/icomply.models';

@Injectable({
  providedIn: 'root'
})
export class IcomplyApiService {

  constructor(private http: HttpClient) { }

  getFastTrackToken(): Observable<FastTrackToken> {
    return this.http.get<FastTrackToken>('/api/iComply/Token/FastTrack');
  }

  getUserTransactions(): Observable<IcomplyTransaction> {
    return this.http.get<IcomplyTransaction>('/api/iComply/Transactions');
  }

  getIcomplyUserIsActive(): Observable<IcomplyUserIsActive> {
    return this.http.get<IcomplyUserIsActive>('/api/iComply/User');
  }

  postConfigureUser(): Observable<string> {
    return this.http.post<string>('/api/iComply/User/Configure', null);
  }
}
