import { InjectionToken } from '@angular/core';
import { ResourcePage } from '../../../../shared/models/resource-page.models';
import { MarketingStrategy } from '../../../../shared/models/marketingStrategy.models';

export const RESOURCE_PAGE_CONSTANTS = new InjectionToken('User Configuration', {
  factory: (): ResourcePage[] => {
    return [
      {
        Url: 'TODO',
        Id: '0',
        Title: 'TODO',
        Template: `
        <p>
            TODO: this resource page
        </p>
        `,
        // Img: 'adllogo.png',
        // LearnMoreUrl: 'https://figmarketing.dailylivingadvice.com'
      },
      {
        Url: 'ActiveDailyLiving',
        Id: '0',
        Title: 'Active Daily Living',
        Template: `<p>Active Daily Living is a turnkey client solution that offers personalized content and tools to help identify and manage at-risk clients to build ongoing communication with family caregivers and seniors alike. Add value to your clients' lives, enhance your offerings, and instill long-term relationships with this ready-to-use platform.
        </p>`,
        Img: 'adllogo.png',
        LearnMoreUrl: 'https://figmarketing.dailylivingadvice.com'
      },
      {
        Url: 'Next',
        Id: '1',
        Title: 'Continuity &amp; Succession',
        EnrollmentSurveyData: {
          Event: 'Thank you for your submission!',
          SurveyIds: [7],
          Disclaimer: ' '
        },
        ViewPermissions: 'Next:View',
        AccessPermissions: 'Next:Access',
        Template: `
        <p class="mb-10">
                <strong>Successful Succession begins and ends with you.</strong> The entire process, firm enrichment, and exit plan should be carefully constructed to accomplish all you desire in transition from a 24/7 mentality, to a lessened pace or complete retirement. Succession planning will involve multiple parties from existing employees and potential partners, to financiers and family. The tactical stages needed to succeed are filled with numerous requirements, traps, and necessity for strict detail every step of the way.
            </p>
            <p class="mb-10">
                Introducing <strong><em>Next</em></strong>, an FIG proprietary continuity and succession model exclusively for our advisors. <strong><em>Next</em></strong> delivers a complete succession model called Successful Succession. It includes a coaching process, materials and side-by-side guidance to accompany you on your journey. We invite you to work with us to create the ideal plan for your future.
                We trust you will find strategy in this guide and look forward to tailoring your own Successful Succession.
            </p>
            <p>
            <a class="" href="mailto:dave.hostetler@figmarketing.com" target="_blank" aria-label="Contact Dave Hostetler">Click Here&nbsp;</a>to contact Dave Hostetler, VP Strategic Development
            </p>
        `,
        LearnMoreUrl: 'https://www.figmarketing.com/blog/wp-content/uploads/2017/07/Next-Brochure-lores.pdf',
        WistiaId: 'ufhxsc80g2',
        AdditionalResourceGroups: [
          {
            GroupName: 'Case Studies',
            AdditionalResources: [
              {
                Icon: 'article',
                Name: 'Next Case Study 1',
                Url: 'Next_Case_Study_1.pdf',
              },
              {
                Icon: 'article',
                Name: 'Next Case Study 2',
                Url: 'Next_Case_Study_2.pdf',
              },
              {
                Icon: 'article',
                Name: 'Next Case Study 3',
                Url: 'Next_Case_Study_3.pdf',
              },
            ]
          },
          {
            GroupName: 'Client Letters',
            AdditionalResources: [
              {
                Icon: 'article',
                Name: 'Continuity - Client Letter 1',
                Url: 'Continuity-Client_Letter_1.docx',
              },
              {
                Icon: 'article',
                Name: 'Continuity - Client Letter 2',
                Url: 'Continuity-Client_Letter_2.docx',
              },
            ]
          },
          {
            GroupName: 'Continuity Agreement',
            AdditionalResources: [
              {
                Icon: 'article',
                Name: 'SAMPLE Advisor Continuity Agreement',
                Url: 'SAMPLE_Advisor_Continuity_Agreement.pdf',
              },
            ]
          },
          {
            GroupName: 'Continuity Quagmires',
            AdditionalResources: [
              {
                Icon: 'article',
                Name: 'Continuity Quagmires',
                Url: 'Continuity_Quagmires.pdf',
              },
            ]
          },
          {
            GroupName: 'Webinars',
            AdditionalResources: [
              {
                Name: 'Next Webinar 1',
                WistiaId: 'tmy0h6uax1',
              },
              {
                Name: 'Continuity Webinar 1',
                WistiaId: '8nulaf2pqu',
              },
            ]
          },
          {
            GroupName: 'Guide to Successful Succession',
            AdditionalResources: [
              {
                Icon: 'article',
                Name: 'Next Successful Succession 12 Steps',
                Url: 'Next_Successful_Succession_12_Steps.pdf',
              },
              {
                Icon: 'article',
                Name: 'Next Brochure',
                Url: 'Next_Brochure.pdf',
              },
            ]
          },
        ]
      },
      {
        Url: 'OfficeDepot',
        Id: '2',
        Img: 'office_depot_max_logo.png',
        Title: 'Office Depot',
        Template: `
        <p class="mb-10">
                Financial Independence Group has partnered with Office Depot to offer discounted prices on office supplies, office furniture, paper, services, and more.
            </p>
            <p class="mb-10">
                <strong>To shop online:</strong>
            <br />
                Register with Office Depot using the link to the right to access your savings.
            </p>
            <p class="mb-10" style="padding-bottom:0;">
                <strong>Like to shop in store?</strong>
           <br />
                Option 1:  Text FIGCard to 555-888 to receive a store purchasing card on your phone.
           <br />
                Option 2: <a class="font-semibold text-blue-dark" href="https://community.officedepot.com/Com2SPCCardTemplate?id=08206913" target="_blank">Click here</a> to print your store purchasing card.
            </p>
            <p class="mb-10"><strong>If you have any issues registering or logging in, contact:</strong></p>
            <div class="m-10 mb-20 flex flex-col">
                <p>Esther Rodriguez</p>
                <a class="font-semibold text-blue-dark" href="mailto:esther.rodriguez@excelerateamerica.com">Esther.Rodriguez@excelerateamerica.com</a>
                858-336-7509
                </div>
                <div class="m-10 mb-20 flex flex-col">
                <p>Heidi Wilcox</p>
                <a class="font-semibold text-blue-dark" href="mailto:heidi.wilcox@excelerateamerica.com">Heidi.Wilcox@excelerateamerica.com</a>
                619-750-7241
            </div>
            <p class="pb-0">
                <strong>Savings Summary:</strong>
            </p>
            <ul class="list-disc list-inside mb-10">
                <li>Up to 55% off office essentials including paper, ink, and toner.</li>
                <li>Up to 70% off copy & print needs and 40% off finishing.</li>
                <li>Up to 55% off cleaning and breakroom supplies.</li>
                <li>Special pricing on furniture and technology.</li>
                <li>5-10 % off almost everything else.</li>
            </ul>
            <p>
                <em>Note: The use of marketing points is not available at this time.</em>
            </p>
        `,
        CallsToAction: [
          {
            Label: 'Register for office depot',
            Url: 'http://bit.ly/FIG_OD'
          },
          {
            Label: 'Log into office depot',
            Url: 'http://business.officedepot.com/'
          },
        ],
      },
      {
        Url: 'ForceCRM',
        EnrollmentSurveyData: {
          Event: 'Thank you for your submission!',
          SurveyIds: [1027],
          Disclaimer: ' '
        },
        ViewPermissions: 'ForceCRM:View',
        Id: '3',
        // Img: 'office_depot_max_logo.png',
        WistiaId: 'j2iemfpkgs',
        Title: 'Force CRM &trade;',
        Template:
          `
                        <div class="flex flex-col md:flex-row flex-grow-0 items-center md:items-start">
                            <div class="flex flex-col order-2 md:order-1">
                                <div class="pricing flex flex-col items-center min-w-220 p-20 md:mr-30 mb-20 rounded-lg border border-solid border-gray-lighter">

                                        <i class="fig-48px fig-product text-gray-light mb-10"></i>

                                    <div class="pricing-block flex flex-col items-center center pb-20">
                                        <h4 class="price text-2xl leading-tight font-semibold text-blue-dark">$500</h4>
                                        <h3 class="text-base leading-tight">per year</h3>
                                    </div>
                                    <p class="center text-sm pb-20 leading-8 text-gray-dark"><em>*Additional costs apply for data&nbsp;migration </em></p>
                                </div>
                            </div>
                            <div class="order-1 md:order-2 pb-20">
                                <p class="mb-10">
                                    Force CRM&nbsp;&trade; is more than your typical CRM.
                                </p>
                                <p class="mb-10">
                                    With this powerful platform, you can now manage your entire office's business securely and efficiently. From marketing and prospects, to data feeds and file storage, Force CRM&nbsp;&trade; is your one-stop shop.
                                </p>
                                <p class="mb-10">
                                    Being tightly integrated with some of the industry's top vendors, technology no longer has to be a burden on your office - leaving you to focus your time on the client.
                                </p>
                                <p class="mb-10">
                                    Interested? Submit an interest form to your right and our technical team will reach out to you shortly!
                                </p>
                            </div>
                        </div>
                    `,
        PurchasePageData: {
          PayComponentCallback: (): void => {
            return;
          },
          PurchaseModel: {
            TermsLink: 'https://www.figmarketing.com/Documents/Terms_of_Use.pdf',
            PurchaseOptions: [
              {
                Description: 'Force CRM - One Time Payment',
                Amount: 500,
                IsRecurringPayment: false,
                PurchaseSeats: false,
                MarketingStrategy: MarketingStrategy.ForceCrm
              }
            ],
            Title: 'Force CRM',
            Purchased: false,
            Name: 'Force CRM',
            MarketingStrategy: MarketingStrategy.ForceCrm
          }
        },
        SurveyName: 'Force CRM',
        AdditionalResourceGroups: [
          {
            GroupName: 'ForceCRM Overview',
            AdditionalResources: [
              {
                Name: '',
                WistiaId: 'yp5bjxyya2'
              }
            ]
          }
        ]
      },
      {
        Url: 'iComply',
        EnrollmentSurveyData: {
          Event: 'Thank you for your submission!',
          SurveyIds: [1025],
          Disclaimer: ' '
        },
        ViewPermissions: 'IComply:View',
        AccessPermissions: 'IComply:Access',
        Id: '4',
        // Img: 'office_depot_max_logo.png',
        WistiaId: 'c0ix46y651',
        Title: 'iComply',
        Template: `
        <p class="mb-10">
                A cutting-edge system that integrates information gathering for compliance suitability and fiduciary responsibilities, electronic application execution, and document uploading capabilities all within one powerful online tool.
            </p>
            <p *hideIfOne="'IComply:Access'">
                <strong>Contact your Sales Consultant for More Information</strong>
            </p>
        `,
      },
      {
        Url: 'TalentSolutions',
        EnrollmentSurveyData: {
          Event: 'Thank you for your submission!',
          SurveyIds: [19],
          Disclaimer: ' '
        },
        ViewPermissions: 'FigTalentSolutions:View',
        AccessPermissions: 'FigTalentSolutions:Access',
        Id: '5',
        Img: 'talent-solutions-logo.svg',
        // LearnMoreUrl: 'https://figtalentsolutions.jobs.net/en-US/',
        CallsToAction: [
          {
            Label: 'Visit Talent Solutions',
            Url: 'https://www.figmarketing.com/#!/TalentSolutions'
          },
        ],
        Title: 'Talent Acquisition',
        Template: `
        <p>
                FIG Talent Solutions can help you move your business forward by enhancing your network with qualified introductions to individuals who possess similar values, cultural beliefs, and business principles.
            </p>
            <p>
                We have taken the best subject matter expertise in the financial services recruiting industry and developed the optimal approach for attracting top talent to some of the hottest jobs within the Advisor community. Our proven process for properly identifying the correct needs of a firm by interviewing all key stakeholders is followed by the detailed creation of your recruiting campaign. Culminating in the identification and placement of the best fit for your organization.
            </p>
            <p>
                FIG Talent Solutions educates, engages, and ultimately recruits new employees in the financial space. Our recruits are hired as employees and work locally at the firm to perform a variety of functions including: Associate Advisor, Secondary Advisor, ParaPlanning, Marketing, and Administrative and Operations Support.
            </p>
        `,
      },
      {
        Url: 'RetireUp',
        EnrollmentSurveyData: {
          Event: 'Thank you for your submission!',
          SurveyIds: [23],
          Disclaimer: ' '
        },
        ViewPermissions: 'RetireUp:View',
        AccessPermissions: 'RetireUp:Access|RetireUpFree:Access',
        Id: '6',
        // Img: 'talent-solutions-logo.svg',
        Title: 'RetireUpPro',
        Template: `
        <p>
            RetireUpPro is more than a sales tool—it's the industry's first advisor-guided retirement decision platform designed to increase capacity, productivity, and profitability. Empower your advisors to guide their clients through a personalized retirement planning experience. Bring clarity at every step, keeping clients engaged and ultimately helping them make retirement decisions easily and with confidence.
        </p>
        <p class="mb-5">
            <strong>With RetireUpPro, you will be able to:</strong>
        </p>
        <ol class="list-decimal list-inside">
            <li>
                Change the Client Conversation
            </li>
            <li>
                Visualize Retirement Realities and Solutions
            </li>
            <li>
                Get Approvals Faster with Cleaner, Compliant Apps
            </li>
        </ol>
        `,
        WistiaId: 'k87jpd8g3s',
        PurchasePageData: {
          PayComponentCallback: (): void => {
            return;
          },
          PurchaseModel: {
            TermsLink: 'https://www.figmarketing.com/Documents/Terms_of_Use.pdf',
            PurchaseOptions: [
              {
                Description: 'RetireUp Pro - One Time Payment',
                Amount: 1000,
                IsRecurringPayment: false,
                PurchaseSeats: false,
                MarketingStrategy: MarketingStrategy.RetireUp
              }
            ],
            Title: 'RetireUp Pro',
            Purchased: false,
            Name: 'RetireUp Pro',
            MarketingStrategy: MarketingStrategy.RetireUp
          }
        },
      },
      {
        Url: 'MedicarePlanning',
        Id: '7',
        Img: 'medicare-planning-logo.png',
        LearnMoreUrl: 'https://www.figmarketing.com/files/resources/Medicare_FIG_Brochure.pdf',
        Title: 'Medicare',
      },
      {
        Url: 'CAR',
        Id: '8',
        EnrollmentSurveyData: {
          Event: 'Thank you for your submission!',
          SurveyIds: [8],
          Disclaimer: ' '
        },
        ViewPermissions: 'Car:View',
        AccessPermissions: 'Dol:Access',
        Img: 'car-logo.png',
        Title: 'CAR Program',
        WistiaId: 'drfnpnetve',
        Template: `
            <p>
                The CAR Program can enhance your relationship with your client/prospect. Demonstrate a sincere interest in your clients/prospects that extends beyond a possible sale, building a connection based on trust. You can provide solutions, not just sell a product.
            </p>
            <p>
                This tool can help you uncover sales and referral opportunities. Once a client/prospect goes through the CAR program, even if no sale takes place, he or she will feel much more comfortable in providing you with referrals to their family, friends and business associates.
            </p>
            <p>
                This review can set you apart from other financial professionals. In this business, you strive to stay ahead of your competitors, and by offering services such as comprehensive policy reviews, you can literally shut the door on your competition. If you can add value to an insurance transaction, you're ahead of the curve.

            </p>
            <p style="padding-bottom:0;">
                What is Included in the Review?
            </p>
            <ul class="list-disc list-inside mb-10 ml-20">
                <li>
                    Review of your current objectives and needs
                </li>
                <li>
                    Summary and analysis of existing in-force policy(ies)
                </li>
                <li>
                    Illustration and review of alternatives
                </li>
                <li>
                    Optional assessment of current medical underwriting
                </li>
                <li>
                    Review of policy ownership and beneficiary designations as needed
                </li>
                <li>
                    Ratings of the insurance companies
                </li>
            </ul>
        `,
        AdditionalResourceGroups: [
          {
            GroupName: 'Annuity CAR',
            AdditionalResources: [
              {
                Icon: 'article',
                Name: 'Fact Finder',
                ExternalUrl: 'https://www.figmarketing.com/files/resources/Annuity_CAR_Fact_Finder.pdf'
              },
              {
                Icon: 'article',
                Name: 'Sample Advisor&nbsp;Output',
                ExternalUrl: 'https://www.figmarketing.com/files/resources/Annuity_CAR_Sample_Output.pdf'
              },
              {
                Icon: 'article',
                Name: 'Clarity Opportunity&nbsp;WP',
                ExternalUrl: 'https://www.figmarketing.com/files/resources/CAR-ClarityOpportunity-WP.pdf'
              },
            ],
          },
          {
            GroupName:'Life CAR',
            AdditionalResources: [
              {
                Icon: 'article',
                Name: 'Sample Client&nbsp;Output',
                ExternalUrl: 'https://www.figmarketing.com/files/resources/CAR_Sample_Output1.pdf'
              },
              {
                Icon: 'article',
                Name: 'Required Form - Carrier Checklist, Authorization, and Goals Form FILLABLE',
                ExternalUrl: 'http://www.figmarketing.com/files/resources/CAR_Checklist_w_Authorization_and_Goals_Forms_FILLABLE.pdf'
              },
              {
                Icon: 'article',
                Name: 'Agent Process',
                ExternalUrl: 'http://www.figmarketing.com/files/resources/CAR_Agent_Process_Guide.pdf'
              },
              {
                Icon: 'article',
                Name: 'Review Process',
                ExternalUrl: 'http://www.figmarketing.com/files/resources/CAR_Review_Process.pdf'
              },
              {
                Icon: 'article',
                Name: 'Life Insurance Concepts',
                ExternalUrl: 'http://www.figmarketing.com/files/resources/CAR_Life_Insurance_Concepts.pdf'
              },
            ]
          },
        ]
      },
      {
        Url: 'LoneBeacon',
        Id: '9',
        EnrollmentSurveyData: {
          Event: 'Thank you for your submission!',
          Disclaimer: ' ',
          Labels: ['Enroll in Standard Package', 'Enroll in Premium Package'],
          SurveyIds: [2037, 2038]
        },
        ViewPermissions: 'LoneBeacon:View',
        AccessPermissions: 'LoneBeaconStandard:Access|LoneBeaconPremium:Access',
        Img: 'lone-beacon-logo.png',//TODO: replace with hi res image
        ImgSize: 'h-150',
        CallsToAction: [{
          Label: 'Visit Our Website',
          Url: 'https://lonebeacon.com/'
        }],
        Title: 'Lone Beacon',
        Template: `
                        <div class="flex flex-row h-full">
                            <div class="mr-20 md:mr-30 w-full md:w-1/2">
                                <div class="flex flex-col h-full rounded-lg bg-blue-lighter bg-opacity-50">
                                    <span class="heading font-bold center text-grey-dark is-xl mx-20 pt-20">Standard Package</span>
                                    <span class="heading font-semibold center text-grey-dark mx-20">Initial Fee $15,000</span>
                                    <span class="heading font-semibold center text-grey-dark mx-20 pb-20 border-b border-blue-darker">Monthly Fee (Starting at) $5,500</span>
                                    <span class="mx-20 pt-20">&nbsp;</span>
                                    <ul class="list-disc list-inside text-base mx-20 my-10">
                                        <li class="my-10">Strategic In-Depth Business Review</li>
                                        <li class="my-10">Marketing, Advertising, and Overall Media&nbsp;Audit</li>
                                        <li class="my-10">New Website Build</li>
                                        <li class="my-10">Blog and Social Media Posting</li>
                                        <li class="my-10">Database Email Marketing</li>
                                    </ul>
                                    <div class="flex flex-col w-full flex-grow justify-end">
                                    <span class="m-20">And more...</span>
                                    <span class="text-sm center mx-40 mb-20">Must qualify. For qualification information and more details, click below.</span>
                                    <a class="flex justify-center items-center button center is-primary is-inverted mx-auto mb-10" href="https://portal.figmarketing.com/v1/Documents/LB_Standard_Package.pdf" target="_blank">Learn More</a>
                                    </div>
                                </div>
                            </div>
                            <div class="w-full md:w-1/2">
                                <div class="flex flex-col rounded-lg bg-blue-lighter bg-opacity-50">
                                    <span class="heading font-bold center text-grey-dark is-xl mx-20 pt-20">Premium Consulting Package</span>
                                    <span class="heading font-semibold center text-grey-dark mx-20">Set-Up Fee $20,000</span>
                                    <span class="heading font-semibold center text-grey-dark mx-20 pb-20 border-b border-blue-darker">Monthly Fee (Starting at) $8,000</span>
                                    <span class="text-sm mx-20 pt-20">Everything offered in the Standard Package PLUS:</span>
                                    <ul class="list-disc list-inside text-base mx-20 my-10">
                                        <li class="my-10">Enhanced SEO</li>
                                        <li class="my-10">Enhanced Content</li>
                                        <li class="my-10">1 Additional Blog &amp; Social Media Post per&nbsp;week</li>
                                        <li class="my-10">Enhanced Rules-Based Marketing Automation</li>
                                        <li class="my-10">Additional Web and Digital Updates</li>
                                        <li class="my-10">Ongoing Strategic Consultation</li>
                                    </ul>
                                    <span class="m-20">And more...</span>
                                    <span class="text-sm center mx-40 mb-20">Must qualify. For qualification information and more details, click below.</span>
                                    <a class="flex justify-center items-center button center is-primary is-inverted mx-auto mb-10" href="https://portal.figmarketing.com/v1/Documents/LB_Premium_Package.pdf" target="_blank">Learn More</a>

                                </div>
                            </div>
                    </div>
                `,
      },
      {
        Url: 'Surge',
        Id: '10',
        EnrollmentSurveyData: {
          Event: 'Thank you for your submission!',
          Disclaimer: ' ',
          SurveyIds: [5]
        },
        CallsToAction: [{
          Label: 'Surge website',
          Url: 'https://www.surgebc.com/'
        },],
        ViewPermissions: 'Surge:View',
        // Img: 'lone-beacon-logo.png',
        Title: 'Personalized Business Consulting',
        Template: `
                    <p>
                        Surge 20.20 is a four-step analysis that will identify challenges, adjust your business model and hold all parties accountable with the goal of surging to new heights immediately.
                    </p>
                    <p>
                        Each day you face personal and business challenges that impact the future of what you have worked so hard to build. With the help of SURGE you will create new personal and business habits that will impact you for the remainder of your years in business as a financial professional.
                    </p>
                    <p>
                        How many times have you left a great seminar or meeting filled with ideas, only to implement none of them? Surge provides support to carry out your best ideas and see your dreams become a reality.
                    </p>
                    <p class="mb-0">
                        <strong>Three important questions:</strong>
                    </p>
                    <ol class="list-decimal list-inside ml-20 mb-10">
                        <li>
                            Are you fully prepared to execute your 5-year business plan to become explosive by the end of the fifth year?
                        </li>
                        <li>
                            Is your business vision clear and concise to execute change inside your business?
                        </li>
                        <li>
                            Are you willing to dedicate 20 minutes 20 days per month to be accountable and focus on your business?
                        </li>
                    </ol>
                    <p>
                        If you are dedicated enough to commit to our program your business will surge just as you dreamed when you first started in your practice.
                    </p>
                `,
        WistiaId: 's56x06imm8',
        PurchasePageData: {
          PayComponentCallback: (): void => {
            return;
          },
          PurchaseModel: {
            TermsLink: 'https://www.figmarketing.com/Documents/Terms_of_Use.pdf',
            PurchaseOptions: [
              {
                Description: 'Business Consulting - One Time Payment',
                Amount: 5000,
                IsRecurringPayment: false,
                PurchaseSeats: false,
                MarketingStrategy: MarketingStrategy.SurgeBc
              }
            ],
            Title: 'Surge Business Consulting',
            Purchased: false,
            Name: 'Surge Business Consulting',
            MarketingStrategy: MarketingStrategy.SurgeBc
          }
        },
      },
      {
        Url: 'Millennium',
        Id: '11',
        EnrollmentSurveyData: {
          Event: 'Thank you for your submission!',
          Disclaimer: ' ',
          SurveyIds: [14]
        },
        LearnMoreUrl: 'https://www.figmarketing.com/blog/wp-content/uploads/2017/07/Next-Brochure-lores.pdf',
        ViewPermissions: 'Millennium:View',
        AccessPermissions: 'Millennium:Access',
        Img: 'millennium-logo.svg',
        Title: 'Business Retirement Plans',
        Template:
          `
                    <p>
                        Are you an independent financial advisor wanting to establish connections with business owners? Team up with FIG's exclusive partner, Millennium to conduct a retirement plan review using exceptional software that will jump-start the conversation. Start by utilizing patent pending technology that will create a unique opportunity to establish credibility and provide fiduciary solutions to businesses in your local market. With a Business Retirement Plan Review, you will have the ability to build a custom retirement solution for each small to medium sized business, rather than take a “one-size-fits-all” approach.
                    </p>
                    <p>
                        In addition to identifying retirement plan pain points for the company, you will also be able to increase conversion rates with exclusive coaching from our industry partner. You will gain an in-depth understanding of the complexities of the retirement plan market, along with supportive resources for each step of the process. By utilizing a Business Retirement Plan Review, you will solve major retirement plan issues and build lasting relationships.
                    </p>
                `,
      },
      {
        Url: 'PowerOfZero',
        Id: '11',
        CallsToAction: [{
          Label: 'Power of Zero Bootcamp',
          Url: 'https://www.powerofzero.com/bootcamp'
        }],
        ExternalInterestFormUrl: 'https://engage.figmarketing.com/acton/fs/blocks/showLandingPage/a/39120/p/p-003c/t/page/fm/0',
        Disclaimer: '<p class="text-sm"><em class="font-semibold"><span class="text-red">PLEASE NOTE:</span> RETURN CORRESPONDENCE FOR SUBMITTED INTEREST IS BEING REVIEWED BY THE POWER OF ZERO TEAM. YOU WILL BE CONTACTED VIA EMAIL TO CONFIRM AREA AVAILABILITY AS WELL AS NEXT STEPS.</em></p>',
        LearnMoreUrl: 'https://www.figmarketing.com/files/resources/POZ_Flyer_7.25.19.pdf',
        ViewPermissions: 'PowerOfZeroPlus:View',
        AccessPermissions: 'PowerOfZeroPlus:Access',
        Title: 'Advanced Seminar Marketing',
        Template:
          `
                    <p>
                        The Power of Zero University Workshop is a marketing program that covers the entire sales process. It's a turnkey, flagship marketing strategy designed to put you in front of a consistent, predictable stream of new clients. You will teach a six-hour, two-day workshop to pre-retirees ages 50 to 65 at a college or university campus near your office.
                    </p>
                    <p class="mb-5">
                        <strong>What makes this marketing program unique?</strong>
                    </p>
                    <ul class="list-disc list-inside text-base mx-20 mb-10">
                        <li>
                            It's a turnkey presentation system designed to educate your prospects on the coming tax crisis
                        </li>
                        <li>
                            It helps you leverage the Power of Zero message to establish immediate credibility and attract more affluent clients
                        </li>
                        <li>
                            You get step-by-step instructions on how to implement the Power of Zero sales process that David McKnight has used to build his own $1 million practice
                        </li>
                        <li>
                            The Power of Zero Marketing Blueprint will teach you how to become the dominant authority on tax-free retirement planning in your marketplace
                        </li>
                        <li>
                            And more!
                        </li>
                    </ul>
                    <p class="mb-5">
                        <strong>The Power of Zero Marketing Revolution</strong>
                    </p>
                    <p>
                        This turnkey marketing system has been perfected over a ten-year span, and will help you create a predictable, sustainable, repeatable marketing process that will put you in front of a consistent stream of qualified prospects that are anxious to hear the Power of Zero message.
                    </p>

                    <p class="mb-5">
                        <strong>What you get:</strong>
                    </p>
                    <ul class="list-disc list-inside text-base mx-20 mb-10" >
                        <li>
                            A professionally designed, eight-page, full-color mailer you can send out to more than 10,000 households within specific, locked-down territories.
                        </li>
                        <li>
                            A professionally designed Workshop PowerPoint
                        </li>
                        <li>
                            A two-volume set of workbooks
                        </li>
                        <li>
                            Video, audio, and script of the Power of Zero flagship presentation
                        </li>
                        <li>
                            Scripts to all three Power of Zero follow-up sales meetings
                        </li>
                        <li>
                            A comprehensive list of objections and responses
                        </li>
                        <li>
                            Access to the Power of Zero Learning Library<sup>&trade;</sup>
                        </li>
                        <li>
                            Full access to a Power of Zero marketer who will assist with road maps and before and after comparisons
                        </li>
                        <li>
                            And more!
                        </li>
                    </ul>

                    <p class="mb-5">
                        <strong>PLUS, 100 percent of your purchase will be applied toward your first marketing campaign with Power of Zero!!</strong>
                    </p>

                    <em class="leading-none pt-10">
                        The information within this document as well as the information shared within this program is for educational purposes only and does not constitute legal, tax or investment advice. Customers should consult their tax or legal professional regarding their own unique situation.
                    </em>
                `,
        WistiaId: 'k8lrywrsix',
        PurchasePageData: {
          PayComponentCallback: (): void => {
            return;
          },
          PurchaseModel: {
            TermsLink: 'https://www.figmarketing.com/Documents/Terms_of_Use.pdf',
            PurchaseOptions: [
              {
                Description: 'Power of Zero Plus - One Time Payment',
                Amount: 500,
                IsRecurringPayment: false,
                PurchaseSeats: false,
                MarketingStrategy: MarketingStrategy.PowerOfZeroPlus
              }
            ],
            Title: 'Power of Zero Plus',
            Purchased: false,
            Name: 'Power of Zero Plus',
            MarketingStrategy: MarketingStrategy.PowerOfZeroPlus
          },
          ConfirmationMessage: 'Have you ensured that your are approved for a territory?'
        }
      },
      {
        Url: 'Hegna',
        Id: '12',
        EnrollmentSurveyData: {
          Event: 'Thank you for your submission!',
          Disclaimer: `By clicking "SUBMIT" below, you agree to the following terms of agreement:
                    <br /><br /><strong>DISCLAIMER</strong>: By clicking the Tier of the program you wish to participate in you accept and agree to all payment terms related thereto. Moreover, you agree to the terms of the Hegna Program Membership Agreement including, without limitation, those relating to the ownership or otherwise of any and all intellectual property related to this program, executed contemporaneous herewith and incorporated herein.`,
          SurveyIds: [2030]
        },
        // LearnMoreUrl: 'https://fig.wistia.com/medias/of495w23fr/download?media_file_id=454331059',
        ViewPermissions: 'HegnaRHN:View',
        AccessPermissions: 'HegnaRHNTierI:Access|HegnaRHNTierII:Access|HegnaRHNTierIII:Access',
        Img: 'RHN-logo-sm.png',
        ImgSize: 'h-128',
        Title: 'Retire Happy Now: Powered By Tom Hegna',
        Template:
          `
                    <p>
                        <strong>Retire Happy Now</strong> is committed to providing valuable knowledge in understanding the
                        latest math and science behind Social Security, life insurance, annuities, and
                        long-term care to secure a happy retirement.
                    </p>
                    <div>
                        <p>
                            Gain access within this portal page to over 100 proven, video-based solutions, created for financial advisors and agents to handle every client objection they're likely to encounter, <strong>completely complimentary!</strong>
                        </p>
                    </div>
                `,
        WistiaId: 'z25jv3ur3a',
        PurchasePageData: {
          PayComponentCallback: (): void => {
            return;
          },
          PurchaseModel: {
            TermsLink: 'https://www.figmarketing.com/Documents/Terms_of_Use.pdf',
            PurchaseOptions: [
              {
                Description: 'Retire Happy Now Tier II',
                Amount: 2999,
                IsRecurringPayment: false,
                PurchaseSeats: false,
                MarketingStrategy: MarketingStrategy.HegnaRHNTierII
              },
              {
                Description: 'Retire Happy Now Tier III',
                Amount: 6999,
                IsRecurringPayment: false,
                PurchaseSeats: false,
                MarketingStrategy: MarketingStrategy.HegnaRHNTierIII
              }
            ],
            Title: 'Retire Happy Now',
            Purchased: false,
            Name: 'Retire Happy Now',
            MarketingStrategy: MarketingStrategy.HegnaRHNTierI
          }
        }
      },
      {
        Url: 'RegulatoryUpdates',
        Id: '13',
        EnrollmentSurveyData: {
          Event: 'Thank you for your submission!',
          Disclaimer: ' ',
          SurveyIds: [2036]
        },
        WistiaId: 'flsa5bk02i',
        // LearnMoreUrl: 'https://fig.wistia.com/medias/of495w23fr/download?media_file_id=454331059',
        ViewPermissions: 'Dol:View',
        AccessPermissions: 'Dol:Access',
        AdditionalResourceGroups: [
          {
            GroupName: '2024 Regulation Updates',
            AdditionalResources: [
              {
                Name: 'Important Regulation Update - April 2024',
                WistiaId: 'flsa5bk02i',
              }
            ]
          },
          {
            GroupName: '2023 Regulation Updates',
            AdditionalResources: [
              {
                Name: 'Important Regulation Update - June 2023',
                WistiaId: '3fqys1qcws',
              },
              {
                Name: 'Important Regulation Update - November 2023',
                WistiaId: '6nn8gefbnr',
              },
            ]
          },
          {
            GroupName: '2022 Regulation Updates',
            AdditionalResources: [
              {
                Name: 'DOL Update 06.14.22',
                WistiaId: '6xrb7x8qaz',
              }
            ]
          },
          {
            GroupName: '2021 Regulation Updates',
            AdditionalResources: [
              {
                Icon: 'article',
                Name: 'NAFA Fiduciary Rule 3.0 - Best Practices Guide',
                ExternalUrl: 'https://www.figmarketing.com/files/resources/NAFA_Fiduciary-Rule-3.0-BestPracticesGuide_011122.pdf'
              },
              {
                Name: '10.8 DOL Update',
                WistiaId: 'bt3soc7jyc',
              },
              {
                Icon: 'article',
                Name: 'PTE 84-24 Disclosure and Acknowledgement Form',
                ExternalUrl: 'https://www.figmarketing.com/files/resources/84-24_Disclosure_Form_8-2021_FINAL.pdf'
              },
              {
                Icon: 'article',
                Name: 'Athene PTE 84-24 Disclosure and Acknowledgement Form',
                ExternalUrl: 'https://www.figmarketing.com/files/resources/25198.pdf'
              },
              {
                Icon: 'article',
                Name: 'Athene DOL Fiduciary Rule & PTE 84-24 FAQs',
                ExternalUrl: 'https://www.figmarketing.com/files/resources/25199.pdf'
              },
              {
                Icon: 'article',
                Name: 'Athene Bottom Line-Producers and PTE 84-24 2021.11.15',
                ExternalUrl: 'https://www.figmarketing.com/files/resources/Athene_Bottom_Line-Producers_and_PTE_84-24_2021.11.15.pdf'
              },
              {
                Icon: 'article',
                Name: 'American Equity Summary of Exemption 84-24',
                ExternalUrl: 'https://www.figmarketing.com/files/resources/DOL-Summary_of_84-24.pdf'
              },
              {
                Icon: 'article',
                Name: 'FAB 2021-02',
                ExternalUrl: 'https://www.figmarketing.com/files/resources/FAB_2021-02(00362917).pdf'
              },
              {
                Icon: 'article',
                Name: 'Nationwide PTE 84-24 Disclosure and Acknowledgement Form',
                ExternalUrl: 'https://www.figmarketing.com/files/resources/LAF-0588AO_PT84-24_Disclosure_and_Acknowledgment_Form_for_Life_Insurance_Purchase-Fillable.pdf'
              },
              {
                Icon: 'article',
                Name: 'PTE 84-24 Disclosure and Acknowledgement Form Instructions',
                ExternalUrl: 'https://www.figmarketing.com/files/resources/PTE_84-24_Disclosure_Form_Instructions_9-2021_FINAL.pdf'
              },
            ]
          },
          {
            GroupName: 'JUNE 9TH DOL IMPLEMENTATION TRAINING',
            AdditionalResources: [
              {
                Name: '05.30.17 - DOL Webinar',
                WistiaId: 'vd9hz2j5ps',
              },
              {
                Icon: 'article',
                Name: '12.01.17 - Transition 84-24 Disclosure Instructions',
                ExternalUrl: 'https://fig.wistia.com/medias/l9da59uema/download?media_file_id=251878794'
              },
              {
                Icon: 'article',
                Name: '12.1.17-6.30.19 - Transition 84-24 Disclosure',
                ExternalUrl: 'https://fig.wistia.com/medias/xlcinrjjwx/download?media_file_id=259926664'
              },
            ]
          },
          {
            GroupName: '2017 DOL UPDATES',
            AdditionalResources: [
              {
                Name: '02.03.17',
                WistiaId: 's8zq9x5js7',
              },
              {
                Name: '04.05.17',
                WistiaId: 'nw7a24wuod',
              },
              {
                Name: '05.16.17',
                WistiaId: '5an9l590b4',
              },
              {
                Name: '03.02.17',
                WistiaId: 'bogaut45sh',
              },
            ]
          },
          {
            GroupName: 'DOL DISCOVERY DAY - DECEMBER 2016',
            AdditionalResources: [
              {
                Name: 'Discovery Day Welcome',
                WistiaId: 'r61e8lr6t9',
              },
              {
                Name: 'Advisor Owned RIAs DOL Prep',
                WistiaId: 'ekgmxkhn7k',
              },
              {
                Name: 'Requirements of Advisors &amp; Financial Institutions in a Post-DOL World',
                WistiaId: '1prn1x3v87',
              },
              {
                Name: 'Affiliating with FIG\'s FI',
                WistiaId: '9vmm631d58',
              },
              {
                Name: 'Best Interest Sales Process',
                WistiaId: '2rluoj9jc4',
              },
              {
                Name: 'The New ACM',
                WistiaId: '6htwvgu406',
              },
            ]
          },
          {
            GroupName: 'SALES SYMPOSIUM - OCTOBER 2016',
            AdditionalResources: [
              {
                Name: 'Brian Williams Opening',
                WistiaId: 'd466xn0ngb',
              },
              {
                Name: 'Brian Williams DOL',
                WistiaId: 'yzz1cuoxhv',
              },
            ]
          },
          {
            GroupName: 'RESOURCES',
            AdditionalResources: [
              {
                Name: 'DOL Interview',
                WistiaId: '4um90ieze6',
              },
              {
                Icon: 'article',
                Name: 'DOL-Advisor Talking Points.pdf',
                ExternalUrl: 'https://fig.wistia.com/medias/g4cipizz0q/download?media_file_id=155755621'
              },
            ]
          },
          {
            GroupName: 'WEBINARS',
            AdditionalResources: [
              {
                Name: '05.16.17 - DOL Update',
                WistiaId: 'zoxymxf32p',
              },
              {
                Name: '06.13.16 - DOL Fiduciary Update',
                WistiaId: 'jxv4ugnm26',
              },
              {
                Name: '08.05.16 - DOL Fiduciary Update',
                WistiaId: 'mef9dqfp9f',
              },
              {
                Name: '06.13.16 - DOL Interview with Cliff Andrews, Chip Anderson, Harry Stout',
                WistiaId: '40kxr3zclh',
              },
              {
                Name: '04.21.16 - DOL Webinar',
                WistiaId: 'vb7jukov5t',
              },
            ]
          },
          {
            GroupName: 'PRESENTATIONS',
            AdditionalResources: [
              {
                Icon: 'article',
                Name: '05.03.16 - Advisor DOL RULE Slides',
                ExternalUrl: 'https://fig.wistia.com/medias/pnmblpl8rc/download?media_file_id=155755592',
              },
            ]
          },
        ],
        Img: 'regulation-updates-logo.png',
        ImgSize: 'h-96',
        Title: 'Regulation Updates',
        Template: `
                <p>
                    Any and all information contained herein is general background information given in summary form and not intended to be complete about the United States Department of Labor's recent Fiduciary Rule and its counterpart, the Best Interest Contract Exemption, and is provided to FIG advisors to use at their discretion for educational purposes only. Accordingly, any and all information contained herein shall in no way constitute legal and/or tax advice. To the maximum extent permitted by applicable law, FIG disclaims all representations and warranties of any kind, express and/or implied, with respect to any and all information contained herein. Without limiting the foregoing, FIG does not represent or warrant that the information contained herein will meet an advisor or subsequent recipient's requirements or expectations, will result in increased profit or revenue, or is accurate.
                </p>
                <p>
                    Moreover, if an advisor is affiliated or registered with a broker-dealer, registered investment advisor (RIA), or other financial institution, FIG in no way represents or warrants that any of the information contained herein sets forth or satisfies any compliance therewith. It is therefore advisor and/or subsequent recipient's sole responsibility, either through a lawyer, tax professional, or otherwise, to ensure the information contained herein is accurate prior to using and/or relying on the same. Advisor and/or recipient's failure to do so is done at said party's own risk and peril and shall in no way create any liability whatsoever against FIG, its subsidiaries, affiliates, vendors, employees and/or directors.
                </p>
                `,
        AdditionalResourceDisclaimer: `
                <p>
                    <em>
                        Any and all information contained herein is general background information given in summary form and not intended to be complete about the United States Department of
                        Labor's recent Fiduciary Rule and its counterpart, the Best Interest Contract Exemption, and is provided to FIG advisors to use at their discretion for educational purposes only.
                        Accordingly, any and all information contained herein shall in no way constitute legal and/or tax advice. To the maximum extent permitted by applicable law, FIG disclaims all
                        representations and warranties of any kind, express and/or implied, with respect to any and all information contained herein. Without limiting the foregoing, FIG does not
                        represent or warrant that the information contained herein will meet an advisor or subsequent recipient's requirements or expectations, will result in increased profit or revenue,
                        or is accurate.
                    </em>
                </p>
                <p>
                    <em>
                        Moreover, if an advisor is affiliated or registered with a broker-dealer, registered investment advisor (RIA), or other financial institution, FIG in no way represents or warrants that
                        any of the information contained herein sets forth or satisfies any compliance there with. It is therefore advisor and/or subsequent recipient's sole responsibility, either through a
                        lawyer, tax professional, or otherwise, to ensure the information contained herein is accurate prior to using and/or relying on the same. Advisor and/or recipient's failure to do so is
                        done at said party's own risk and peril and shall in no way create any liability whatsoever against FIG, its' subsidiaries, affiliates, vendors, employees and/or directors.
                    </em>
                </p>
                `
      },
      {
        Url: 'Elevate',
        Id: '14',
        EnrollmentSurveyData: {
          Event: 'Thank you for your submission!',
          Disclaimer: ' ',
          SurveyIds: [2027, 2]
        },
        LearnMoreUrl: 'https://portal.figmarketing.com/v1/Documents/elevatebrochure.pdf',
        ViewPermissions: 'Elevate:View',
        AccessPermissions: 'Elevate:Access|ElevateEnhanced:Access',
        // Img: 'RHN-logo-sm.png',
        ImgSize: 'h-128',
        WistiaId: '4yrei0kz3f',
        Title: 'Elevate&trade;',
        Template:
          `
                    <h2 class="text-lg mb-10 font-bold"><em>The All-encompassing Marketing Platform</em></h2>
                    <p>Let us help you Elevate your business. With industry-leading coaches, creative
                    branding and marketing services, and much more, Elevate gives you the tools needed
                    to take your business to the next level.</p>
                    <p>Elevate offers access to the most highly respected experts in the business and their
                    proven practices for growth and retention. Each top-producing mentor brings a unique
                    view of their sales processes, along with proven marketing techniques and assets that are
                    currently in use.</p>
                    <p>As a member, you have access to an extensive library of resources to enhance your
                    marketing and sales efforts. Most importantly, you'll receive a dedicated marketing
                    strategist who has experience working with top financial professionals across the country.</p>
                `
      },
      {
        Url: 'Lockton',
        Id: '15',
        // EnrollmentSurveyData: {
        //     Event: 'Thank you for your submission!',
        //     Disclaimer: ' ',
        //     SurveyIds: [2027]
        // },
        // LearnMoreUrl: 'https://locktonaffinityadvisor.com/fig/?utm_source=fig+tracking+link+02-19&utm_medium=impact&utm_campaign=2019+laad+life+agent+partner+links&segmentcode=gba01352',
        CallsToAction: [
          {
            Label: 'Lockton Website',
            Url: 'https://locktonaffinityadvisor.com/fig/?utm_source=fig+tracking+link+02-19&utm_medium=impact&utm_campaign=2019+laad+life+agent+partner+links&segmentcode=gba01352'
          }
        ],
        ViewPermissions: 'Lockton:View',
        Img: 'lockton-logo.png',
        ImgSize: 'h-128',
        ImgSubtitle: `
                <p class="center">
                    Receive a quote and purchase coverage in less than 10&nbsp;minutes.<br />
                    <strong>Get started today!</strong>
                </p>
                `,
        Title: 'E&amp;O Insurance',
        Template:
          `
                    <p>
                        Financial Independence Group has partnered with Lockton Affinity Advisor to offer customized insurance solutions to agents like you.
                    </p>
                    <p>
                        Through Lockton Affinity Advisor, you have access to Errors and Omissions with Cyber Liability Insurance, and additional benefits like:
                    </p>
                        <h4 class="mb-0"><strong>Affirmative Fiduciary Coverage</strong></h4>
                        <p>
                            ERISA and vicarious liability are included in your coverage.
                        </p>
                        <h4 class="mb-0"><strong>Cyber Protection</strong></h4>
                        <p>
                            Cyber Liability with a limit of $250,000 and a $100,000 sub-limit for Legal Forensics included&nbsp;automatically.
                        </p>
                        <h4 class="mb-0"><strong>Individual Policy Limits</strong></h4>
                        <p>
                            Receive individual limits of $1,000,000 per claim/$3,000,000 aggregate, rather than a group master policy with a shared aggregate limit.
                        </p>
                        <h4 class="mb-0"><strong>Competitive Pricing</strong></h4>
                        <p>
                        Premiums start at $450 with $0 deductible. For qualifying accounts, Series 6 Products and P&C Personal Lines coverage is also available for a slighter higher premium.
                        </p>
                    `
      },
      {
        Url: 'Cannex',
        Id: '16',
        ViewPermissions: 'MenuResources:View',
        AccessPermissions: 'Cannex:Access',
        Title: 'SPIA/DIA Quotes',
        Template: `
                    <h3 class="text-base">Redirecting...</h3>

                `
      },
      {
        Url: 'Covid19',
        Id: '17',
        Title: 'Digital Marketing Assets: How to Manage Your Business Digitally',
        WistiaId: '4kfoxl2y9h',
        ViewPermissions: 'MenuResources:View',
        Template:
          `
                    <p>
                        FIG's digital resource center is here to help you navigate your business in a digital world.
                    </p>
                    <p>
                        From social media marketing to conducting appointments and submitting electronically, you'll find it all here.
                    </p>
                    `
      },
      {
        Url: 'TheAmericanCollege',
        CallsToAction: [
          {
            Label: 'LEARN MORE AND ENROLL',
            Url: 'https://www.theamericancollege.edu/partners/1591613'
          },
        ],
        Disclaimer: '<em>*If using marketing points, please contact your Marketing Consultant.</em>',
        Id: '18',
        Img: 'the_american_college_logo.png',
        ImgSubtitle: '<em class="mx-auto">Every Class, World-Class.</em>',
        // LearnMoreUrl: 'https://www.figmarketing.com/files/resources/Medicare_FIG_Brochure.pdf',
        Title: 'The American College of Financial Services',
        ViewPermissions: 'AmericanCollege:View',
        Template:
          `
                    <div>
                        <p>
                            The American College of Financial Services is the nation's only accredited nonprofit dedicated to the financial services profession.
                        </p>
                        <p>
                            The College places a premium on quality education and works tirelessly to ensure that society has informed, ethical financial service and insurance professionals it can trust. Our degrees and designations are universally recognized as representing the highest standard in expertise and education. More than 200,000 professionals have learned and honed their craft here. What they have found is that in the complex world of financial services, the key to success is being better prepared than the competition. It's why so many return to The College throughout their careers.
                        </p>
                        <p>
                            More than 20 percent of financial advisors working in the U.S. today have received an education through The American College of Financial Services.
                        </p>
                        <p>
                            <a class="learn-more" rel="noopener" target="_blank" href="https://hubs.ly/H0cFfH80">
                                Explore The American College Brochure
                            </a>
                        </p>
                    </div>
                    <div>
                        <h4><strong>Designations Available</strong></h4>
                        <div class="flex flex-row flex-wrap">
                            <div class="w-full md:w-1/2 p-10">
                                <div class="font-bold leading-none">CFP<sup>&reg;</sup></div>
                                <div class="description">Certified Financial Planner<sup>&trade;</sup></div>
                            </div>
                            <div class="w-full md:w-1/2 p-10">
                                <div class="font-bold leading-none">ChFC<sup>&reg;</sup></div>
                                <div class="description">Chartered Financial Consultant<sup>&reg;</sup></div>
                            </div>
                            <div class="w-full md:w-1/2 p-10">
                                <div class="font-bold leading-none">CLU<sup>&reg;</sup></div>
                                <div class="description">Chartered Life Underwriter<sup>&reg;</sup></div>
                            </div>
                            <div class="w-full md:w-1/2 p-10">
                                <div class="font-bold leading-none">WMCP<sup>&reg;</sup></div>
                                <div class="description">Wealth Management Certified Professional<sup>&reg;</sup></div>
                            </div>
                            <div class="w-full md:w-1/2 p-10">
                                <div class="font-bold leading-none">RICP<sup>&reg;</sup></div>
                                <div class="description">Retirement Income Certified Professional<sup>&reg;</sup></div>
                            </div>
                        </div>
                    </div>
                `,
      },
      {
        Url: 'AnnuityRateWatch',
        Id: '19',
        Img: 'annuity-rate-watch-logo.png',
        ImgSize: 'h-96',
        ViewPermissions: 'MenuResources:View',
        AccessPermissions: 'AnnuityRateWatch:Access',
        Title: 'Annuity Rate Watch',
        Template: `
                    <h3 class="text-base">Redirecting...</h3>

                `
      },
      {
        Url: 'Truelytics',
        Id: '20',
        EnrollmentSurveyData: {
          Event: 'Thank you for your submission!',
          Disclaimer: ' ',
          SurveyIds: [2039]
        },
        WistiaId: 'ds3she22hi',
        ImgSize: 'h-100',
        CallsToAction: [
          {
            Label: 'TrueLytics Website',
            Url: 'https://www.truelytics.com/'
          }
        ],
        ViewPermissions: 'Truelytics:View',
        AccessPermissions: 'Truelytics:Access',
        Title: 'Truelytics',
        Template: `
                <p>In 2021, we partnered with Truelytics, a leading transition management platform. With the support from Truelytics, we can help you transform how you discover business opportunities.</p>
                <p class="mb-5"><strong>This amazing practice management tool will enhance your practice by:</strong></p>
                <ol class="list-decimal list-inside mb-10">
                <li>Valuing your firm, providing benchmarks against other financial professionals, and showing you steps to take which will increase your business's value.</li>
                <li>Giving you access to a continuity planning locker and tools. The locker is your secure online vault where you'll store all your essential documents in the unfortunate event of a death or disability of the firm owner(s).</li>
                <li>Providing a matchmaking marketplace for FIG financial professionals to come together with a shared desire: buying or selling a firm!</li>
                </ol>

                <p class="mb-5"><strong>Who can use the Truelytics Platform?</strong></p>
                <ul class="list-disc list-inside mb-10"><li>All FIG-affiliated financial professionals</li></ul>
                <p class="mb-5"><strong>What's the monthly subscription fee for Truelytics?</strong></p>
                <ul class="list-disc list-inside mb-10"><li>$0: it's free! We see Truelytics as an essential platform for your growth, continuity planning, and succession, so we've got you covered.</li></ul>
                `
      },
      {
        Url: 'CarePlanning',
        Disclaimer: 'Are you interested in a duplicatable system that will teach you how to implement Care Planning into your practice? Click here to request additional information on a platform that will provide training videos on the how-tos of selling Asset Based LTC. You will have everything you need at your fingertips to start selling LTC including a proven PowerPoint presentation and all sales materials used from start to finish in the sales process.',
        Id: '21',
        Title: 'Care Planning Resources',
        Img: 'care-planning-resources-logo.svg',
        EnrollmentSurveyData: {
          Event: 'Thank you for your submission!',
          SurveyIds: [18],
          Disclaimer: ' '
        },
        ViewPermissions: 'LtcTraining:View',
        AccessPermissions: 'LtcTraining:Access',
        Template: `
                <p>
                    FIG's Care Planning team helps you get to where you want to be on asset-based LTC solutions. Our team can provide benefits such as product comparisons, guides, forms, and tips. Succinctly, we assist you in understanding the nuances of all things LTC.
                </p>
                <p>
                    Care planning is a significant component of a client's complete retirement portfolio, so we strive to help financial professionals understand the various products and solutions in this space. But, with these solutions comes various underwriting challenges and opportunities. Our experienced professionals are here to assist you to make the process simple for you.
                </p>
                <p>
                    When discussing Care Planning with clients, it is important to start the conversation by presenting the costs associated with care so they understand the impact LTC can have on their assets before mentioning premium. For this reason, we recommend you use Genworth's Cost of Care Survey to present the costs of care in your area and even forecast costs through the expected time of claim to emphasize the real-life risk. Here is the link: https://www.genworth.com/aging-and-you/finances/cost-of-care.html
                </p>
                `,
        AdditionalResourceGroups: [
          {
            GroupName: '2019 Sessions - General',
            AdditionalResources: [
              {
                Name: '3 Steps to Implementing LTC 4/23/19',
                WistiaId: '0hsb5d2o0p'
              },
              {
                Name: '3 Unique Ways and Fund Long Term Care 3/22/19',
                WistiaId: 'mvxe8jrcdl'
              },
              {
                Name: 'The Gift of Love 8/28/18',
                WistiaId: '0r1u9icl0t'
              },
            ]
          },
          {
            GroupName: '2019 Sessions - Product Specific',
            AdditionalResources: [
              {
                Name: 'SecureCare UL - A More Flexible Hybrid LTC Solution 9/20/18',
                WistiaId: 'ikyojrh91o'
              },
              {
                Name: 'MoneyGuard Wellness Program (Silverbrick) 2/15/19',
                WistiaId: 'q1rm2bf92n'
              },
              {
                Name: 'How to Complete a OneAmerica E-Application 4/8/19',
                WistiaId: 'a8zpwymo38'
              },
              {
                Name: 'Introducing Nationwide CareMatters II - Innovation at Its Finest 5/31/19',
                WistiaId: 'ojqzot430m'
              },
              {
                Name: 'NEW Asset Care Product Enhancements with OneAmerica 6/21/19',
                WistiaId: 'ra6x73y46u'
              },
            ]
          },
          {
            GroupName: '2019 Sessions - General',
            AdditionalResources: [
              {
                Icon: 'article',
                Name: 'CarePlanning Infographic',
                Url: 'CarePlanning_Infographic.pdf'
              },
              {
                Icon: 'article',
                Name: '2019 Business Tax Guide',
                Url: '2019_Business_Tax_Guide.pdf'
              },
            ]
          },
        ]
      },
      {
        Url: 'DisabilityResources',
        Id: '22',
        Title: 'Disability',
      },
      {
        Url: 'LifeInsurance',
        Id: '23',
        Title: 'Life Insurance',
        ViewPermissions: 'EducationLifeInsurance:View',
      },
      {
        Url: 'AnnuityResources',
        Id: '24',
        Title: 'Annuity Resources',
      },
      {
        Url: 'AdvanceYourReach',
        Id: '25',
        Title: 'Advance Your Reach',
        Img: 'AYR-2019-Logo.jpg',
        LearnMoreUrl: 'https://www.figmarketing.com/files/resources/FIG_Signature_Talk_Brochure_2021.pdf',
        ViewPermissions: 'AdvanceYourReach:View',
        Template: `
                <p>One of the biggest challenges facing many financial advisors today is cutting through the noise and delivering a clear message positioning you as an authority in your industry.
                </p>
                <p>With businesses constantly fighting to grow their presence online, it means essential services like yours often get lost or undiscovered by clients who need your help.
                </p>
                <p>You want to be seen as a reliable, trustworthy advisor ready to serve clients. To do that, you need a clear message to reach potential prospects and convert them into long-lasting clients.
                </p>
                <p>With the Signature Talk Workshop, you will create a compelling Signature Talk, positioning you as the go-to financial management authority. You will have everything you need to confidently and clearly share the value, expertise, and insights you have - and clients are looking for.
                </p>
                <p>Inside the Signature Talk Workshop, our experts work with you to create a clear and powerful message you can use to grow your practice. We come alongside you to coach you through each phase of the process. And, we give you the direct and productive feedback you need to start using your Signature Talk immediately.
                </p>
                <p>Stop getting lost in the noise and reach your ideal clients without the headaches of traditional marketing that's often riddled with compliance. Establish yourself as the authority in your industry by creating the strongest marketing tool you have - your Signature Talk.
                </p>
                `,
        CallsToAction: [
          {
            Label: 'Enroll',
            Url: 'http://advanceyourreach.com/fig'
          },
          {
            Label: 'Speak to an Advisor',
            Url: 'https://calendly.com/advance-your-reach-advisors/sig-talk-ew-fig?month=2021-04'
          },
        ],
      },
      {
        Url: 'FusionNetix',
        Title: 'FusionNetix',
        Id: '26',
        LearnMoreUrl: 'http://www.fusionnetix.com/',
        Img: 'fusionNetix-transparent.png',
        ViewPermissions: 'FusionNetix:View',
        Template: `
              <div>
                <h4><strong>EXPERT IT MANAGEMENT AND CYBERSECURITY</strong></h4>
                <p>
                    A common-sense approach to IT and cybersecurity that focuses on efficiency, usability and leaving the tech-jargon&nbsp;out.
                </p>
                <p>
                    FusionNetix understands Small and Medium Businesses and working remote. With our direct focus on the financial services industry, our team ensures your business has the tools necessary to protect against today's advanced threats, the knowledge to stay SEC & FINRA compliant, and the simplicity you need to enhance operational efficiency—also reducing employee&nbsp;frustration.
                </p>
                <p>
                    Our tech stack is built with mobility and redundancy in mind. Imagine working as securely at a coffee shop as you would at your home&nbsp;office!
                </p>
                <ul class="list-disc list-inside mb-10">
                    <li>Our security tools have backup systems in place to stand guard against the toughest cyber&nbsp;threats.</li>
                    <li>Our team monitors connected networks and real-time reporting for every hint of malicious&nbsp;activity.</li>
                    <li>FusionNetix hosts monthly IT strategy meetings to continuously evaluate your systems, efficiency, and steps to make your tech work&nbsp;better.</li>
                    <li>Our RIA-focused team conducts random Penetration Tests and audits to help in the event of SEC or FINRA&nbsp;Audits</li>
                </ul>
                <p>Come visit us at <a href="http://www.fusionnetix.com/" target="_blank" aria-label="link to Fusionnetix.com">www.fusionnetix.com</a> or reach Max Alles to learn more about our services for FIG&nbsp;advisors.</p>
            </div>
              `
      },
      {
        Url: 'FusionFinancialPartners',
        Title: 'Fusion Financial Partners',
        Id: '27',
        ViewPermissions: 'FusionFinancialPartners:View',
        // LearnMoreUrl: 'http://www.fusionfp.com/',
        Img: 'fusion-financial-part-white-trans.png',
        CallsToAction: [
          {
            Label: 'FFP Website',
            Url: 'http://www.fusionfp.com/'
          },
          {
            Label: 'Email Us',
            Url: 'mailto:axxcess@fusionfp.com?subject=A message from FIG Portal'
          }
        ],
        Template: `
                    <div>
                        <h4><strong>CONSIDER US YOUR RIA CONSULTANTS</strong></h4>
                        <p>
                            Business consultants purport themselves to understand “business”. Fusion Financial Partners understands RIAs. We know compliance requirements, we know revenue synch points. See how an engagement with Fusion can boost your bottom-line.
                        </p>
                        <p>
                            Fusion Financial Partners is a consulting firm designed to help breakaway advisors launch, help existing RIAs grow, and support leadership teams in learning and understanding the keys that are holding your business back from&nbsp;scaling.
                        </p>
                        <p>
                            Founded by two executives from the custodial world, Fusion Financial Partners leverages decades of experience to evaluate your advisory business in its entirety -
                        </p>
                        <ul class="list-disc list-inside mb-10">
                            <li>Our Fusion ScoreCardTM service is the ideal assessment tool to establish where your business stands today and what roadmap you can follow to achieve a better&nbsp;tomorrow.</li>
                            <li>From the road-map, we construct growth plans through our OCGOTM - Outsourced Client Growth Officer service whether devising a COI strategy through the succession&nbsp;stage.</li>
                            <li>Fusion Black OpsTM offers advisors an opportunity to covertly explore independence with a guided, experienced hand and resources to maneuver through every step of the&nbsp;journey.</li>
                        </ul>
                        <p>Let us help you develop your blueprint for the future.</p>
                    </div>
              `
      },
      {
        Url: 'BestInterestRules',
        Id: '28',
        Title: 'Best Interest Rules',
        LearnMoreUrl: 'https://engage.figmarketing.com/acton/rif/39120/s-071f-2012/-/l-005a:23/q-005d/showPreparedMessage?utm_term=View&utm_campaign=Important%20Update%20to%20NAIC%20Annuity%20Suitability%20Model%20Regulation&utm_content=email&utm_source=Act-On+Software&utm_medium=email&cm_mmc=Act-On%20Software-_-email-_-Important%20Update%20to%20NAIC%20Annuity%20Suitability%20Model%20Regulation-_-View&sid=TV2:GVNPBxPgp',
        Img: 'best-interest-rules-logo-light.png',
        ImgSize: 'h-96',
        WistiaId: '6h873tw5m3',
        Template: `
                <p>
                    In Feb 2020, the NAIC approved enhancements to Model #275, also known as the <em>Suitability in Annuity Transactions Model Regulation</em>. These enhancements focus primarily on creating a best interest obligation that applies to both Insurers (the issuers of the annuity contract) and Producers (those acting in an insurance agent capacity) when making a recommendation to purchase, exchange or replace an annuity.
                    </p>
                    <p>
                    Beginning January 1, 2021, Iowa and Arizona become the first two states to sign on and implement this new regulation. Many other states will follow throughout (RI in April) 2021 and beyond. Most states are in the process of reviewing this Model and making determinations as to what steps they will take to approve and implement the regulation.
                    </p>
                `,
        AdditionalResourceGroups: [
          {
            GroupName: 'Best Interest Resources',
            AdditionalResources: [
              {
                Icon: 'article',
                Name: 'NAIC Best Interest',
                ExternalUrl: 'https://www.figmarketing.com/files/resources/NAIC_Best_Interest.pdf',
              },
              {
                Icon: 'article',
                Name: 'NAIC Model #275 - Annuity Disclosure Form',
                ExternalUrl: 'https://www.figmarketing.com/files/resources/NAIC_Model_275-Annuity_Disclosure_Form-Guidance.pdf',
              },
              {
                Icon: 'article',
                Name: 'NAIC Model 275 - Disclosure Forms',
                ExternalUrl: 'https://www.figmarketing.com/files/resources/NAIC_Model_275-Disclosure_Forms-Clean.pdf',
              },
              {
                Icon: 'article',
                Name: 'NAIC Suitability MDL 275 Adoption - 04.07.21',
                ExternalUrl: 'https://www.figmarketing.com/files/resources/NAIC_Suitability_MDL_275_Adoption-Current_Status(04.07.21).pdf',
              },
            ]
          },
        ]
      },
      //   {
      //     Url: 'AnnuityIncomeAnalysis',
      //     Id: '30',
      //     Title: 'Annuity Income Analysis',
      //     ViewPermissions: ''
      //   },
      {
        Url: 'CreativeServices',
        Id: '31',
        EnrollmentSurveyData: {
          Event: 'Thank you for your submission!',
          Disclaimer: ' ',
          SurveyIds: [3]
        },
        LearnMoreUrl: 'http://www.figblueprint.com/wp-content/uploads/2017/06/apm_booklet-V2.pdf',
        ViewPermissions: 'CreativeServices:View',
        AccessPermissions: 'CreativeServices:Access',
        Img: 'creativeservices2_w-dark.png',
        ImgSize: 'h-64',
        Title: 'Creative Services - A Complete Marketing Strategy',
        Template:
          `
                    <p>
                    Do you have a complete branding strategy that will smash the competition? Or a media action plan that will command national attention? Experience why advisors trust their branding and marketing to Financial Independence Group's in-house marketing department, All Points Media.
                    </p>
                    <p>
                        Understanding your business is a key part of our process that not only helps us deliver tailored and creative services for your practice, but also establishes you as an expert in your local market. Whether your needs involve an impactful logo design, a modern website, professional video production, audio services, enhanced seminar event marketing, radio programming, outdoor or television advertising, we have the ability to create your marketing vision. Never worry about inconsistent marketing material or suffer from a creative block again! All Points Media offers contracted FIG agents access to graphic design services for all of their marketing material needs.

                    </p>
                `,
      },
      {
        Url: 'Holistiplan',
        Id: '32',
        // LearnMoreUrl: 'https://www.figmarketing.com/files/resources/Holistiplan.pdf',
        CallsToAction: [
          {
            Label: 'GET STARTED WITH HOLISTIPLAN',
            Url: 'https://app.holistiplan.com/start/'
          },
          {
            Label: 'WATCH DEMO',
            Url: 'https://www.figmarketing.com/files/resources/Holistiplan_Demo.mp4'
          },
          {
            Label: 'WATCH Webinar',
            Url: 'https://fig.wistia.com/medias/46m9fklm7q'
          }
        ],
        ViewPermissions: 'Holistiplan:View',
        Img: 'holistiplan-logo-color.svg',
        Title: 'Holistiplan',
        Template:
          `
                    <p>
                    We've formed a strategic alliance with Holistiplan, a cloud-based tax planning software that can help you enhance your tax planning process. Holistiplan offers a scalable and efficient way to do high-impact tax planning for all your clients. <a class="learn-more" href="https://www.figmarketing.com/files/resources/Holistiplan.pdf" rel="noopener" target="_blank">Click here</a> to learn more.
                    </p>
                    <p class="mb-5">
                        <strong>With Holistiplan, your firm will be able to:</strong>
                    </p>
                    <ol class="list-decimal list-inside">
                        <li>
                        Successfully scale your tax planning process and enhance the client experience.
                        </li>
                        <li>Utilize advanced technology that can read a complete tax return and create a customized report with relevant observations within seconds.
                        </li>
                        <li>Provide clients with easy-to-read PDF file deliverables to email or print out for review.
                        </li>
                        <li>Complete instant scenario analyses to quickly identify key income breakpoints for various tax planning opportunities.
                        </li>
                    </ol>
                    <p class="mb-5">
                        <strong>Who can use Holistiplan?</strong>
                    </p>
                    <ul class="list-disc list-inside mb-10">
                        <li>All FIG-affiliated financial professionals</li>
                    </ul>
                    <p class="mb-5">
                        <strong>How can I get started with Holistiplan?</strong>
                    </p>
                    <ul class="list-disc list-inside mb-10">
                        <li>FIG financial professionals can try Holistiplan free for seven days. After that, you can get 10% off the retail prices of Holistiplan by visiting their website and entering the code <strong>10fig2021</strong> at checkout.
                        </li>
                    </ul>
                    <p class="mb-5">
                        <strong>Sample Reports:</strong>
                    </p>
                    <ul class="list-disc list-inside mb-10">
                        <li><a class="learn-more" href="https://www.figmarketing.com/files/resources/Holistiplan_Sample_Tax_Report.pdf" rel="noopener" target="_blank">Sample Tax Report</a>
                        </li>
                        <li><a class="learn-more" href="https://www.figmarketing.com/files/resources/Holistiplan_Sample_Tax_Report-Lower_Income.pdf" rel="noopener" target="_blank">Sample Tax Report - Lower Income</a>
                        </li>
                    </ul>
                `,
      },
      {
        Url: 'TrustServices',
        Id: '35',
        LearnMoreUrl: 'https://icontrustnv.com/',
        CallsToAction: [
          {
            Label: 'Trust Services Training Manual',
            Url: 'https://www.figmarketing.com/files/resources/TRUST_SERVICES_TRAINING_MANUAL_FOR_FINANCIAL_PROFESSIONALS.pdf'
          },
          {
            Label: 'Trust Services Fee Agreement',
            Url: 'https://www.figmarketing.com/files/resources/Trust_Services_Fee_Agreement.pdf'
          },
        ],
        ViewPermissions: 'TrustServices:View',
        Img: 'trust-services-logo.png',
        ImgSize: 'h-100',
        Title: 'Trust Services',
        Template:
          `
                    <p>
                    We have partnered with a top provider of trust services to create the first <strong>FLAT FEE SCHEDULE FOR ALL TRUST SERVICES</strong> offered within an IMO.
                    </p>
                    <p>
                    Exclusively dedicated and secure trust administration for our affiliated financial professionals offering to <strong>protect</strong> their clients and the assets they manage.
                    </p>
                    <p class="mb-5">
                    From corporate and successor trustee services to complete trust administration, we deliver:
                    </p>
                    <div class="flex flex-wrap md:gap-20">
                    <ul class="list-disc list-inside md:w-2/5 my-0 md:my-5">
                        <li>Nevada Asset Protection&nbsp;Trusts</li>
                        <li>Dynasty Trusts</li>
                        <li>Nevada Incomplete Gift Non-Grantor Trusts&nbsp;(NINGs)</li>
                        <li>Beneficiary Defective Inheritors Trusts&nbsp;(BDITs)</li>
                    </ul>
                    <ul class="list-disc list-inside md:w-2/5 mt-0 mb-5 md:my-5">
                      <li>Successor Trustee on Revocable&nbsp;Trusts</li>
                      <li>Irrevocable Trusts</li>
                      <li>Agency Account</li>
                      <li>Executor Services in Nevada</li>
                    </ul>
                    </div>
                    <p>
                    Our <strong>goal</strong> is simple and singularly focused: To administer trusts with the highest level of customer service and responsiveness in helping families carry out their legacy.
                    </p>
                    <p><strong>Why Nevada?</strong></p>
                    <p class="mb-5">
                    Nevada has the top trust laws for compounding wealth and asset protection.
                    </p>
                    <ul class="list-disc list-inside mb-10">
                        <li>Nevada Asset Protection Trusts&nbsp;(NAPT)</li>
                        <li>State Income Tax Savings</li>
                        <li>Nevada Directed Trust Statute</li>
                        <li>Nevada's Decanting Statute</li>
                        <li>Dynasty Trusts</li>
                    </ul>
                    <p>
                      <a class="learn-more" href="https://retirementinsideout.com/ep-37-trust-administrators-in-your-business-with-gino-pascucci" rel="noopener" target="_blank" aria-label="September 10th Podcast w/ Icon Trust CMO Gino Pascucci">
                      September 10th Podcast</a> with Icon Trust CMO Gino&nbsp;Pascucci.
                    </p>
                    <p>
                      <a class="learn-more" href="https://retirementinsideout.com/trust-and-estate-planning-with-brian-simmons-of-icon-trust" rel="noopener" target="_blank" aria-label="October 8th Podcast with Icon Trust Chief Trust Officer Brian Simmons">
                      October 8th Podcast</a> with Icon Trust Chief Trust Officer Brian&nbsp;Simmons.
                    </p>
          `,
      },
      {
        Url: 'Utah',
        Title: 'Utah',
        Id: '36',
        Img: 'utah-logo.png',
        AdditionalImages: [
          {
            Url: 'https://www.figmarketing.com/files/images/utah-1.jpg',
            Label: 'Utah image 1'
          },
          {
            Url: 'https://www.figmarketing.com/files/images/utah-2.jpg',
            Label: 'Utah image 2'
          },
          {
            Url: 'https://www.figmarketing.com/files/images/utah-3.jpg',
            Label: 'Utah image 3'
          },
        ],
        ViewPermissions: 'FIGTripsAndEvents:View',
        Template: `
            <div>
                <h2 class="text-lg text-gray-dark italic">Deer Valley, UT Agent Convention, January 6-11, 2025</h2>
                <h2 class="text-gray-dark italic mb-20">Qualification Period January 1, 2023 - June 30, 2024</h2>
                <h4><strong>QUALIFICATION GUIDELINES: </strong></h4>
                <p>
                A qualifier will only receive credit for premium from carriers which the advisor is actively contracted through FIG at the end of the qualification period. Qualification is based off the TOP GA contract level and may exclude business written with certain insurance carriers, please contact your sales consultant for more details or if you have any questions. Qualification is also non-transferable, has no cash value, and is valid for the qualifier and one guest only. Applicable tax laws require that qualifiers are issued an IRS Form 1099 for all convention related fees & expenses for the qualifier and their guest. If a cancellation occurs, qualifier will be issued an IRS Form 1099 for all convention related fees and expenses for the qualifier and its guest that are non-refundable to FIG.
                </p>
            </div>
            <div>
                <h4><strong>HOW CPCS ARE CALCULATED:</strong></h4>
                <p>
                This program is based on Combined Production Credit (CPCs) earned during the defined qualification period. CPCs are a way to combine all lines of business, so they are weighted equally. Target Life production counts at 100% and Base Premium for Asset-Based LTC Premium counts at 100% towards CPC credit, while single premium asset-based LTC premium will count at 10% towards CPC credit. In addition, traditional LTC premium with Kemper and MassMutual only will count towards this program at 100% towards CPC credit. All fixed annuity and immediate annuity production with all carriers will count 3.33% towards CPCs for this program. Example: $3,000,000 in paid fixed annuity production would equate to 100,000 CPCs. Short Term Indexed Annuity premium, defined as indexed products that have a surrender charge period of 7 years or less, will count at 6% towards FIG CPC credit. For example, $2,000,000 of short term indexed annuity premium equals 120,000 FIG CPCs. All other indexed annuity premium will count at 10% towards FIG CPC credit. For example, $1,000,000 of indexed annuity premium equals 100,000 FIG CPCs.
                </p>
            </div>
            <div>
                <h4><strong>ADDITIONAL REQUIREMENTS:</strong></h4>
                <p>
                Qualifier and his or her travel companion shall be eligible and in good health to travel and agree to execute a FIG approved waiver of liability and hold harmless agreement prior to the trip. The terms, conditions and eligibility requirements herein are void where prohibited up and until the date of the event. Moreover, any qualifier affiliated with a broker dealer or RIA may be subject to approval by the same in order to attend this event.
                </p>
            </div>
              `
      },
      {
        // TODO: corey 2024-10 remove when modal removed
        Url: 'TermLifeQuoteEngine',
        Id: '37',
        ViewPermissions: 'LifeQuoteEngine:View|IpipelineFormsQuotesLife:Access',
        Title: 'Term Life Quote Engine',
        Template: `
                    <h3 class="text-base">Redirecting...</h3>

                `
      },
    ];
  }
});
