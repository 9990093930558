import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SsoService } from 'src/app/core/services/sso.service';

@Component({
  selector: 'app-carrier-announcement',
  standalone: true,
  imports: [
    MatButton,
    CommonModule
  ],
  templateUrl: './carrier-announcement.component.html',
})
export class CarrierAnnouncementComponent {
  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<CarrierAnnouncementComponent>,
    private ssoService: SsoService
  ){}

  closeDialog():void{
    this.dialog.closeAll();
  }

  launchTermLife():void{
    this.ssoService.iPipelineLifeQuotesSso();
    this.dialog.closeAll();
  }
}
