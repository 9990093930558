import { Pipe, PipeTransform } from '@angular/core';
import { DOBToAgeHelper } from '../helpers/date-of-birth-to-age.helper';

@Pipe({
  name: 'dobToAge'
})
/**Pipe for converting DOB to age
 * @param dob as string
 * @return age as number or undefined
 */
export class DobToAgePipe implements PipeTransform {
  transform(dob: Date|null|undefined): number|undefined {
    return DOBToAgeHelper.convertToAge(dob);
  }
}