import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CpcPolicy } from 'src/app/portal/components/business/components/cpc-reporting-list/models/cpc-policy.model';
import { InformalTablePolicy } from 'src/app/portal/components/business/components/informal-policy-list/models/informalTablePolicy.model';
import { FileUploadInfoModel } from 'src/app/portal/components/business/models/policy-v2.models';
import { PaperApplicationHistoryModel } from 'src/app/portal/modals/paper-application-upload-history/models/paper-application-history.model';
import { AgentBusinessSummary, AgentCommissionGroupList, AgentGuidList, AgentSubAgent, AgentSubEntity, AgentYearlyProduction } from 'src/app/shared/models/agent.models';
import { Contract, ContractDetail } from 'src/app/shared/models/contract.models';
import { EntryResponse, ListResponse, SingleResponse } from 'src/app/shared/models/generic.models';
import { PolicyDetail, Policy, PolicyFunding, PolicySuitability, PolicyTask, Note, InformalPolicyDetails, InformalPolicyDetailsEntry, ExportPolicyListModel, InformalPolicyEntryV2, InformalPolicyV2 } from 'src/app/shared/models/policy.models';

@Injectable({
  providedIn: 'root'
})
export class ReportsApiService {

  constructor(
    private http: HttpClient
  ) { }

  exportContractingReport(): Observable<Blob> {
    return this.http.get<Blob>('/api/ContractReport/Export', { responseType: 'blob' as 'json' });
  }

  exportCpcPolicies(cpcPolicies: CpcPolicy[]): Observable<Blob> {
    return this.http.post<Blob>('/api/Policies/Cpcs/Export', cpcPolicies, { responseType: 'blob' as 'json' });
  }

  exportInformalPolicies(): Observable<Blob> {
    return this.http.post<Blob>('/api/Policies/Informal/Export', { AgentIds: '', CanSeeAgentComissions: true, IncludeUser: true }, { responseType: 'blob' as 'json' });
  }

  exportPolicies(model: ExportPolicyListModel): Observable<Blob> {
    return this.http.post<Blob>('/api/Policies/Export', model, { responseType: 'blob' as 'json' });
  }

  getAgentBusinessSummary(arcAgentGuid: string): Observable<AgentBusinessSummary> {
    return this.http.get<AgentBusinessSummary>(`/api/Agents/${arcAgentGuid}/BusinessSummary`);
  }

  getAgentUploadsByGuid(policyGuid: string): Observable<PolicyTask[]> {
    return this.http.get<ListResponse<PolicyTask>>(`/api/Policies/${policyGuid}/AgentUploads`)
      .pipe(map((x: ListResponse<PolicyTask>) => x.Entries));
  }

  getAgentYearlyProduction(arcAgentGuid: string): Observable<AgentYearlyProduction[]> {
    return this.http.get<AgentYearlyProduction[]>(`/api/Agents/${arcAgentGuid}/YearlyProduction`);
  }

  /** post to return all CPC policies related to a list of commission groups
   * @param agentCommissionGroupList list of agent groups
   * @return CpcPolicy array
   */
  getCommissionGroupCpcPolicies(agentCommissionGroupList: AgentCommissionGroupList): Observable<CpcPolicy[]> {
    return this.http.post<ListResponse<CpcPolicy>>('/api/Policies/Cpcs/CommissionGroup', agentCommissionGroupList)
      .pipe(map((x: ListResponse<CpcPolicy>) => x.Entries.map(p => {
        p.StatementDate = p.StatementDate ? new Date(p.StatementDate) : null;
        return p;
      })));
  }

  getCommissionGroupInforcePaidPolicies(agentCommissionGroupList: AgentCommissionGroupList): Observable<Policy[]> {
    return this.http.post<ListResponse<Policy>>('/api/Policies/Inforce/Paid', agentCommissionGroupList)
      .pipe(map((x: ListResponse<Policy>) => x.Entries.map(p => {
        p.InforceDate = p.InforceDate ? new Date(p.InforceDate) : null;
        p.PolicyStatusDate = p.PolicyStatusDate ? new Date(p.PolicyStatusDate) : null;
        return p;
      })));
  }

  getCommissionGroupInforcePolicies(agentCommissionGroupList: AgentCommissionGroupList): Observable<Policy[]> {
    return this.http.post<ListResponse<Policy>>('/api/Policies/Inforce/CommissionGroup', agentCommissionGroupList)
      .pipe(map((x: ListResponse<Policy>) => x.Entries.map(p => {
        p.InforceDate = p.InforceDate ? new Date(p.InforceDate) : null;
        p.PolicyStatusDate = p.PolicyStatusDate ? new Date(p.PolicyStatusDate) : null;
        return p;
      })));
  }

  getCommissionGroupInforceVariablePolicies(agentCommissionGroupList: AgentCommissionGroupList): Observable<Policy[]> {
    return this.http.post<ListResponse<Policy>>('/api/Policies/Inforce/Variable/CommissionGroup', agentCommissionGroupList)
      .pipe(map((x: ListResponse<Policy>) => x.Entries.map(p => {
        p.InforceDate = p.InforceDate ? new Date(p.InforceDate) : null;
        p.PolicyStatusDate = p.PolicyStatusDate ? new Date(p.PolicyStatusDate) : null;
        return p;
      })));
  }

  getCommissionGroupPendingPolicies(agentCommissionGroupList: AgentCommissionGroupList): Observable<Policy[]> {
    return this.http.post<ListResponse<Policy>>('/api/Policies/Pending/CommissionGroup', agentCommissionGroupList)
      .pipe(map((x: ListResponse<Policy>) => x.Entries.map(p => {
        p.PolicyStatusDate = p.PolicyStatusDate ? new Date(p.PolicyStatusDate) : null;
        return p;
      })));
  }

  getCommissionGroupPendingVariablePolicies(agentCommissionGroupList: AgentCommissionGroupList): Observable<Policy[]> {
    return this.http.post<ListResponse<Policy>>('/api/Policies/Pending/Variable/CommissionGroup', agentCommissionGroupList)
      .pipe(map((x: ListResponse<Policy>) => x.Entries.map(p => {
        p.PolicyStatusDate = p.PolicyStatusDate ? new Date(p.PolicyStatusDate) : null;
        return p;
      })));
  }

  getContractDetail(contractGuid: string, lineOfBusinessGuid: string): Observable<ContractDetail> {
    return this.http.get<SingleResponse<ContractDetail>>(`/api/ContractReport/${contractGuid}/LineOfBusiness/${lineOfBusinessGuid}`)
      .pipe(map((x: SingleResponse<ContractDetail>) => x.Entries));
  }

  getContracts(): Observable<Contract[]> {
    return this.http.get<ListResponse<Contract>>('/api/ContractReport/AgentContracts')
      .pipe(map((x: ListResponse<Contract>) => x.Entries));
  }

  getCpcPolicies(agentGuidList: AgentGuidList): Observable<CpcPolicy[]> {
    return this.http.post<ListResponse<CpcPolicy>>('/api/Policies/Cpcs', agentGuidList)
      .pipe(map((x: ListResponse<CpcPolicy>) => x.Entries.map(p => {
        p.StatementDate = p.StatementDate ? new Date(p.StatementDate) : null;
        return p;
      })));
  }


  getInforcePaidPolicies(agentGuidList: AgentGuidList): Observable<Policy[]> {
    return this.http.post<ListResponse<Policy>>('/api/Policies/Inforce/Paid', agentGuidList)
      .pipe(map((x: ListResponse<Policy>) => x.Entries.map(p => {
        p.InforceDate = p.InforceDate ? new Date(p.InforceDate) : null;
        p.PolicyStatusDate = p.PolicyStatusDate ? new Date(p.PolicyStatusDate) : null;
        return p;
      })));
  }

  getInforcePolicies(agentGuidList: AgentGuidList): Observable<Policy[]> {
    return this.http.post<ListResponse<Policy>>('/api/Policies/Inforce', agentGuidList)
      .pipe(map((x: ListResponse<Policy>) => x.Entries.map(p => {
        p.InforceDate = p.InforceDate ? new Date(p.InforceDate) : null;
        p.PolicyStatusDate = p.PolicyStatusDate ? new Date(p.PolicyStatusDate) : null;
        return p;
      })));
  }

  getInforceVariablePolicies(agentGuidList: AgentGuidList): Observable<Policy[]> {
    return this.http.post<ListResponse<Policy>>('/api/Policies/Inforce/Variable', agentGuidList)
      .pipe(map((x: ListResponse<Policy>) => x.Entries.map(p => {
        p.InforceDate = p.InforceDate ? new Date(p.InforceDate) : null;
        p.PolicyStatusDate = p.PolicyStatusDate ? new Date(p.PolicyStatusDate) : null;
        return p;
      })));
  }

  getInformalDetail(policyGuid: string): Observable<InformalPolicyDetails> {
    return this.http.get<InformalPolicyDetailsEntry>(`/api/Policies/Informal/${policyGuid}`)
      .pipe(map((x: InformalPolicyDetailsEntry) => x.Entry));
  }

  getInformalDetailV2(policyGuid: string): Observable<InformalPolicyV2> {
    return this.http.get<InformalPolicyEntryV2>(`/api/Policies/Informal/v2/${policyGuid}`)
      .pipe(map((x: InformalPolicyEntryV2) => x.Entry));
  }

  getInformalPolicies(): Observable<InformalTablePolicy[]> {
    return this.http.get<ListResponse<InformalTablePolicy>>('/api/Policies/Informal')
      .pipe(map((x: ListResponse<InformalTablePolicy>) => x.Entries));
  }

  getIsVariablePolicy(policyGuid: string): Observable<boolean> {
    return this.http.get<boolean>(`/api/Policies/${policyGuid}/IsVariable`);
  }

  /**
   * Get all paper apps uploaded via modal by agent
   * @return [{CreatedOn: Date, Notes, PaperAppHistoryFileModel[]}]
   */
  getPaperApplicationHistory(): Observable<PaperApplicationHistoryModel[]> {
    return this.http.get<PaperApplicationHistoryModel[]>('/api/FileUpload/PaperApplication/V2/History');
  }

  getPendingPolicies(agentGuidList: AgentGuidList): Observable<Policy[]> {
    return this.http.post<ListResponse<Policy>>('/api/Policies/Pending', agentGuidList)
      .pipe(map((x: ListResponse<Policy>) => x.Entries.map(p => {
        p.PolicyStatusDate = p.PolicyStatusDate ? new Date(p.PolicyStatusDate) : null;
        return p;
      })));
  }

  getPendingVariablePolicies(agentGuidList: AgentGuidList): Observable<Policy[]> {
    return this.http.post<ListResponse<Policy>>('/api/Policies/Pending/Variable', agentGuidList)
      .pipe(map((x: ListResponse<Policy>) => x.Entries.map(p => {
        p.PolicyStatusDate = p.PolicyStatusDate ? new Date(p.PolicyStatusDate) : null;
        return p;
      })));
  }

  getPolicyCaseStatusByGuid(policyGuid: string): Observable<PolicyTask> {
    return this.http.get<EntryResponse<PolicyTask>>(`/api/Policies/${policyGuid}/CaseStatus`)
      .pipe(map((x: EntryResponse<PolicyTask>) => x.Entry));
  }

  getPolicyDetailsByGuid(policyGuid: string): Observable<PolicyDetail> {
    return this.http.get<EntryResponse<PolicyDetail>>(`/api/Policies/${policyGuid}`)
      .pipe(map((x: EntryResponse<PolicyDetail>) => x.Entry));
  }

  getPolicyFundingByGuid(policyGuid: string): Observable<PolicyFunding[]> {
    return this.http.get<EntryResponse<PolicyFunding[]>>(`/api/Policies/${policyGuid}/Funding`)
      .pipe(map((x: EntryResponse<PolicyFunding[]>) => x.Entry));
  }

  getPolicyRequirementHistoryByGuid(policyGuid: string): Observable<PolicyTask[]> {
    return this.http.get<EntryResponse<PolicyTask[]>>(`/api/Policies/${policyGuid}/Requirements`)
      .pipe(map((x: EntryResponse<PolicyTask[]>) => x.Entry));
  }

  getPolicySuitabilityStatusByGuid(policyGuid: string): Observable<PolicySuitability> {
    return this.http.get<EntryResponse<PolicySuitability>>(`/api/Policies/${policyGuid}/SuitabilityStatus`)
      .pipe(map((x: EntryResponse<PolicySuitability>) => x.Entry));
  }

  getSubAgents(): Observable<AgentSubAgent[]> {
    return this.http.get<ListResponse<AgentSubAgent>>('/api/SubAgents')
      .pipe(map((x: ListResponse<AgentSubAgent>) => x.Entries));
  }

  getSubEntities(): Observable<AgentSubEntity[]> {
    return this.http.get<ListResponse<AgentSubEntity>>('/api/SubAgents/SubEntities')
      .pipe(map((x: ListResponse<AgentSubEntity>) => x.Entries));
  }

  getVariablePolicyCaseStatusByGuid(policyGuid: string): Observable<PolicyTask> {
    return this.http.get<EntryResponse<PolicyTask>>(`/api/Policies/Variable/${policyGuid}/CaseStatus`)
      .pipe(map((x: EntryResponse<PolicyTask>) => x.Entry));
  }

  getVariablePolicyDetailsByGuid(policyGuid: string): Observable<PolicyDetail> {
    return this.http.get<EntryResponse<PolicyDetail>>(`/api/Policies/Variable/${policyGuid}`)
      .pipe(map((x: EntryResponse<PolicyDetail>) => x.Entry));
  }

  /**
   * Post paper app files and notes for quick uploads for data analysis
   * @param formData {notes:string, Files[]}
   * @returns Observable<string>
   */
  postPaperApplicationV2(formData: FormData): Observable<string> {
    console.log(formData);
    return this.http.post('/api/FileUpload/PaperApplication/V2', formData, { responseType: 'text' });
  }

  /**
   * Post files to create a task in ARC.
   * TODO: Consider validation on file size and/or file count. When tested locally, it could take up to 20-30s to upload 5-6 PDF files
   * @param formData Standard form data
   * @returns `Observable<FileUploadInfoModel[]>`
   */
  postNewPolicyFiles(formData: FormData): Observable<string> {
    return this.http.post('/api/FileUpload/Policy', formData, { responseType: 'text' });
  }

  /**
   * Post files to a policy or policy's task in ARC.
   * TODO: Consider validation on file size and/or file count. When tested locally, it could take up to 20-30s to upload 5-6 PDF files
   * @param policyGuid Used to tie the files to a policy. This is required as of 5/5/22 since `taskId` is now optional
   * @param formData Standard form data
   * @param taskId Optional. Passed in as a `queryParam`. When `taskId` is present, the files will be tied to a task in ARC (Usually a subcategory/requirement/checklist item for Life/LTC as of 5/5/22)
   * @returns `Observable<FileUploadInfoModel[]>`
   */
  postPolicyFiles(policyGuid: string, formData: FormData, taskId?: number,): Observable<FileUploadInfoModel[]> {
    return this.http.post<FileUploadInfoModel[]>(`/api/FileUpload/${policyGuid}/Task?taskId=${taskId}`, formData);
  }

  /**
   * @param note Simple note model, no richtext formatting supported
   * @param policyGuid Used to tie the note to a policy. This is required as of 5/5/22 since `taskId` is now optional
   * @param taskId Optional. Passed in as a `queryParam`. When `taskId` is present, the note will be tied to a task in ARC (Usually a subcategory/requirement/checklist item for Life/LTC as of 5/5/22)
   * @returns `Observable<Note>` It's been noted that some values on this return model is incorrect, such as `IsExternal = false` when it should be `true` for all agent's messages
   */
  postPolicyNotes(note: string, policyGuid: string, taskId?: number): Observable<Note> {
    if (taskId) return this.http.post<Note>(`/api/Policies/${policyGuid}/Notes?taskId=${taskId}`, { Note: note });
    else return this.http.post<Note>(`/api/Policies/${policyGuid}/Notes`, { Note: note });
  }
}
