import { Component, EventEmitter, OnDestroy, Output, type OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TourService } from 'ngx-ui-tour-md-menu';
import { Subject, timer } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { AmplitudeEventService } from 'src/app/core/services/amplitude/amplitude-event.service';
import { SalesforceSsoService } from 'src/app/core/services/http/salesforce-sso.service';
import { WealthboxApiService } from 'src/app/core/services/http/wealthbox-api.service';
import { SessionStoreService } from 'src/app/core/services/stores/session-store.service';
import { ManageIntegrationsModalComponent } from 'src/app/portal/modals/manage-integrations-modal/manage-integrations-modal.component';
import { ManageIntegrationsEnum } from 'src/app/shared/enums/integrations.enum';
import { ManageIntegrationResModel } from 'src/app/shared/models/intergrations.models';

/**
 * TODO: There is a small bug related to when a user is connected to more than 1 CRM
 * Ex: If connected to Redtail, user can click on Redtail to open the integrations modal, but can then go to the WB tab to connect to WB, the modal will close but the UI will have no idea that the useris now connected to both Redtail & WB.
 * Could pose issue to search. Mentioned to Angelas & Brad & they think this is super edge-case that we don't have to worry about for now but leaving this for posterity
 */
@Component({
  selector: 'app-integrations-menu',
  templateUrl: './integrations-menu.component.html',
  styleUrls: ['./integrations-menu.component.scss'],
})
export class IntegrationsMenuComponent implements OnInit, OnDestroy {

  constructor(
    private dialog: MatDialog,
    private sessionStore: SessionStoreService,
    private wealthboxApiService: WealthboxApiService,
    private salesforceSsoService: SalesforceSsoService,
    private tourService: TourService,
    private amplitudeEventService: AmplitudeEventService,
  ) { }

  @Output() integrationsChanged = new EventEmitter<ManageIntegrationsEnum[]>();
  integrations: ManageIntegrationsEnum[] = [];
  manageIntegrationEnum = ManageIntegrationsEnum;
  isWealthboxUser = !!this.sessionStore.IsWealthboxUser;
  isSalesforceUser = !!this.sessionStore.IsSalesforceUser;
  isRedtailUser = !!this.sessionStore.User.IsRedtailUser;
  closeTimer$ = new Subject<boolean>();

  ngOnInit(): void {
    if (this.isRedtailUser) {
      this.integrations.push(ManageIntegrationsEnum.Redtail);
    }
    if (this.isSalesforceUser) {
      this.integrations.push(ManageIntegrationsEnum.Salesforce);
    }
    if (this.isWealthboxUser) {
      this.integrations.push(ManageIntegrationsEnum.Wealthbox);
    }
    this.integrationsChanged.emit(this.integrations);
  }

  ngOnDestroy(): void {
    this.closeTimer$.unsubscribe();
  }

  endTour(): void {
    this.tourService.end();
  }

  removeIntegration(integration: ManageIntegrationsEnum): void {
    const index = this.integrations.indexOf(integration, 0);
    if (index > -1) {
      this.integrations.splice(index, 1);
      this.integrationsChanged.emit(this.integrations);
    }
  }

  addIntegration(integration: ManageIntegrationsEnum): void {
    const index = this.integrations.indexOf(integration);
    if (index === -1) {
      this.integrations.push(integration);
      this.integrationsChanged.emit(this.integrations);
    }
  }

  hasIntegration(integration: ManageIntegrationsEnum): boolean {
    return this.integrations.includes(integration);
  }

  openManageIntegrationModal(integration: ManageIntegrationsEnum): void {
    const dialog = this.dialog.open<ManageIntegrationsModalComponent, ManageIntegrationsEnum, ManageIntegrationResModel>(ManageIntegrationsModalComponent, {
      data: integration,
    });

    dialog.afterClosed().subscribe({
      next: res => {
        if (res === null || res === undefined) return;
        switch (res.crmIntegration) {
          case ManageIntegrationsEnum.Redtail:
            this.isRedtailUser = this.sessionStore.User.IsRedtailUser;
            if (this.isRedtailUser) {
              this.addIntegration(ManageIntegrationsEnum.Redtail);
            } else {
              this.removeIntegration(ManageIntegrationsEnum.Redtail);
            }
            break;
          case ManageIntegrationsEnum.Salesforce:
            if (res.isConnected) {
              this.activateSalesforce();
            } else {
              this.deactivateSalesforce();
            }
            break;
          case ManageIntegrationsEnum.Wealthbox:
            if (res.isConnected) {
              this.activateWealthbox();
            } else {
              this.deactivateWealthbox();
            }
            break;
          default:
            break;
        }
      }
    });
  }

  /** Let user log into RedTail */
  openRedtailIntegrationModal(): void {
    const dialog = this.dialog.open<ManageIntegrationsModalComponent, ManageIntegrationsEnum, boolean>(ManageIntegrationsModalComponent, {
      data: ManageIntegrationsEnum.Redtail
    });

    // Once user connects/disconnects successfully, the MatDialog response will be true/false respectively. If user closes dialog without taking action, don't update UI
    dialog.afterClosed().subscribe({
      next: res => {
        if (res === null || res === undefined) return;
        this.isRedtailUser = this.sessionStore.User.IsRedtailUser;
        if (this.isRedtailUser) {
          this.addIntegration(ManageIntegrationsEnum.Redtail);
        } else {
          this.removeIntegration(ManageIntegrationsEnum.Redtail);
        }
      }
    });
  }

  openWealthboxIntegrationModal(): void {
    const dialog = this.dialog.open<ManageIntegrationsModalComponent, ManageIntegrationsEnum, boolean>(ManageIntegrationsModalComponent, {
      data: ManageIntegrationsEnum.Wealthbox
    });

    // Once user connects/disconnects successfully, the MatDialog response will be true/false respectively. If user closes dialog without taking action, don't update UI
    dialog.afterClosed().subscribe({
      next: res => {
        if (res === null || res === undefined) return;
        if (res) {
          this.activateWealthbox();
        } else {
          this.deactivateWealthbox();
        }
      }
    });
  }

  openSalesforceIntegrationModal(): void {
    const dialog = this.dialog.open<ManageIntegrationsModalComponent, ManageIntegrationsEnum, boolean>(ManageIntegrationsModalComponent, {
      data: ManageIntegrationsEnum.Salesforce
    });

    // Once user connects/disconnects successfully, the MatDialog response will be true/false respectively. If user closes dialog without taking action, don't update UI
    dialog.afterClosed().subscribe({
      next: res => {
        if (res === null || res === undefined) return;
        if (res) {
          this.activateSalesforce();
        } else {
          this.deactivateSalesforce();
        }
      }
    });
  }

  activateWealthbox(): void {
    window.open('/api/Wealthbox/Sso/', '_blank', 'width=800,height=650');
    this.startWealthboxPolling();
  }

  activateSalesforce(): void {
    window.open('/api/Salesforce/Sso/', '_blank', 'width=800,height=650');
    this.startSalesforcePolling();
  }

  startWealthboxPolling(requestInterval = 1000): void {
    timer(0, requestInterval)
      .pipe(
        switchMap(() => this.wealthboxApiService.isConnected()),
        takeUntil(this.closeTimer$)
      )
      .subscribe((res) => {
        this.isWealthboxUser = res;
        if (this.isWealthboxUser) {
          this.sessionStore.setUserWealthboxStatus(this.isWealthboxUser);
          this.addIntegration(ManageIntegrationsEnum.Wealthbox);
          this.closeTimer$.next();
        }
      });
  }

  startSalesforcePolling(requestInterval = 1000): void {
    timer(0, requestInterval)
      .pipe(
        switchMap(() => this.salesforceSsoService.isConnected()),
        takeUntil(this.closeTimer$)
      )
      .subscribe((res) => {
        this.isSalesforceUser = res;
        if (this.isSalesforceUser) {
          this.sessionStore.setUserSalesforceStatus(this.isSalesforceUser);
          this.addIntegration(ManageIntegrationsEnum.Salesforce);
          this.closeTimer$.next();
        }
      });
  }

  deactivateWealthbox(): void {
    this.isWealthboxUser = false;
    this.sessionStore.setUserWealthboxStatus(false);
    this.removeIntegration(ManageIntegrationsEnum.Wealthbox);
  }

  deactivateSalesforce(): void {
    this.isSalesforceUser = false;
    this.sessionStore.setUserSalesforceStatus(false);
    this.removeIntegration(ManageIntegrationsEnum.Salesforce);
  }
}