import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionService } from 'src/app/core/services/auth/permission.service';

@Directive({
  // disabling as the rule won't fit our existing, and legacy applications of the directive
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[showIfAll]',
  standalone: false
})
export class ShowIfAllDirective {

  private hasView = false;

  constructor(
    private templateRef:TemplateRef<unknown>,
    private viewContainerRef:ViewContainerRef,
    private permissionService:PermissionService
  ) { }

  @Input() set showIfAll(permissions:string) {

    const isAuthorized = this.permissionService.hasAllPermissions(permissions);
    if(isAuthorized && !this.hasView){
      this.viewContainerRef.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!isAuthorized && this.hasView) {
      this.viewContainerRef.clear();
      this.hasView = false;
    }
  }
}
