import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Action, PolicyStatement } from '../../../shared/models/permission.models';
import { CookieService } from 'ngx-cookie-service';
import { allOrOneEnum } from 'src/app/shared/models/custom-routes.models';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  private readonly onBehalfOfCookieName: string = 'X-On-Behalf-Of';

  constructor(private http: HttpClient, private cookieService: CookieService) { }


  setPermissions(permissions: PolicyStatement[]): void {
    localStorage.setItem('Auth.Permissions', JSON.stringify(permissions));
  }

  getPermissions(): PolicyStatement[] {
    return JSON.parse(localStorage.getItem('Auth.Permissions') || '[]') as PolicyStatement[];
  }

  getOnBehalfOfUserId(): string | null {
    return this.cookieService.check(this.onBehalfOfCookieName) ? this.cookieService.get(this.onBehalfOfCookieName) : null;
  }

  clearAllLocalStorage(): void {
    this.removeStoredPermissions();
    this.removeStoredOnBehalfOfUserId();
    this.removeMarketingTrackerToken();
  }

  removeStoredPermissions(): void {
    localStorage.removeItem('Auth.Permissions');
  }

  removeStoredOnBehalfOfUserId(): void {
    this.cookieService.delete(this.onBehalfOfCookieName, '/');
    localStorage.removeItem('Auth.UserDelegateId');
  }

  removeMarketingTrackerToken(): void {
    localStorage.removeItem('Auth.MarketingTrackerAccessToken');
  }

  /**
   * * TODO: Move all permission values to `Array<string>` & remove use of pipe-separated string
   * @param permissions * TODO: Move all permission values to `Array<string>` & remove use of pipe-separated string
   * @returns `true | false`
   */
  hasOnePermission(permissions?: string | string[]): boolean {
    if (permissions === null || !permissions || !permissions.length) return true;
    const isString = typeof permissions === 'string';
    const currentUserPermissions = this.getPermissions();
    const checkPermissions = isString ? permissions.split('|') : permissions;
    const obj: any = {};
    let allow: boolean | undefined;

    checkPermissions.forEach(val => {
      const resource = val.split(':')[0];
      const action = val.split(':')[1];
      let explicitDeny = false;

      if (obj[resource + ':' + action] !== false) {
        for (const userPermission of currentUserPermissions) {
          if (explicitDeny) break;
          for (const x of userPermission.Actions) {
            if (x.AllowAll === '*' && userPermission.Effect === 'ALLOW') {
              allow = true;
              break;
            }
            if (x.AllowAll === '*' && userPermission.Effect === 'DENY') {
              allow = false;
              break;
            }
            if (this.hasPermission(x, action, resource)) {
              if (userPermission.Effect === 'ALLOW') {
                obj[resource + ':' + action] = true;
              }
              if (userPermission.Effect === 'DENY') {
                obj[resource + ':' + action] = false;
                explicitDeny = true;
                break;
              }
            }
          }
        }
      }
    });

    if (allow !== undefined) return allow;

    allow = this.containsAtLeastOneAllowPermission(obj);

    return allow;
  }

  /**
   * * TODO: Move all permission values to `Array<string>` & remove use of pipe-separated string
   * @param permissions * TODO: Move all permission values to `Array<string>` & remove use of pipe-separated string
   * @returns `true | false`
   */
  hasAllPermissions(permissions?: string | string[]): boolean {
    if (permissions === null || !permissions || !permissions.length) return true;
    const isString = typeof permissions === 'string';
    const currentUserPermissions = this.getPermissions();
    const checkPermissions = isString ? permissions.split('|') : permissions;
    let allow = false;
    let breakRepeat = false;

    for (const val of checkPermissions) {
      const resource = val.split(':')[0];
      const action = val.split(':')[1];
      let exists = false;
      let explicitDeny = false;
      if (breakRepeat) break;

      for (const userPermission of currentUserPermissions) {
        if (explicitDeny === true) break;

        for (const x of userPermission.Actions) {
          if (x.AllowAll === '*' && userPermission.Effect === 'ALLOW') {
            allow = true;
            exists = true;
            break;
          }

          if (x.AllowAll === '*' && userPermission.Effect === 'DENY') {
            allow = false;
            exists = true;
            break;
          }

          if (this.hasPermission(x, action, resource)) {
            if (userPermission.Effect === 'ALLOW') {
              allow = true;
            }
            if (userPermission.Effect === 'DENY') {
              allow = false;
              explicitDeny = true;
              breakRepeat = true;
              break;
            }

            exists = true;
          }
        }
      }

      if (!exists) {
        allow = false;
        breakRepeat = true;
        return allow;
      }
    }
    return allow;
  }

  private hasPermission(permissionAction: Action, userAction: string, resource: string): boolean {
    if (permissionAction.Action.toLowerCase() === userAction.toLowerCase() && permissionAction.Resource.toLowerCase() === resource.toLowerCase())
      return true;
    else
      return false;
  }

  /**
   * Handles deny and/or allow permission tree
   * @param denyPermissions if user has any deny permission will return false
   * @param showPermissions permission(s) user must have to return true
   * @param allOrOne optional enum that tells which permission test will validate.
   * ['showIfOne', 'showIfAll'] - defaults to 'showIfOne'
   * @returns false if user has any denied permissions, or true if user permitted for all or one permission
   * TODO: Move all permission values to `Array<string>` & remove use of pipe-separated string
   */
  hasPermissions(showPermissions: string | string[], denyPermissions?: string | string[], allOrOne?: allOrOneEnum): boolean {
    let canAccess = true;
    if (denyPermissions && this.hasOnePermission(denyPermissions)) {
      canAccess = false;
    } else if (allOrOne === allOrOneEnum.showIfAll) {
      canAccess = this.hasAllPermissions(showPermissions);
    } else {
      canAccess = this.hasOnePermission(showPermissions);
    }
    return canAccess;
  }

  private containsAtLeastOneAllowPermission(obj: any): boolean {
    // See if any keys in the object contain a "true" value
    return Boolean(Object.keys(obj).find(row => obj[row]));
  }

  getUserDelegateId(): string | null {
    return localStorage.getItem('Auth.UserDelegateId');
  }

  setOnBehalfOfUserId(userId: string): void {
    // using cookies here instead of localStorage so that requests for new tabs and iframes will include the cookie automatically
    this.cookieService.set(this.onBehalfOfCookieName, userId, { path: '/', sameSite: 'Lax' });
  }

  setUserDelegateId(userDelegateId: string): void {
    localStorage.setItem('Auth.UserDelegateId', userDelegateId);
  }
}
