import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { tap, filter, debounceTime, switchMap, distinctUntilChanged, map } from 'rxjs/operators';
import { RedtailApiService } from 'src/app/core/services/http/redtail-api.service';
import { RedtailContact } from 'src/app/shared/models/redtail-contact.models';
import { Contact } from '../../models/contact.models';

@Component({
  selector: 'app-redtail-search-form',
  templateUrl: './redtail-search-form.component.html',
  styleUrls: ['./redtail-search-form.component.scss'],
  standalone: false
})

export class RedtailSearchFormComponent implements OnInit {
  @Output() switchToManualClientInput = new EventEmitter<boolean>();
  @Output() contactSelected = new EventEmitter<RedtailContact>();
  @Input() parentCtrl: FormControl<Contact | string | null | undefined> = this.fb.control(null);
  isSearching = false;
  hasError = false;
  noContactsFound = false;
  contacts: Contact[] = [];
  isRedtailContact = false;

  constructor(private redtailApiService: RedtailApiService, private fb: FormBuilder) { }

  ngOnInit(): void {
    // W/e the input value changes in the search box
    this.parentCtrl.valueChanges
      .pipe(
        tap(value => {
          this.isSearching = true;
          this.noContactsFound = false;
          this.hasError = false;
          this.checkIsRedtailContact(value);
        }),
        // wait 350ms to start search to avoid consecutive calls while user is typing
        debounceTime(350),
        map(value => {
          return value && typeof value === 'string' ? value : '';
        }),
        filter(searchText => {
          // We don't want to search if input is empty because it will return all contacts for user which is an expensive api call
          if (!searchText) {
            this.isSearching = false;
            this.contacts = [];
            return false;
          }
          return true;
        }),
        distinctUntilChanged(),
        // switchMap will cancel current API requests if subject is triggered again before request completes
        switchMap((searchText: string) => {
          return this.redtailApiService.searchRedtailContacts({ Name: searchText });
        }))
      .subscribe({
        next: (contacts: RedtailContact[]) => {
          this.contacts = contacts.map(this.convertRedtailContactToContactModel);
          this.isSearching = false;
          this.noContactsFound = !contacts.length;
        },
        error: () => {
          this.isSearching = false;
          this.hasError = true;
          this.noContactsFound = true;
          this.contacts = [];
        }
      });
  }

  setDisplayValue(contact: Contact): string {
    return contact.FirstName + ' ' + contact.LastName;
  }

  checkIsRedtailContact(value: Contact | string | null | undefined): void {
    if (!value || typeof value === 'string') {
      this.isRedtailContact = false;
      return;
    } else {
      this.isRedtailContact = true;
    }
  }

  requestManualInput(): void {
    this.parentCtrl.disable();
    this.switchToManualClientInput.next(true);
  }

  convertRedtailContactToContactModel(redtailContact: RedtailContact): Contact {
    const primaryAddress = redtailContact.Addresses.find(a => a.IsPrimary);
    const primaryEmail = redtailContact.Emails.find(e => e.IsPrimary)?.Address;
    const primaryPhone = redtailContact.Phones.find(p => p.IsPrimary);
    return {
      FirstName: redtailContact.FirstName,
      LastName: redtailContact.LastName,
      Alias: redtailContact.Nickname,
      Dob: redtailContact.Dob,
      EmployerName: redtailContact.Employer,
      FullName: redtailContact.FullName,
      Gender: redtailContact.Gender,
      MaritalStatus: redtailContact.MaritalStatus,
      MiddleName: redtailContact.MiddleName,
      Occupation: redtailContact.JobTitle,
      PrimaryEmailAddress: primaryEmail,
      PrimaryPhoneNumber: primaryPhone?.Number,
      PrimaryAddress1: primaryAddress?.StreetAddress,
      PrimaryAddress2: primaryAddress?.SecondaryAddress,
      PrimaryAddressCity: primaryAddress?.City,
      PrimaryAddressCountry: primaryAddress?.Country,
      PrimaryAddressState: primaryAddress?.State,
      PrimaryAddressZipcode: primaryAddress?.Zip,
      Ssn: redtailContact.TaxId,
      Suffix: redtailContact.Suffix,
      Crm: 'Redtail',
      Prefix: redtailContact.Salutation,
      CompanyName: redtailContact.CompanyName,
    };
  }
}
