import { Component } from '@angular/core';

@Component({
  selector: 'app-next',
  templateUrl: './next.component.html',
  standalone: false
})
export class NextComponent {
  nextSteps: Step[] = [
    {
      Id: 1,
      Title: 'Continuity Basic',
      Text: ['It all begins with a basic plan in case of a triggering event such as long-term disability or untimely death. What is the protocol and implementation process for your business to continue should something happen to you? This requires a partner, plan and necessary documentation to solidify and protect you and yours. Next will guide you through continuity planning to ensure successful transition for every party involved, most importantly your valued clients.']
    },
    {
      Id: 2,
      Title: 'Succession Discovery',
      Text: ['When you think about your eventual retirement from your business what comes to mind? Do you plan an immediate exit, or do you believe you will remain involved as long as possible? Every business owner has a different outlook when it comes to their specific exit strategy. Understanding what that is now and adapting to future changes to your plan is important to any successful succession.']
    },
    {
      Id: 3,
      Title: 'Business Consulting',
      Text: ['Business Consulting is a complete strategic and tactical evaluation of your business model. Understanding everything about your business is important in succession planning to enable growth and value creation in your business for the future. Everything from key employees and internal systems, to branding and ﬁrm ﬁnances are evaluated in the formal business consulting process. This step is a necessity for ﬁrms looking to increase valuations over time, and unlock every opportunity to enhance and grow the business.']
    },
    {
      Id: 4,
      Title: 'Succession Plan Outline and Mapping',
      Text: ['Drafting your speciﬁc plan and mapping all intricacies involved. This will be the guidance used to help everyone involved reach the ﬁnal destination. Over time this may include variations or added steps based on your changing needs or desires. Mapping is the single best way to ensure accountability and management of your personal plan.']
    },
    {
      Id: 5,
      Title: 'Back Office Logistics',
      Text: ['Throughout your Next succession process you may be introduced to hand-selected alliances to aid in portions of the process whereby Next believes the alliance to be the best source to complete that portion of the transaction. Our back ofﬁce logistics has mastered the process of valuation, creating methods for the merger or acquisition of your business, and the necessary steps and details in executing.']
    },
    {
      Id: 6,
      Title: 'Financing Structure',
      Text: ['The math behind the deal is a key step that will often make or break the entire transaction. We will discuss all funding options and any steps needed to attract and retain ﬁnancing. This may involve special ﬁnancing options beneﬁcial to parties of the transaction. Next will continue to investigate select alliances that specialize in ﬁnancial services ﬁrm lending.']
    },
    {
      Id: 7,
      Title: 'Document Analysis, Coordination and Review',
      Text: ['Just as in any large transaction, such as a home mortgage, you should expect a multitude of documents in succession planning. Analyzing, coordinating with all parties such as your legal and tax counsel, and reviewing continuous changes are all important elements to this process.']
    },
    {
      Id: 8,
      Title: 'Strategic and Tactical Growth Modeling',
      Text: ['In planning your future exit, growing your business throughout the transition period is important for potential valuation increases and in helping the business continue to thrive for its new partners and employees. Using a combination of the Surge 20.20 process, recommendations from alliances, and remaining accountable to the mapping process, a growth model will remain priority as the business continues to mature. A successful succession plan does not mean pause, rather grow even stronger.']
    },
    {
      Id: 9,
      Title: 'Culture Coordination',
      Text: ['Merging two sometimes distinct business cultures can be challenging. Who is in charge, who will execute each function of ﬁrm processes, who will be a leader, and who is completely offended of the upcoming transition? These and many other questions are addressed in creating a plan for seamless culture merging. Like ﬁnancing, this step is very important to the future success of the business model and retention of clients. Failure to address cultural differences may result in long-term dissatisfaction for all parties, including clients.']
    },
    {
      Id: 10,
      Title: 'Ongoing Business and Succession Review',
      Text: ['The plan is in place and you are satisfied we have created a successful succession. Like any other plan, constant review and accountability to all aspects of the plan are a must. In reviewing your plan at least annually, we are able to adjust if necessary to protect all parties involved. Your business will change over time and the succession plan might need to in tandem. A formal business and succession plan review process will be implemented for you from inception.']
    },
    {
      Id: 11,
      Title: 'Plan Execution and Transition',
      Text: ['It is time for the merger or acquisition to take place. The process of ﬁrm blending and all the behind the scenes necessities to complete a thorough and well-executed transition are examined and reviewed to enhance the experience.']
    },
    {
      Id: 12,
      Title: 'Advisor Exit Implementation',
      Text: ['At some point in any successful succession, the selling advisor will transition away from the business. This may be methodical over time or abrupt based on the desires of the parties involved. There are key steps taken to protect all involved as the advisor exits. This includes effective communication to staff and clients, and processes in place to protect alliances and outside parties involved. This is the ﬁnal step in a Next successful succession.']
    }
  ];
  constructor() { }
}

interface Step {
  Id: number,
  Title: string,
  Text: string[]
}