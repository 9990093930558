@if (data) {
  <section class="flex flex-col flex-grow w-full h-full">
    @if (data?.title) {
      <header class="flex items-center flex-none p-20 border-b border-solid border-gray-lighter">
        <h2 class="m-10 text-lg font-bold" data-cy="confirmationTitle">{{data.title}}</h2>
      </header>
    }
    <section class="flex flex-col flex-grow overflow-auto p-30">
      @if (data?.message) {
        <p class="m-auto text-lg">{{data.message}}</p>
      }
    </section>
    <div class="flex flex-none p-20 mt-auto border-t border-solid border-gray-lighter">
      @if (!data.hideCancelButton) {
        <button appCyData="confirmationDeclineBtn" mat-button type="button" class="m-10 button is-primary is-outlined" [mat-dialog-close]="false">Cancel</button>
      }
      <button appCyData="confirmationAcceptBtn" mat-button type="button" class="m-10 ml-auto button" [class]="data.isWarning ? 'is-warning' : 'is-primary'"
      [mat-dialog-close]="true">{{data.confirmationButtonText ? data.confirmationButtonText : 'Confirm'}}</button>
    </div>
  </section>
}