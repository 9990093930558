import { Component, Input, OnInit } from '@angular/core';
import { fadeIn } from 'src/app/animations';

@Component({
  animations: [fadeIn],
  selector: 'app-new-feature',
  templateUrl: './new-feature.component.html',
})
export class NewFeatureComponent implements OnInit {
  @Input() expirationDate?: NewFeatureExpirationDate;
  hasExpired = false;

  ngOnInit(): void {
    this.getExpiration();
  }

  /** Compare today's date to expiration date to set the hasExpired boolean. Subtract 1 from month
   * because it is zero indexed
   */
  getExpiration(): void {
    if (this.expirationDate) {
      const today: Date = new Date();
      this.hasExpired = today > new Date(this.expirationDate.year, this.expirationDate.month -1, this.expirationDate.day);
    }
  }
}

export interface NewFeatureExpirationDate {
  day: number; //DD
  month: number; //MM
  year: number; //YYYY
}