<div class="flex items-center flex-none border-b p-20 sm:p-30 gap-x-20 border-gray-lighter">
  <h1 class="mr auto text-xl font-bold text-orange-400">FIG Carrier Announcement</h1>
  <button mat-button mat-dialog-close="" class="ml-auto icon-button is-inverted is-primary" type="button" (click)="closeDialog()">
    <i class="material-icons">close</i>
  </button>
</div>
<div class="flex flex-col text-base overflow-auto p-20 sm:p-30 gap-y-20 ">
  <span>We want to keep you informed about potential service delays you may experience with the following carriers:
    <ul class="list-disc font-semibold list-inside ml-20 pt-15">
      <li>Transamerica</li>
      <li>Corebridge</li>
    </ul>
  </span>

  <span>We're working closely with these carriers, but current response times may be delayed. If you have other carrier options that fit your client's needs, consider using them for a smoother experience.</span>
  <span>We'll keep you updated on any changes.</span>
  <span>Thank you for your understanding and patience!</span>
</div>
<div class="flex items-center flex-none mt-auto border-t p-20 sm:p-30 border-gray-lighter">
  <button mat-button type="button" class="ml-auto button is-primary" (click)="launchTermLife()" aria-label="Launch Term Life Quote">
    <span class="flex items-center ml-5">Launch Term Life Quote<i class="material-icons mi-18">chevron_right</i></span>
  </button>
</div>