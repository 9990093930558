import { Component, OnInit } from '@angular/core';
import { fadeIn } from 'src/app/animations';
import { SearchResultModel } from '../../services/models/search-result.model';
import { GlobalSearchService } from '../../services/global-search.service';
import { AmplitudeEventService } from 'src/app/core/services/amplitude/amplitude-event.service';
import { countMapString } from 'src/app/shared/helpers/count-map.helper';

@Component({
  animations: [fadeIn],
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  standalone: false
})
export class GlobalSearchComponent implements OnInit {
  searchEntry = '';
  searchText = '';
  searchResultsList: SearchResultModel[] = [];

  constructor(
    private amplitudeEventService: AmplitudeEventService,
    public globalSearchService: GlobalSearchService,
  ) { }

  ngOnInit(): void {
    this.globalSearchService.searchResultSubject.subscribe(res => this.searchResultsList = res);
  }

  displayFn(result?: SearchResultModel): string {
    const name = result?.Name || '';
    return name;
  }

  filterSearch(): void {
    if (!this.searchText) {
      this.searchResultsList = [];
      return;
    }
    this.searchEntry = this.searchText;
    this.globalSearchService.searchTermSubject.next(this.searchText);
  }

  getSearchTrackingScenario(entry: string, results: string, selected: string): string {
    return entry && results !== searchEnum.noResultSelected && selected !== searchEnum.noResultSelected
      ? '1' //Search Term, Search Results, Search Result Selected
      : selected === searchEnum.noResultSelected && results === searchEnum.noResultFound
        ? '2' //Search Term, NULL (Empty Array), NULL
        : '3'; //Search Term, Search Results, NULL
  }

  logSearchTracking(selectedResult: SearchResultModel | null): void {
    const eventType = 'Global Search';
    const clickTarget = this.searchEntry || searchEnum.noSearchText;
    const selected = selectedResult ? selectedResult?.Name : searchEnum.noResultSelected;
    const results = this.searchResultsList.length ? this.searchResultsList?.map(p => p.Name).join(', ') : searchEnum.noResultFound;
    const eventProps = {
      scenario: this.getSearchTrackingScenario(this.searchEntry, results, selected),
      search_text: this.searchEntry,
      search_results: results,
      search_results_count: countMapString(this.searchResultsList.length),
      selected_result: selected,
    };
    this.amplitudeEventService.logCustomEvent('Search Term: ' + clickTarget, eventType, eventProps, false);
  }
}

const enum searchEnum {
  noSearchText = 'No search text',
  noResultSelected = 'No result selected',
  noResultFound = 'No results found',
}