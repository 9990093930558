export interface SurveyModel {
  SurveyId: number;
  SurveyName: string;
  Questions: SurveyQuestion[];
}

export interface SurveyQuestion {
  SurveyQuestionId: number;
  SurveyQuestionTypeId: number;
  SurveyQuestionType: string;
  SurveyQuestionCategoryId: number;
  SurveyQuestionCategory: string;
  Order: number;
  SurveyQuestionText: string;
  IsRequired: boolean;
  PossibleAnswers: PossibleAnswer[]
  AnswerValue: unknown;
}

export interface PossibleAnswer {
  PossibleAnswerId: number;
  PossibleAnswerText: string;
  IsText: boolean;
  Value: unknown;
}

export interface SurveyAgentAnswerModel {
  TextValue: string;
  SurveyQuestionPossibleAnswerId: number;
  SurveyQuestionId?:number;
}
