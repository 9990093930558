import { Component, OnInit } from '@angular/core';
import { fadeIn } from 'src/app/animations';
import { ActivatedRoute } from '@angular/router';
import indexOf from 'lodash-es/indexOf';
import { LifeInsuranceResourcesApiService } from 'src/app/core/services/http/agentportalresources-api/life-insurance-resources-api.service';
import { forkJoin, of } from 'rxjs';
import { LifeInsuranceResources, LifeInsuranceResourcesCategory, LifeInsuranceResourcesSubCategory } from 'src/app/shared/models/life-insurance-resources.models';
import { MatDialog } from '@angular/material/dialog';
import { FactFinderInstructionsComponent } from 'src/app/portal/modals/fact-finder-instructions/fact-finder-instructions.component';
import { UserVpApiService } from 'src/app/core/services/http/user-vp-api.service';
import { LifeVp } from 'src/app/shared/models/vp.models';
import { SessionStoreService } from 'src/app/core/services/stores/session-store.service';
import { User } from 'src/app/shared/models/user.models';
import { catchError } from 'rxjs/operators';
import { hasAll } from 'src/app/shared/helpers/search.helpers';
import { AmplitudeApiService } from 'src/app/core/services/amplitude/amplitude-api.service';

@Component({
  animations: [fadeIn],
  selector: 'app-life-insurance',
  templateUrl: './life-insurance.component.html',
  standalone: false
})
export class LifeInsuranceComponent implements OnInit {
  user!: User;
  userLifeVp!: LifeVp;
  allPanelsOpened = false;
  categories: LifeInsuranceResourcesCategory[] = [];
  loading = false;
  resources: LifeInsuranceResources[] = [];
  searchText = '';
  showFilteredList = false;
  subCategories: LifeInsuranceResourcesSubCategory[] = [];
  resourceUrl: string | null = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private amplitudeApiService: AmplitudeApiService,
    private dialog: MatDialog,
    private lifeInsuranceResourcesApiService: LifeInsuranceResourcesApiService,
    private userVpApiService: UserVpApiService,
    private sessionStoreService: SessionStoreService,
  ) {
    this.user = this.sessionStoreService.User;
  }

  ngOnInit(): void {
    this.loading = true;
    this.resourceUrl = this.getResourceUrl();

    forkJoin({
      lifeVp: this.userVpApiService.getUserLifeVP().pipe(catchError(error => {
        console.log(error);
        return of({ FirstName: '', LastName: '', EmailAddress: '', });
      })),
      resources: this.lifeInsuranceResourcesApiService.getUserPermittedTiles(),
      categories: this.lifeInsuranceResourcesApiService.getCategories(),
      subCategories: this.lifeInsuranceResourcesApiService.getSubCategories(),
    })
      .subscribe(res => {
        this.userLifeVp = res.lifeVp !== undefined ? res.lifeVp : { FirstName: '', LastName: '', EmailAddress: '', };
        this.resources = res.resources.map((item: LifeInsuranceResources) => {
          if (!item.Fields.HasDocuSignAutoFill) return item;
          else {
            const userParams = `&Agent_UserName=${encodeURIComponent(`${this.user.FirstName} ${this.user.LastName}`)}&Agent_Email=${this.user.Email}`;
            const VpParams = `&${encodeURIComponent('Insert FIG Life VP Name And Email')}_UserName=${encodeURIComponent(`${this.userLifeVp.FirstName} ${this.userLifeVp.LastName}`)}&${encodeURIComponent('Insert FIG Life VP Name And Email')}_Email=${this.userLifeVp.EmailAddress}`;
            item.Fields.ExternalUrl += VpParams + userParams;
            return item;
          }
        });
        this.subCategories = res.subCategories;
        this.categories = res.categories.map((item: LifeInsuranceResourcesCategory) => {
          const index = indexOf(res.categories, item);
          item.Expanded = index === 0 ? true : false;
          return item;
        });
      })
      .add(() => {
        this.loading = false;
      });
  }

  filterBySearchText(resources: LifeInsuranceResources[], searchText: string): LifeInsuranceResources[] {
    if (searchText.length > 0) {
      this.showFilteredList = true;
      this.amplitudeApiService.logEvent(`Life Insurance Resources: Search - ${searchText}`);
      return resources.filter(item => hasAll(item.Fields, searchText));
    } else {
      this.showFilteredList = false;
      return resources;
    }
  }

  getResourceUrl(): string | null {
    return this.activatedRoute.snapshot.paramMap.get('resource');
  }

  getSpecialFeaturedItems(items: LifeInsuranceResources[]): LifeInsuranceResources[] {
    return items.filter(item => item.Fields.SubCategoryName === null);
  }

  openFactFinderModal(): void {
    this.dialog.open(FactFinderInstructionsComponent, {});
  }

  scrollTo(category: LifeInsuranceResourcesCategory, id: string): void {
    category.Expanded = true;
    const el = document.getElementById(id);
    setTimeout(() => {
      el?.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  }

  toggleAccordion(): void {
    let count = 0;
    this.categories.forEach(item => {
      if (item.Expanded) count++;
    });
    if (count === 0 || count < this.categories.length) {
      this.categories.forEach(item => {
        item.Expanded = true;
      });
      this.allPanelsOpened = true;
    } else {
      this.categories.forEach(item => {
        item.Expanded = false;
      });
      this.allPanelsOpened = false;
    }
  }
}