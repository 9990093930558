import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-fact-finder-instructions',
  templateUrl: './fact-finder-instructions.component.html',
  standalone: false
})
export class FactFinderInstructionsComponent {
  instructions: string[] = [
    'Click the link to access the form.',
    'Put your name and email address in the Agent field.',
    'Leave the other fields blank and hit "Begin Signing". This will take you to DocuSign&trade; to view the form.',
    'If printing is needed, click "Print" located near the top-middle of the screen.'
  ];

  constructor(
    private dialog: MatDialog,
  ) { }

  closeDialog(): void {
    this.dialog.closeAll();
  }
}
