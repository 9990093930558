export const PortalCookies: PortalCookiesModel = {
  crmIntegrations: 'IntegrationsAnnouncement',
  amplitudeSurveyCompleted: 'amplitudeSurveyCompleted',
  annualStatementAnnouncementSeen: 'annualStatementAnnouncementSeen',
};

interface PortalCookiesModel {
  crmIntegrations: 'IntegrationsAnnouncement',
  amplitudeSurveyCompleted: 'amplitudeSurveyCompleted',
  annualStatementAnnouncementSeen: 'annualStatementAnnouncementSeen',
}