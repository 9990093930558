<cdk-virtual-scroll-viewport itemSize="58" class="w-full h-full" #virtualScroll @fadeIn>
  <table class="table-auto custom-table">
    <thead>
      <tr>
        <td *cdkVirtualFor="let cell of table.Header"
          class="{{cell.WidthClass}}">
          <span (click)="orderTableBy(cell)" (keypress)="orderTableBy(cell)" class="flex items-center cursor-pointer min-h-24 focus:outline-none"
            attr.aria-label="Sort by {{cell.DisplayName}}">
            <span class="mr-5">{{cell.DisplayName}}</span>
            @if (cell === table.OrderByColumn) {
              <span class="material-icons text-blue-dark">
                {{table.AscendingOrder ? 'expand_less' : 'expand_more'}}
              </span>
            }
          </span>
        </td>
      </tr>
    </thead>
    <tbody>
      @for (row of table.Body; track row) {
        <tr id="policyRow" class="cursor-pointer" aria-label="Go to policy details"
          [ngClass]="{'has-analytics': !!row.SimonPolicyUrl}"
          (click)="!loadingModal ? openPolicyDetails(row) : ''">
          @if (showAgentColumn) {
            <td class="flex items-center">
              @if (row.ActionNeeded) {
                <button type="button" mat-button (click)="openRequirementModal(row)"
                  matTooltip="Action needed. Click to resolve" aria-label="Action needed. Click to resolve"
                  class="z-10 mr-5 -mt-5 icon-button is-alert is-inverted">
                  <span class="material-icons mi-18 text-orange">
                    warning_amber
                  </span>
                </button>
              }
              {{row.Agent}}
            </td>
          }
          <td>
            @if (row.ActionNeeded && !showAgentColumn) {
              <button type="button" mat-button (click)="openRequirementModal(row)"
                matTooltip="Action needed. Click to resolve" aria-label="Action needed. Click to resolve"
                class="icon-button is-alert is-inverted">
                <span class="material-icons mi-18">
                  warning_amber
                </span>
              </button>
            }
            {{row.Clients}}
          </td>
          <td>
          {{row.Carrier}}</td>
          <td>
            <span class="flex flex-col w-full">
              <span class="font-bold">{{row.Product}}</span>
              <span>{{ row.PolicyNumber ? '(' + row.PolicyNumber + ')' :
                ''}} {{row.FaceAmount ? (row.FaceAmount | currency) + ' (Face Amount)' : ''}}
              </span>
            </span>
          </td>
          <td>
            @if (row.IsPaidPolicy && !row.PolicyGuid) {
              <span class="font-bold">Paid Policy</span>
            }
            @if (row.PolicyGuid) {
              <div>
                @if (!row.ActionNeeded) {
                  <div class="flex flex-row">
                    <div class="flex flex-col w-full">
                      <span class="font-bold">{{row.PolicyStatus}}</span>
                      <span>{{row.PolicyStatusDate | date: 'MM/dd/yyyy' : 'UTC'}}</span>
                    </div>
                  </div>
                }
              </div>
            }
          </td>
          <td>
          {{row.InforceDate ? (row.InforceDate | date: 'MM/dd/yyyy' : 'UTC') : '---'}}</td>
          <td>
            <div class="flex flex-col w-full">
              <span class="font-bold">{{row.Premium | currency}}</span>
            </div>
            <span>
              {{row.Target ? (row.Target | currency) + ' (TargetPrem.)' : ''}}
            </span>
          </td>
        </tr>
      }
      @if (!!isError) {
        <tr id="policyErrorRow" class="" aria-label="Error getting policies">
          <td colspan="8"><span class="text-red">Sorry, an error has occurred. Please try again later.</span></td>
        </tr>
      }
      @else if (!isError && table.Body.length===0) {
        <tr id="policyEmptyRow" aria-label="No policies found"
          >
          <td colspan="8">No policies found.</td>
        </tr>
      }
    </tbody>
  </table>

</cdk-virtual-scroll-viewport>