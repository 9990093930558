import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import forEach from 'lodash-es/forEach';
import { fadeIn } from 'src/app/animations';
import { FirelightApiService } from 'src/app/core/services/http/firelight-api.service';
import { ActivityResponse, EApplicationTransactionDocument } from 'src/app/shared/models/firelightApi.models';
import { environment } from 'src/environments/environment';
import { ConfirmationComponent } from '../confirmation/confirmation.component';

@Component({
  animations: [fadeIn],
  selector: 'app-e-application-downloads',
  templateUrl: './e-application-downloads.component.html',
})
export class EApplicationDownloadsComponent implements OnInit {
  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<EApplicationDownloadsComponent>,
    private firelightApiService: FirelightApiService,
    @Inject(MAT_DIALOG_DATA) public data: ActivityResponse,
  ) { }
  environment = environment;
  loadingDocuments = false;
  transactionDocuments: EApplicationTransactionDocument[] = [];

  ngOnInit(): void {
    this.loadingDocuments = true;
    console.log('Data:', this.data);
    if (this.data.TransactionId) this.transactionDocuments = this.data.TransactionDocuments ? this.data.TransactionDocuments : [];
    else this.firelightApiService.getFirelightApplicationDocuments(this.data.ActivityId)
      .subscribe(
        result => {
          this.transactionDocuments = result;
        },
        error => {
          console.log('Error', error);
        })
      .add(() => {
        this.loadingDocuments = false;
      });
  }

  downloadAllDocuments(): void {
    this.dialog.open(ConfirmationComponent, {
      data: {
        title: 'Alert', //optional
        message: `Are you sure you would like to download ${this.transactionDocuments.length} file(s)?`,
        confirmationButtonText: 'confirm', //optional
        isWarning: false, //optional
        hideCancelButton: false, //optional
      }
    }).afterClosed()
      .subscribe((result) => {
        if (result) {//only perform on confirm, not cancel
          forEach(this.transactionDocuments, document => {
            window.open(document.Url);
          });
        }
      });
  }
}
