<section class="flex flex-col w-full h-full overflow-hidden">

  <header class="items-center flex-none border-b md:px-30 border-gray-lighter">
    <div class="flex flex-col justify-between gap-y-5">
      <span class="text-xl font-bold">Paper Application Upload History</span>
    </div>
    <button mat-button
      matTooltip="Close dialog"
      type="button"
      mat-dialog-close
      class="ml-auto icon-button is-primary is-inverted"><i class="material-icons">close</i></button>
    </header>
    <section class="flex flex-col flex-grow p-20 overflow-auto gap-y-10 md:p-30">
      @if (!isLoading) {
        @for (upload of paperUploads; track upload) {
          <div class="flex p-10 rounded bg-gray-lightest">
            <div class="flex flex-col mr-20 gap-y-5">
              <span class="text-sm text-gray" [innerText]="upload.CreatedOn | date: 'M/d/YYY H:mm a'"></span>
              <span class="text-sm" [innerText]="upload.Note"></span>
            </div>
            <div class="flex flex-col ml-auto text-right gap-y-5">
              @for (file of upload.Files; track file) {
                <span class="text-xs text-gray whitespace-nowrap" [innerText]="file.FileName"
                  >
                </span>
              }
            </div>
          </div>
        }@empty {
          <div class="flex items-center justify-center w-full h-full p-20">
            <span class="px-20 py-10 mx-auto rounded-sm bg-gray-lightest border-1 border-gray">No paper applications found.</span>
          </div>
        }
      }@else {
        <div class="relative w-full h-full p-20 loader-wrap">
          <div class="loader-sm is-blue"></div>
        </div>
      }
    </section>
  </section>