import { Component, OnInit } from '@angular/core';
import { fadeIn, slideInOut } from 'src/app/animations';
import { TimeUnit } from '../../models/timeUnit.models';
import { getTimeRemaining } from '../../helpers/countdown.helper';

@Component({
  animations: [fadeIn, slideInOut],
  selector: 'app-countdown-timer',
  templateUrl: './countdown-timer.component.html',
})
export class CountdownTimerComponent implements OnInit {
  countDownDate = new Date(Date.UTC(2021, 10, 15, 5, 0, 0));
  countDownUTCTime = this.countDownDate.getTime() + this.countDownDate.getTimezoneOffset();
  result = '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  interval: any = 0;
  results: TimeUnit = {};

  ngOnInit(): void {
    this.interval = setInterval(this.update.bind(this), 1000);
  }

  update(): void {
    this.results = getTimeRemaining(this.countDownUTCTime);
    this.result = this.results.Result;
  }
}

