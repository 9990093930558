import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringToCapitalized'
})
export class StringToCapitalizedPipe implements PipeTransform {
  /** Take a string of characters and convert to capitalized
   * @param text string to be converted
   * @return string with capitalization only on the first character of each word
  */
  transform(text: string | null | undefined): string {
    if (!text) {
      return '';
    } else {
      const lower = text.toLowerCase();
      const frags = lower.split(' ');
      let i;
      for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
      return frags.join(' ');
    }
  }
}
