import { AfterViewInit, Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { sortBy, uniqBy } from 'lodash-es';
import { IStepOption, TourService } from 'ngx-ui-tour-md-menu';
import { forkJoin, Subscription } from 'rxjs';
import { catchError, debounceTime } from 'rxjs/operators';
import { fadeIn } from 'src/app/animations';
import { AmplitudeEventService } from 'src/app/core/services/amplitude/amplitude-event.service';
import { EAppService } from 'src/app/core/services/e-app.service';
import { ProductsApiService } from 'src/app/core/services/http/products-api.service';
import { ToastClassEnum } from 'src/app/core/services/snackbar/snackbar.models';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { SsoService } from 'src/app/core/services/sso.service';
import { SessionStoreService } from 'src/app/core/services/stores/session-store.service';
import { SubmitPaperApplicationV2Component } from 'src/app/portal/modals/submit-paper-application-v2/submit-paper-application-v2.component';
import { policyLobs } from 'src/app/shared/constants/policyList.constants';
import { statesAndTerritories } from 'src/app/shared/constants/states.constants';
import { StartEAppTour } from 'src/app/shared/constants/ui-tour.constants';
import { ManageIntegrationsEnum } from 'src/app/shared/enums/integrations.enum';
import { LineOfBusinessId } from 'src/app/shared/enums/line-business-id.enum';
import { VendorId } from 'src/app/shared/enums/vendor-id.enum';
import { AmplitudeStartEAppFunnelModel, AmplitudeStartEAppFormModel } from 'src/app/shared/models/amplitude.models';
import { Contact } from 'src/app/shared/models/contact.models';
import { ExternalVendorCarrier, ExternalVendorProductState, ExternalVendorProductV2 } from 'src/app/shared/models/eapps.models';
import { CreateActivityBasicResponse } from 'src/app/shared/models/firelightApi.models';
import { StateBasicModel } from 'src/app/shared/models/generic.models';
import { PaperApplicationMatDialogData } from 'src/app/shared/models/paper-application.model';
import { PolicyLob } from 'src/app/shared/models/policy.models';
import { EAppPreferenceEnum } from 'src/app/shared/models/user-settings.model';
import { ValidateObjectType } from 'src/app/shared/validators/validators';
import { environment } from 'src/environments/environment';

@Component({
  animations: [fadeIn],
  selector: 'app-start-application-form',
  templateUrl: './start-application-form.component.html',
})

export class StartApplicationFormComponent implements OnInit, AfterViewInit, OnDestroy {
  isLoading = false;
  isSubmitting = false;
  queryActivityId: string | null = null;
  queryCusip: string | null = null;
  queryLobId: string | null = null;
  queryState: string | null = null;
  isNewApplication = true;
  errorMessage = '';
  /** All carriers mapped from the permitted product list */
  private permittedCarriers: ExternalVendorCarrier[] = [];
  /** Carrier list used in the autocomplete dropdown */
  autocompleteCarriers: ExternalVendorCarrier[] = [];
  /** All permitted products from api call */
  private permittedProducts: ExternalVendorProductV2[] = [];
  /** Product list used in the autocomplete dropdown */
  autocompleteProducts: ExternalVendorProductV2[] = [];
  /** State list used in the autocomplete dropdown */
  autocompleteStates: StateBasicModel[] = statesAndTerritories;
  vendorIdEnum = VendorId;
  lineOfBusinessIdEnum = LineOfBusinessId;
  /** LOB options used in radio select */
  linesOfBusiness = policyLobs;
  formContactValidators = [Validators.required, ValidateObjectType<Contact>('FirstName'), ValidateObjectType<Contact>('LastName')];
  formManualClientNameValidators = [Validators.required];
  /**
   * UI form group
   * `clientName` and `contact` are not required except if you're an iComply user
   * They should also never be shown together. `contact` may not be required, but if user starts typing then we need to enforce `ValidateObjectType` due to the nature of autocmplete taking in both string & obj
   * */
  startEAppForm = this.fb.group({
    clientName: this.fb.group({
      firstName: this.fb.control<string | null>(null, this.formManualClientNameValidators),
      lastName: this.fb.control<string | null>(null, this.formManualClientNameValidators),
    }),
    contact: this.fb.control<Contact | string | null>(null, this.formContactValidators),
    lob: this.fb.control<PolicyLob | null>(null, [Validators.required]),
    state: this.fb.control<StateBasicModel | string | null>(null, [Validators.required, ValidateObjectType<StateBasicModel>('LongName')]),
    carrier: this.fb.control<ExternalVendorCarrier | string | null>(null, [Validators.required, ValidateObjectType<ExternalVendorCarrier>('Business')]),
    product: this.fb.control<ExternalVendorProductV2 | string | null>(null, [Validators.required, ValidateObjectType<ExternalVendorProductV2>('Product')]),
  });
  formValueChangeSub = new Subscription();
  lobValueChangeSub = new Subscription();
  stateValueChangeSub = new Subscription();
  carrierValueChangeSub = new Subscription();
  /**
   * W/e `this.startEAppForm.controls.product` changes value we re-apply the filter on the `autocompleteProductList`
   */
  productValueChangeSub = new Subscription();
  contactValueChangeSub = new Subscription();
  eAppPreferene = this.getUserEAppPreference();
  oneAmericaCarrierId = 51;
  isRedtailUser = this.sessionStore.User.IsRedtailUser;
  isIComplyUser = this.sessionStore.IsIcomplyUser;
  snackbardSuccessMessage = 'Application launched successfully in a new tab.';
  snackbardErrorMessage = 'Sorry, an error has occured. Please try again or contact our staff if the issue persists.';
  integrations: ManageIntegrationsEnum[] = [];
  /** Used for Amplitude funnel tracking */
  endWorkflowSuccess = false;
  useManualInput = false;
  /** Used to determine which type of application based on the selected product's Vendor ID */
  selectedProductType?: ExternalVendorProductTypeEnum;
  externalVendorProductType = ExternalVendorProductTypeEnum;
  connectedCrms: ManageIntegrationsEnum[] = [];

  constructor(
    private fb: FormBuilder,
    private productsApiService: ProductsApiService,
    private activatedRoute: ActivatedRoute,
    private ssoService: SsoService,
    private eAppService: EAppService,
    private sessionStore: SessionStoreService,
    private dialog: MatDialog,
    private snackbar: SnackbarService,
    private dialogRef: MatDialogRef<StartApplicationFormComponent>,
    /** Determine when component is called as a modal view from the main portal header */
    @Optional() @Inject(MAT_DIALOG_DATA) public data: { isModalView?: boolean, startTour?: boolean } | null,
    private amplitudeEventService: AmplitudeEventService,
    private tourService: TourService,
  ) { }

  ngOnInit(): void {
    // Log 1
    this.amplitudeEventService.logEAppFunnel('Start Workflow', this.mapAmplitudeTrackingModel(this.startEAppForm.value as AmplitudeStartEAppFormModel));
    this.getQueryParams();
    this.getPermittedProducts();
    this.startTour();
    this.dialogRef.afterClosed().subscribe({
      next: () => {
        if (!this.endWorkflowSuccess) {
          this.amplitudeEventService.logEAppFunnel('Abandon Workflow', this.mapAmplitudeTrackingModel(this.startEAppForm.value as AmplitudeStartEAppFormModel));
        }
      }
    });
  }

  ngAfterViewInit(): void {
    const width = '62rem';
    const height = '70rem';
    this.dialogRef.updateSize(width, height);
  }

  ngOnDestroy(): void {
    this.contactValueChangeSub.unsubscribe();
    this.lobValueChangeSub.unsubscribe();
    this.stateValueChangeSub.unsubscribe();
    this.carrierValueChangeSub.unsubscribe();
    this.productValueChangeSub.unsubscribe();
  }

  startTour(): void {
    if (this.data.startTour) {
      const tourOptions: IStepOption = {
        popoverClass: 'portal-tour',
        isAsync: true,
        delayAfterNavigation: 150,
        delayBeforeStepShow: 50,
        backdropConfig: {
          offset: 10,
        },
      };

      this.tourService.initialize(StartEAppTour, tourOptions);
      this.tourService.start();
    }
  }

  /**
   * Callback from integrations-menu component to update the user input fields
   * @param integrations list of connected CRMs
   */
  updateIntegrations(integrations: ManageIntegrationsEnum[]): void {
    this.integrations = integrations;
    this.connectedCrms = integrations.filter(i => i !== ManageIntegrationsEnum.Envestnet); // Ensure we don't pass back Envestnet as a connected CRM
    this.updateInputFields();
  }

  /**
   * Get display `string` value for state name used in the UI autocomplete
   * @param value actual state model in form control
   * @returns display name for state
   */
  getState(value?: StateBasicModel): string {
    return value?.LongName || '';
  }

  /**
   * Get display `string` value for carrier name used in the UI autocomplete
   * @param value actual carrier model in form control
   * @returns display name for carrier
   */
  getCarrier(value?: ExternalVendorCarrier): string {
    return value?.Business || '';
  }

  /**
   * Get display `string` value for product name used in the UI autocomplete
   * @param value actual product model in form control
   * @returns display name for product
   */
  getProduct(value?: ExternalVendorProductV2): string {
    return value?.Product || '';
  }

  /**
   * Get list of permitted products & check IComply user
   * Then filter LOB & carrier list based on what's available from the product list
   * Then map LOB & carrier list to be selected on UI
   */
  getPermittedProducts(): void {
    this.isLoading = true;
    forkJoin({
      products: this.productsApiService.getPermittedProductsV2().pipe(catchError(error => {
        throw error;
      })),
    }).subscribe({
      next: res => {
        this.permittedProducts = res.products;
        this.autocompleteProducts = res.products;
        this.getPermittedCarriers(res.products);
        this.updateInputFields();
        this.filterLob();
        this.autocompleteCarriers = this.filterCarrierList(this.startEAppForm.value.lob?.Id);
        this.filterProductList(this.startEAppForm.value.lob, this.startEAppForm.value.state, this.startEAppForm.value.carrier);
      },
      error: () => {
        this.snackbar.openSnackbar(this.snackbardErrorMessage, ToastClassEnum.warning);
      },
      complete: () => {
        if (this.queryActivityId) this.mapExistingApplication();
        this.checkFormValueChange();
      }
    }).add(() => {
      this.isLoading = false;
    });
  }

  /**
   * Get full list of permitted carriers for filtering later
   * @param permittedProducts All permitted products from API call to be mapped
   */
  getPermittedCarriers(permittedProducts: ExternalVendorProductV2[]): void {
    const carriers = permittedProducts.map(product => {
      return {
        Business: product.Business,
        CarrierCode: product.CarrierCode,
        CarrierID: product.CarrierID,
      };
    });
    this.permittedCarriers = sortBy(uniqBy(carriers, 'Business'), 'Business');
  }

  /**
   * Get query params for applications sarting from other locations in Portal
   */
  getQueryParams(): void {
    this.queryActivityId = this.activatedRoute.snapshot.queryParamMap.get('activityId');
    this.queryState = this.activatedRoute.snapshot.queryParamMap.get('state');
    this.queryCusip = this.activatedRoute.snapshot.queryParamMap.get('cusip');
    this.queryLobId = this.activatedRoute.snapshot.queryParamMap.get('lobId');
    this.isNewApplication = this.activatedRoute.snapshot.queryParamMap.get('isNew') === 'false' ? false : true;
  }

  /**
   * Check for form control value changes to re-apply filtering of products/carriers/states, etc, as well as trigger autocomplete filter values when users search
   * TODO: This is super finnicky. Will need some refactor to avoid future bugs
   */
  checkFormValueChange(): void {
    this.formValueChangeSub = this.startEAppForm.valueChanges.subscribe({
      next: value => {
        if (!this.startEAppForm.pristine && this.startEAppForm.valid) {
          this.amplitudeEventService.logEAppFunnel('Complete Form', this.mapAmplitudeTrackingModel(value as AmplitudeStartEAppFormModel));
        }
      }
    });
    this.contactValueChangeSub = this.startEAppForm.controls.contact.valueChanges.subscribe({
      next: value => {
        // `form.ClientName` may not be required, but if user starts typing then we need to enforce `ValidateObjectType` due to the nature of autocmplete taking in both string & obj
        if (!this.useManualInput) {
          if (value) {
            this.enableSearchInputs(true, true, false);
          } else {
            this.enableSearchInputs(true, false, false);
          }
          if (value && typeof value !== 'string') {
            this.mapContact(value);
          }
        }
      }
    });
    this.lobValueChangeSub = this.startEAppForm.controls.lob.valueChanges.subscribe({
      next: value => {
        if (value) {
          this.autocompleteCarriers = this.filterCarrierList(value.Id);
          this.filterProductList(value, this.startEAppForm.value.state, this.startEAppForm.value.carrier);
        }
      }
    });
    this.stateValueChangeSub = this.startEAppForm.controls.state.valueChanges.subscribe({
      next: value => {
        this.filterProductList(this.startEAppForm.value.lob, value, this.startEAppForm.value.carrier);
        this.filterAutocompleteStates(value);
      }
    });

    this.carrierValueChangeSub = this.startEAppForm.controls.carrier.valueChanges.subscribe({
      next: value => {
        this.filterProductList(this.startEAppForm.value.lob, this.startEAppForm.value.state, value);
        this.filterAutocompleteCarriers(value);
      }
    });

    this.productValueChangeSub = this.startEAppForm.controls.product.valueChanges.pipe(
      debounceTime(100)
    ).subscribe({
      next: value => {
        this.filterProductList(this.startEAppForm.value.lob, this.startEAppForm.value.state, this.startEAppForm.value.carrier);
        this.filterAutocompleteProductList(value);
        this.selectedProductType = this.checkProductType(this.startEAppForm.value.product);
        if (value && typeof value !== 'string') {
          const carrier: ExternalVendorCarrier = {
            Business: value?.Business,
            CarrierID: value?.CarrierID,
            CarrierCode: value?.CarrierCode,
          };
          this.startEAppForm.controls.carrier.patchValue(carrier, { emitEvent: false });
          this.filterAutocompleteCarriers(carrier);
        }
      }
    });

    this.startEAppForm.updateValueAndValidity();
  }

  /**
   * Update form value if user search for an existing Redtail contact
   * @param contact map to an active Redtail contact
   */
  mapContact(contact: Contact | null): void {
    if (!contact) return;
    if (!contact.FirstName) contact.FirstName = 'TBD';
    if (!contact.LastName) contact.LastName = 'TBD';
  }

  /**
   * Map lob, state, carrier & product from cusip & state query params
   * Set values to form
   */
  mapExistingApplication(): void {
    if (this.queryCusip && this.queryState) {
      const product = this.permittedProducts.find(product => {
        return product.ProductCusip === this.queryCusip;
      });
      if (product) {
        const lob = policyLobs.find(lob => lob.Id === product.LineOfBusinessID);
        // TODO: Originally, this logic used CarrierCode, but that caused some issues with Allianz & Allianz Preferred, which have the same code "ALZ" but diff. IDs.
        // So setting preferred method to ID & default to Code if not found
        const carrier = this.permittedCarriers.find(carrier => carrier.CarrierID === product.CarrierID) || this.permittedCarriers.find(carrier => carrier.CarrierCode === product.CarrierCode);
        const state = statesAndTerritories.find(state => state.ShortName.toLowerCase() === this.queryState?.toLowerCase());

        this.startEAppForm.controls.lob.patchValue(lob || null);
        this.startEAppForm.controls.carrier.patchValue(carrier || null);
        this.startEAppForm.controls.product.patchValue(product);
        this.startEAppForm.controls.state.patchValue(state || null);
      }
    }
  }

  /**
   * Check if user is and IComply user & if is logged into RedTail/Wealthbox, then update the name input's controls accordingly
   * Ex: `this.startEAppForm.controls.clientName.controls.firstName.clearValidators();`
   */
  updateInputFields(): void {
    if (this.integrations.length > 0) {
      this.enableSearchInputs(true, this.isIComplyUser?.IsActive , true);
      this.enableClientNameInputs(false, false, true);
    } else {
      this.useManualInput = true;
      this.enableSearchInputs(false, false, true);
      this.enableClientNameInputs(true, this.isIComplyUser?.IsActive , true);
    }
  }

  /**
   * Toggle Redtail form controls & validation on/off.
   * `form.controls.contact` may not be required, but if user starts typing then we need to enforce `ValidateObjectType` due to the nature of autocmplete taking in both string & obj
   * @param enabled on if `true`, off if `false`
   * @param addValidators add the appropriate validators to the fields
   * @param emitEvent allow valueChange subscriber to pick up this event. We set it to false if we want to manipulate the form value during a valueChange cycle to avoid enedless loop
   */
  enableSearchInputs(enabled: boolean, addValidators: boolean | undefined, emitEvent: boolean): void {
    const opts = {
      emitEvent: emitEvent
    };
    if (enabled) {
      this.startEAppForm.controls.contact.enable(opts);
      if (addValidators) {
        this.startEAppForm.controls.contact.addValidators(this.formContactValidators);
      } else {
        this.startEAppForm.controls.contact.clearValidators();
      }
    } else {
      this.startEAppForm.controls.contact.clearValidators();
      this.startEAppForm.controls.contact.disable(opts);
    }
    this.startEAppForm.controls.contact.updateValueAndValidity(opts);
  }

  /**
   * Toggle first & last name form controls & validation on/off
   * @param enabled on if `true`, off if `false`
   * @param addValidators add the appropriate validators to the fields
   * @param emitEvent allow valueChange subscriber to pick up this event. We set it to false if we want to manipulate the form value during a valueChange cycle to avoid enedless loop
   */
  enableClientNameInputs(enabled: boolean, addValidators: boolean | undefined, emitEvent: boolean): void {
    const opts = {
      emitEvent: emitEvent
    };
    if (enabled) {
      this.startEAppForm.controls.clientName.controls.firstName.enable(opts);
      this.startEAppForm.controls.clientName.controls.lastName.enable(opts);
      if (addValidators) {
        this.startEAppForm.controls.clientName.controls.firstName.addValidators(this.formManualClientNameValidators);
        this.startEAppForm.controls.clientName.controls.lastName.addValidators(this.formManualClientNameValidators);
      } else {
        this.startEAppForm.controls.clientName.controls.firstName.clearValidators();
        this.startEAppForm.controls.clientName.controls.lastName.clearValidators();
      }
    } else {
      this.startEAppForm.controls.clientName.controls.firstName.clearValidators();
      this.startEAppForm.controls.clientName.controls.firstName.disable(opts);

      this.startEAppForm.controls.clientName.controls.lastName.clearValidators();
      this.startEAppForm.controls.clientName.controls.lastName.disable(opts);
    }
    this.startEAppForm.controls.clientName.controls.firstName.updateValueAndValidity(opts);
    this.startEAppForm.controls.clientName.controls.lastName.updateValueAndValidity(opts);
  }

  /**
   * Filter LOB radio buttons
   * Also set the default form control value `this.startEAppForm.controls.lob` in the UI if there are available LOBs
   */
  filterLob(): void {
    const result: PolicyLob[] = [];
    policyLobs.forEach(lob => {
      const match = this.permittedProducts.find(product => product.LineOfBusinessID === lob.Id);
      if (match) result.push(lob);
    });
    this.linesOfBusiness = sortBy(result, 'Name');
    if (this.linesOfBusiness.length)
      this.startEAppForm.controls.lob.patchValue(this.linesOfBusiness[0]);
  }

  /**
   * Filter the state option list based on the input value
   * @param value can be `string` when searching manually, or `StateBasicModel` when selected, or `null` when form reset
   */
  filterAutocompleteStates(value: string | StateBasicModel | null): void {
    this.autocompleteStates = statesAndTerritories.filter(state => {
      if (!value) return statesAndTerritories;
      if (typeof value === 'string')
        return state.LongName.toLowerCase().includes(value.toLowerCase()) || state.ShortName.toLowerCase().includes(value.toLowerCase());
      else
        return state;
    });
  }

  /**
   * Filter the carrier option list based on the input value
   * @param value can be `string` when searching manually, or `ExternalVendorCarrier` when selected, or `null` when form reset
   */
  filterAutocompleteCarriers(value: string | ExternalVendorCarrier | null): void {
    const carriers = this.filterCarrierList(this.startEAppForm.value.lob?.Id, value);

    this.autocompleteCarriers = carriers.filter(carrier => {
      if (!value)
        return true;
      if (typeof value === 'string')
        return carrier.Business.toLowerCase().includes(value.toLowerCase());
      else
        return true;
    });
  }

  /**
   * Filter autocomplete carrier list based on the selected LOB option
   * Also clear out carrier and/or product inputs if the selected values are not found in the new carrier list
   * @param selectedLobId If null, return all mapped carrier from permitted product list
   * @param newCarrierValue New carrier value emitted from input, if null then use current form value
   */
  filterCarrierList(selectedLobId: number | null | undefined, newCarrierValue?: ExternalVendorCarrier | string | null): ExternalVendorCarrier[] {
    let filteredCarriers: ExternalVendorCarrier[] = [];
    let filteredProducts: ExternalVendorProductV2[] = [];
    newCarrierValue = newCarrierValue || this.startEAppForm.value.carrier;

    if (selectedLobId) {
      filteredProducts = this.permittedProducts.filter(product => product.LineOfBusinessID === selectedLobId);
    } else {
      filteredProducts = this.permittedProducts;
    }
    filteredCarriers = filteredProducts.map(product => {
      return {
        Business: product.Business,
        CarrierCode: product.CarrierCode,
        CarrierID: product.CarrierID,
      };
    });

    filteredCarriers = sortBy(uniqBy(filteredCarriers, 'Business'), 'Business');

    // When filtered carrier list is refreshed, clear the current autocomplete carrier value if:
    // there's an existing value & it's not a string & it doesn't exist in the new filtered carrier list
    if (newCarrierValue && typeof newCarrierValue !== 'string') {
      const carrierValue = newCarrierValue;
      const includesCarrier = filteredCarriers.find(x => x.Business === carrierValue.Business);
      if (!includesCarrier) {
        this.startEAppForm.controls.carrier.reset(null, {
          emitEvent: false
        });
      }
    }

    // When filtered carrier list is refreshed, clear the current autocomplete product value if:
    // there's an existing value & it's not a string & it doesn't exist in the new filtered carrier list
    if (this.startEAppForm.value.product && typeof this.startEAppForm.value.product !== 'string')
      if (!filteredProducts.includes(this.startEAppForm.value.product)) {
        this.startEAppForm.controls.product.reset();
      }


    return filteredCarriers;
  }

  /**
   * Filter the product option list based on the input value
   * @param value can be `string` when searching manually, or `ExternalVendorCarrier` when selected, or `null` when form reset
   */
  filterAutocompleteProductList(value: string | ExternalVendorProductV2 | null): void {
    this.autocompleteProducts = this.autocompleteProducts.filter(product => {
      if (!value) return true;
      if (typeof value === 'string') {
        return product.Product.toLowerCase().includes(value.toLowerCase());
      } else
        return true;
    });
  }

  /**
   * Filter from all permitted products to those matching selected LOB, state & carrier. If user changes either LOB, state or carrier and the selected product is no longer available, clear the product form input
   * @param selectedLob LOB value from form control
   * @param selectedState state value from form control
   * @param selectedCarrier carrier value from form control
   */
  filterProductList(selectedLob: PolicyLob | null | undefined, selectedState: StateBasicModel | string | null | undefined, selectedCarrier: ExternalVendorCarrier | string | null | undefined): void {
    if (typeof selectedState === 'string' && typeof selectedCarrier === 'string') return;
    let filteredProducts: ExternalVendorProductV2[] = [];
    const matchState = (productNotAvailableStates: ExternalVendorProductState[], selectedState?: StateBasicModel | string | null): boolean => {
      if (!selectedState || typeof selectedState === 'string') return true;
      return productNotAvailableStates.length === 0 ? true : !productNotAvailableStates.map(x => x.StateAbbrev.toLowerCase()).includes(selectedState.ShortName.toLowerCase());
    };
    /**
     * Helper to filter product list below
     * @param productCarrier carrier belonging to the filtered product
     * @param selectedCarrier carrier value from form control
     * @returns `boolean`
     */
    const matchCarrier = (productCarrier: string, selectedCarrier?: ExternalVendorCarrier | string | null): boolean => {
      if (!selectedCarrier || typeof selectedCarrier === 'string') return true;
      return productCarrier.toLowerCase() === selectedCarrier.Business.toLowerCase();
    };

    /**
    * Helper to filter product list below
    * @param productLobId lob ID belonging to the filtered product
    * @param selectedLobId lob ID value from form control
    * @returns `boolean`
    */
    const matchLobId = (productLobId: number, selectedLobId?: number): boolean => {
      if (!selectedLobId) return true;
      return productLobId === selectedLobId;
    };

    filteredProducts = this.permittedProducts.filter(p => matchCarrier(p.Business, selectedCarrier) && matchLobId(p.LineOfBusinessID, selectedLob?.Id) && matchState(p.NotAvailableStates, selectedState));

    this.autocompleteProducts = sortBy(filteredProducts, 'Product');

    if (this.startEAppForm.value.product && typeof this.startEAppForm.value.product !== 'string' && !filteredProducts.includes(this.startEAppForm.value.product)) {
      this.startEAppForm.controls.product.reset();
    }
  }

  /**
   * Check if product is supported on different e-app platform
   * If nothing matches, default to paper application
   */
  submit(): void {
    this.isSubmitting = true;
    this.amplitudeEventService.logEAppFunnel('Submit Application', this.mapAmplitudeTrackingModel(this.startEAppForm.value as AmplitudeStartEAppFormModel));
    if (this.startEAppForm.invalid) {
      this.startEAppForm.markAllAsTouched();
      this.isSubmitting = false;
      return;
    }
    const productType = this.checkProductType(this.startEAppForm.value.product);

    switch (productType) {
      case undefined:
        break;
      case ExternalVendorProductTypeEnum.firelight:
        this.submitFirelightEApplication();
        break;
      case ExternalVendorProductTypeEnum.iPipeline:
        this.submitIPipelineEApplication();
        break;
      case ExternalVendorProductTypeEnum.aspida:
        this.ssoToAspida();
        break;
      case ExternalVendorProductTypeEnum.equiTrust:
        this.linkToEquitrustApp();
        break;
      default:
        this.openPaperApplication();
        break;
    }

  }

  checkProductType(product: ExternalVendorProductV2 | string | null): ExternalVendorProductTypeEnum | undefined {
    if (typeof product === 'string' || !product) {
      return undefined;
    } else {
      if (this.isFirelightProduct(product)) {
        return ExternalVendorProductTypeEnum.firelight;
      }
      if (this.isIPipelineProduct(product)) {
        return ExternalVendorProductTypeEnum.iPipeline;
      }
      if (this.isAspidaCarrierSelected(product)) {
        return ExternalVendorProductTypeEnum.aspida;
      }
      return ExternalVendorProductTypeEnum.paperApp;
    }
  }

  /**
   * If product has `ExternalVendorID: 1` & `ProductCusip`
   * @returns boolean
   */
  isFirelightProduct(product: ExternalVendorProductV2 | string): boolean {
    if (typeof product === 'string') return false;
    return (product?.ExternalVendorID === this.vendorIdEnum.FireLight) && (!!product?.ProductCusip);
  }

  /**
   * If product has `ExternalVendorID: 4` & `ProductCusip`
   * @returns boolean
   */
  isIPipelineProduct(product: ExternalVendorProductV2 | string): boolean {
    if (typeof product === 'string') return false;
    return (product?.ExternalVendorID === this.vendorIdEnum.IPipeline) && (!!product?.ProductCusip) && (!!product?.ExternalVendorProductTypeID);
  }

  /**
   * If product has `ExternalVendorID: 5`
   * @returns boolean
   */
  isAspidaCarrierSelected(product: ExternalVendorProductV2 | string): boolean {
    if (typeof product === 'string') return false;
    return product?.ExternalVendorID === this.vendorIdEnum.Aspida;
  }

  /**
   * If product has `ExternalVendorID: 1` & `ProductCusip`
   * @returns boolean
   */
  isEquitrustLTCProductSelected(product: ExternalVendorProductV2 | string): boolean {
    if (typeof product === 'string') return false;
    //Equitrust LTC products route to EQ agent login page
    return product?.CarrierID === this.oneAmericaCarrierId && product?.LineOfBusinessID === this.lineOfBusinessIdEnum.LongTermCare;
  }

  ssoToAspida(): void {
    this.ssoService.aspidaSso();
    this.amplitudeEventService.logEAppFunnel('End Workflow Success', this.mapAmplitudeTrackingModel(this.startEAppForm.value as AmplitudeStartEAppFormModel));
    this.isSubmitting = false;
    this.dialogRef.close(true);
  }

  linkToEquitrustApp(): void {
    window.open(environment.equitrustAppLink, '_blank');
    this.amplitudeEventService.logEAppFunnel('End Workflow Success', this.mapAmplitudeTrackingModel(this.startEAppForm.value as AmplitudeStartEAppFormModel));
    this.isSubmitting = false;
    this.dialogRef.close(true);
  }

  submitIPipelineEApplication(): void {
    const form = this.startEAppForm.value;
    if (typeof form.product === 'string' || typeof form.state === 'string') return;
    if (form.state && form.product?.ProductCusip && form.product.ExternalVendorProductTypeID)
      // Amplitude funnel tracking is handled within the service method
      this.eAppService.startiPipelineEApplication(form.state.ShortName, form.product.ProductCusip, form.product.ExternalVendorProductTypeID.toString(), this.mapAmplitudeTrackingModel(form as AmplitudeStartEAppFormModel), form.contact as Contact);
    this.isSubmitting = false;
  }

  /**
   * Get user's e-application preference. If user hasn't set before, set it to Firelight SSO as we're trying to wean off of Embedded view
   * @returns user's e-app preference
   */
  getUserEAppPreference(): EAppPreferenceEnum {
    let result = this.sessionStore.UserSettings?.EAppPreference;
    if (result === undefined || result === EAppPreferenceEnum.None) {
      result = EAppPreferenceEnum.FirelightDirect;
      this.sessionStore.updateEAppPreference(EAppPreferenceEnum.FirelightDirect);
    }
    return result;
  }

  submitFirelightEApplication(): void {
    if (typeof this.startEAppForm.value.contact === 'string' || !this.startEAppForm.value.product || !this.startEAppForm.value.state || typeof this.startEAppForm.value.state === 'string' || typeof this.startEAppForm.value.product === 'string') return;

    this.eAppService.startFirelightEApplication(this.startEAppForm.value.product, this.startEAppForm.value.state, this.startEAppForm.value.contact, this.startEAppForm.value.clientName?.firstName, this.startEAppForm.value.clientName?.lastName)
      .subscribe({
        next: (activity: CreateActivityBasicResponse) => {
          this.eAppService.goToFirelight(activity.ActivityId, { isNew: true });
          this.endWorkflowSuccess = true;
          this.amplitudeEventService.logEAppFunnel('End Workflow Success', this.mapAmplitudeTrackingModel(this.startEAppForm.value as AmplitudeStartEAppFormModel));
          this.endWorkflowSuccess = true;
          this.dialogRef.close(this.endWorkflowSuccess);
          this.snackbar.openSnackbar(this.snackbardSuccessMessage, ToastClassEnum.success);
        },
        error: () => {
          this.snackbar.openSnackbar(this.snackbardErrorMessage, ToastClassEnum.warning);
        }
      }).add(() => {
        this.isSubmitting = false;
      });
  }

  openPaperApplication(): void {
    const dialog = this.dialog.open<SubmitPaperApplicationV2Component, PaperApplicationMatDialogData, boolean>(SubmitPaperApplicationV2Component, {
      data: {
        fromWorkflow: true,
      }
    });
    this.isSubmitting = false;
    dialog.afterClosed().subscribe({
      next: res => {
        this.endWorkflowSuccess = res;
        if (res) {
          this.amplitudeEventService.logEAppFunnel('End Workflow Success', this.mapAmplitudeTrackingModel(this.startEAppForm.value as AmplitudeStartEAppFormModel));
        }
      }
    });
  }

  /**
   * If user decides to go for manual input of client's first name & last name, update form control accordingly
   * @param useManualInput boolean event emitted from `redtail-search-form.component`
   */
  switchToManualClientInput(useManualInput: boolean): void {
    if (useManualInput) {
      this.enableSearchInputs(false, this.isIComplyUser?.IsActive , false);
      this.enableClientNameInputs(true, this.isIComplyUser?.IsActive , true);
    } else {
      this.enableSearchInputs(true, this.isIComplyUser?.IsActive , false);
      this.enableClientNameInputs(false, this.isIComplyUser?.IsActive , true);
    }
    this.useManualInput = useManualInput;
  }

  /**
   * Used exclusively for tracking user drop-off during submitting e-applications
   * @param formModel Extrapolated form model from the modal
   * @returns Flattended Amplitude model
   */
  mapAmplitudeTrackingModel(formModel: AmplitudeStartEAppFormModel): AmplitudeStartEAppFunnelModel {
    let contactType = 'undefined';
    const productType = this.checkProductType(this.startEAppForm.value.product) || 'undefined';

    if (formModel.contact && typeof formModel.contact !== 'string') {
      contactType = formModel.contact?.Crm || 'undefined';
    } else if (formModel.clientName?.firstName && formModel.clientName?.lastName) {
      contactType = 'Manual Contact';
    } else {
      contactType = 'undefined';
    }

    const result: AmplitudeStartEAppFunnelModel = {
      carrier: formModel?.carrier && typeof formModel?.carrier !== 'string' ? formModel?.carrier?.Business : 'undefined',
      contact_type: contactType,
      lob: formModel?.lob?.Name || 'undefined',
      product: formModel?.product && typeof formModel?.product !== 'string' ? formModel?.product.Product : 'undefined',
      product_type: productType || 'undefined',
      state: formModel.state && typeof formModel?.state !== 'string' ? formModel?.state?.LongName : 'undefined',
    };
    console.log(result.product_type);
    return result;
  }
}

export enum ExternalVendorProductTypeEnum {
  firelight = 'Firelight',
  iPipeline = 'iPipeline',
  aspida = 'Aspida',
  equiTrust = 'EquiTrust LTC',
  paperApp = 'Paper App Product',
}