export interface UserSettingsModel {
  Id: string,
  UserId: string,
  EAppPreference:EAppPreferenceEnum
}

export enum EAppPreferenceEnum {
  None = 'None',
  FirelightEmbedded = 'FirelightEmbedded',
  FirelightDirect = 'FirelightDirect'
}