import { InformalPolicyTable, PolicyLob, PolicyTable, PolicyTableHeader } from '../models/policy.models';

// TODO: Remove when My-Buisness is removed
export const policyListTableHeaders: PolicyTableHeader[] = [
  {
    DisplayName: 'Agent',
    ColumnName: ['FirstName', 'LastName'],
    WidthClass: 'min-w-128',
    ReportTypes: ['Inforce', 'Lifecycle', 'PendingV2', 'Pending'],
  },
  {
    DisplayName: 'Clients',
    ColumnName: ['Clients[0]', 'Clients[1]', 'Clients[2]'],
    WidthClass: 'min-w-128',
    ReportTypes: ['Pending', 'Inforce', 'Lifecycle', 'PendingV2'],
  },
  {
    DisplayName: 'Carrier',
    ColumnName: ['Carrier'],
    WidthClass: '',
    ReportTypes: ['PendingV2', 'Lifecycle'],
  },
  {
    DisplayName: 'Agent(S)',
    ColumnName: ['Agents'],
    WidthClass: '',
    ReportTypes: ['Informal'],
  },
  {
    DisplayName: 'Related People',
    ColumnName: ['RelatedPeople'],
    WidthClass: '',
    ReportTypes: ['Informal'],
  },
  {
    DisplayName: 'Policy',
    ColumnName: ['Carrier', 'Product'],
    WidthClass: '',
    ReportTypes: ['Pending', 'Inforce', 'Lifecycle', 'PendingV2'],
  },
  {
    DisplayName: 'Premium',
    ColumnName: ['SinglePremium'],
    WidthClass: '',
    ReportTypes: ['Pending', 'Inforce', 'Lifecycle', 'PendingV2'],
  },
  {
    DisplayName: 'Face Amount',
    ColumnName: ['FaceAmount'],
    WidthClass: '',
    ReportTypes: ['Pending', 'Inforce', 'Lifecycle', 'Informal'],
  },
  {
    DisplayName: 'Target Premium',
    ColumnName: ['Target'],
    WidthClass: '',
    ReportTypes: ['Pending', 'Inforce', 'Lifecycle'],
  },
  {
    DisplayName: 'Account Value',
    ColumnName: ['CurrentValue'],
    WidthClass: '',
    ReportTypes: ['Lifecycle'],
  },
  {
    DisplayName: 'Surrender Value',
    ColumnName: ['SurrenderValue'],
    WidthClass: '',
    ReportTypes: ['Lifecycle'],
  },
  {
    DisplayName: 'Ann. Premium',
    ColumnName: ['AnnualizedPremium'],
    WidthClass: '',
    ReportTypes: ['Pending', 'Inforce', 'Informal'],
  },
  {
    DisplayName: 'State',
    ColumnName: ['StateName'],
    WidthClass: '',
    ReportTypes: ['Informal'],
  },
  {
    DisplayName: 'Carriers',
    ColumnName: ['Carrier'],
    WidthClass: '',
    ReportTypes: ['Informal'],
  },
  {
    DisplayName: 'Inforce Date',
    ColumnName: ['InforceDate'],
    WidthClass: '',
    ReportTypes: ['Inforce', 'Lifecycle'],
  },
  {
    DisplayName: 'Surrender End Date',
    ColumnName: ['SurrenderEndDate'],
    WidthClass: '',
    ReportTypes: ['Lifecycle'],
  },
  {
    DisplayName: 'Commision',
    ColumnName: ['CommissionAmount'],
    WidthClass: '',
    ReportTypes: ['Inforce'],
  },
  {
    DisplayName: 'Latest Status',
    ColumnName: ['PolicyStatusDate'],
    WidthClass: 'min-w-256',
    ReportTypes: ['Pending', 'Inforce', 'Lifecycle', 'PendingV2'],
  }
];

// TODO: REMOVE when My-Buisness is removed
export const policyTable: PolicyTable = {
  Header: policyListTableHeaders,
  Body: []
};

// TODO: remove this when 'My-Buisness' is removed
export const informalPolicyTable: InformalPolicyTable = {
  Header: policyListTableHeaders.filter(header => header.ReportTypes.includes('Informal')),
  Body: []
};

export const policyLobs: PolicyLob[] = [
  { Id: 1, Name: 'Annuity', Selected: true },
  { Id: 4, Name: 'Life', Selected: false },
  { Id: 2, Name: 'Long-term Care', Selected: false },
  { Id: 3, Name: 'Disability', Selected: false },
];

export enum PolicyStageEnum {
  applicationSigned = 1,
  receivedAtFig = 2,
  submittedToCarrier = 3,
  pending = 4,
  approvedLifeLtc = 5,
  issued = 6,
  inforce = 7,
  cancelled = 8,
}

export const policyStages: number[] = [
  PolicyStageEnum.applicationSigned,
  PolicyStageEnum.receivedAtFig,
  PolicyStageEnum.submittedToCarrier,
  PolicyStageEnum.pending,
  PolicyStageEnum.approvedLifeLtc,
  PolicyStageEnum.issued,
  PolicyStageEnum.inforce,
  PolicyStageEnum.cancelled,
];