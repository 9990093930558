import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LifeVp } from 'src/app/shared/models/vp.models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserVpApiService {

  constructor(private http: HttpClient) { }

  getUserLifeVP(): Observable<LifeVp> {
    return this.http.get<LifeVp>('/api/AgentVp/LifeInsurance');
  }

  getSalesTeam(): Observable<string> {
    return this.http.get<string>('/api/AgentVp/SalesTeam');
  }
}
