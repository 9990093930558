import { IStepOption } from 'ngx-ui-tour-md-menu';
import { IMdStepOption } from 'ngx-ui-tour-md-menu/lib/step-option.interface';

export const StartEAppTour: IMdStepOption[] = [
  {
    title: 'CRM Connection Guide',
    anchorId: 'integrations1',
    content: 'Click here to find the available CRMs to connect to. You will be returned to this dialog afterward.',
    isAsync: true,
  }
];

export const LifecycleTour: IMdStepOption[] = [
  {
    title: 'View Select',
    anchorId: 'lifecycle1',
    content: 'Click here to toggle between different data views.',
    route: 'Portal/Business/Fixed/Lifecycle'
  },
  {
    title: 'Manage Views',
    anchorId: 'lifecycle2',
    content: 'Click here to update and/or create different data views. This is also where you can set your <strong>primary view</strong>.',
  },
  {
    title: 'Export',
    anchorId: 'lifecycle3',
    content: 'Click here to export your data or schedule a report to be emailed to you.',
  }
];


export const SampleTourStep = {
  step1: {
    title: 'Step 1',
    id: 'step1',
  },
  step2: {
    title: 'Step 2',
    id: 'step2',
  },
  step3: {
    title: 'Step 3',
    id: 'step3',
  },
  step4: {
    title: 'Step 4',
    id: 'step4',
  },
  step5: {
    title: 'Step 5',
    id: 'step5',
  }
};

export const SampleTour: IMdStepOption[] = [
  {
    anchorId: SampleTourStep.step1.id,
    content: 'This is a sample tour. You can click <strong>Next</strong> or press the <strong>Right Arrow</strong> key to proceed.',
    title: SampleTourStep.step1.title,
    route: 'Portal/AngularSandbox',
  },
  {
    anchorId: SampleTourStep.step2.id,
    content: 'This is the contracting page. You can insert <code>code markup as well.</code>',
    title: SampleTourStep.step2.title,
    route: 'Portal/Contracting',
  },
  {
    anchorId: SampleTourStep.step3.id,
    content: 'This is the aplication history page. Lorem ipsum doriamet.',
    title: SampleTourStep.step3.title,
    route: 'Portal/Business/EApplications/History',
  },
  {
    anchorId: SampleTourStep.step4.id,
    content: 'This is the pending business view. Lorem ipsum doriamet.',
    title: SampleTourStep.step4.title,
    route: 'Portal/Business/Fixed/Pending',
  },
  {
    anchorId: SampleTourStep.step5.id,
    content: 'This is the tools & calcs page. Lorem ipsum doriamet.',
    title: SampleTourStep.step5.title,
    route: 'Portal/Resources/ToolsCalculators',
  },
];

export const tourOptions: IStepOption = {
  isAsync: true,
  delayAfterNavigation: 150,
  delayBeforeStepShow: 50,
  enableBackdrop: true,
  scrollContainer: '.portal-body',
  backdropConfig: {
    offset: 10,
  },
  popoverClass: 'portal-tour',
};