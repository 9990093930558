import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { FeatureFlagApiService } from 'src/app/core/services/http/feature-flag-api.service';

// import { FeatureFlagService } from 'src/app/core/services/feature-flag.service'
// TODO: DO NOT USE since this requires a http request and may cause glitching while waitinf for flag result. Use service for now
@Directive({
  selector: '[appRemoveIfFeatureOff]'
})
export class RemoveIfFeatureOffDirective implements OnInit {
  @Input('appRemoveIfFeatureOff') featureName: string;

  constructor(private el: ElementRef<HTMLElement>, private featureFlagApiService: FeatureFlagApiService) {
    this.featureName = '';
  }

  ngOnInit(): void {

    this.featureFlagApiService.getFlag(this.featureName).subscribe(flag => {
      if (!flag.FlagEnabled) {
        this.el.nativeElement.parentNode?.removeChild(this.el.nativeElement);
      }
    });
  }
}