<section class="flex flex-col w-full h-full mx-auto gap-y-20">
  <app-message-notification class="w-full" *showIfOne="'IpipelineEApps:Access'">
    <span>If you started a life insurance application and do not see it below or in your Pending Business tab, please launch<button class="px-5 font-bold text-blue-600 underline transition duration-150 hover:text-blue-700" (click)="activateIpipelineSso()" type="button">iPipeline</button>to proceed with the application.</span>
  </app-message-notification>
  <div class="flex-grow overflow-hidden card" @fadeIn>
    <section class="flex flex-row items-center justify-between p-20 border-b border-solid border-gray-lighter">
      <h1 class="text-lg font-bold">E-Applications</h1>
      <span class="mx-20 divider is-vertical"></span>
      <div class="relative flex flex-row items-center justify-start sm:flex-grow-0 sm:w-384 ">
        <i class="absolute transform -translate-y-1/2 material-icons top-1/2 left-5 text-gray">search</i>
        <input type="search" class="w-full input is-lg pl-36" placeholder="Search..." [(ngModel)]="searchText"
               (ngModelChange)="filterBySearchText(pastApplications, searchText)" />
      </div>
      <button type="button"
              [disabled]="loadingList"
              class="flex items-center justify-center ml-auto button is-primary is-outlined"
              aria-label="Open menu" mat-button (click)="toggleSidenav()">
        <span class="flex items-center">
          <span>Filters</span>
          <span class="ml-5 material-icons mi-18">filter_alt</span>
        </span>
      </button>
    </section>
    @if (!loadingList) {
    <mat-sidenav-container class="relative flex flex-col w-full h-full overflow-hidden" @fadeIn>
      <mat-sidenav-content class="relative flex flex-col overflow-auto bg-white">
        <div class="sticky top-0 z-10 grid grid-cols-12 px-20 text-xs font-bold uppercase bg-white md:px-30 text-gray">
          <span class="flex items-center col-span-4 p-10 cursor-pointer focus:outline-none"
                (click)="orderRows('CaseName')" (keypress8)="orderRows('CaseName')" aria-label="Sort by CaseName">
            <span class="mr-5">Application Name</span>
            @if (columnName === 'CaseName') {
            <span class="material-icons text-gray">
              {{reverseOrder ? 'arrow_drop_up' : 'arrow_drop_down'}}
            </span>
            }
          </span>
          <span class="flex items-center col-span-3 p-10 cursor-pointer focus:outline-none"
                (click)="orderRows('CarrierProduct')" (keypress)="orderRows('CarrierProduct')" aria-label="Sort by Carrier/Product">
            <span class="mr-5">Product</span>
            @if (columnName === 'CarrierProduct') {
            <span class="material-icons text-gray">
              {{reverseOrder ? 'arrow_drop_up' : 'arrow_drop_down'}}
            </span>
            }
          </span>
          <span class="flex items-center col-span-2 p-10 cursor-pointer focus:outline-none"
                (click)="orderRows('Date')" (keypress)="orderRows('Date')" aria-label="Sort by Date">
            <span class="mr-5">Created On</span>
            @if (columnName === 'Date') {
            <span class="material-icons text-gray">
              {{reverseOrder ? 'arrow_drop_up' : 'arrow_drop_down'}}
            </span>
            }
          </span>
          <span class="flex items-center col-span-2 p-10 cursor-pointer focus:outline-none"
                (click)="orderRows('Status')" (keypress)="orderRows('Status')" aria-label="Sort by Date">
            <span class="mr-5">Status</span>
            @if (columnName === 'Status') {
            <span class="material-icons text-gray">
              {{reverseOrder ? 'arrow_drop_up' : 'arrow_drop_down'}}
            </span>
            }
          </span>
        </div>
        <div class="grid grid-cols-1 px-10 gap-x-20 gap-y-10 md:px-20 pb-30">
          @if (selectedFilter === applicationStatusEnum.all) {
          @for (a of filterBySearchText(pastApplications, searchText); track a; let last = $last) {
          <div
               class="grid items-center grid-cols-12 p-10 text-sm font-bold rounded bg-gray-lightest"
               [class]="a.IsPriority ? 'text-orange order-1' : !onloadOrder || a.DisplayStatus !== FirelightActivityCodeEnum.Complete ? 'text-gray-dark order-2' : 'text-gray-dark order-3'"> <!--'onloadOrder' used to sink down "Complete" Apps only on the initial load-->
            <span class="flex items-center col-span-4 p-10 ">
              @if (a.IsPriority) {
              <span class="mr-10 material-icons mi-32">warning_amber</span>
              }{{a.ActivityName}}
            </span>
            <span class="flex flex-col col-span-3 p-10">
              <span>{{a.ProductName}}</span>
            </span>
            @if (a.TransactionId) {
            <span class="col-span-2 p-10 font-medium">
              {{a.AuditDate | date: 'M/dd/yyyy' : 'UTC'}}
            </span>
            }@else {
            <span class="col-span-2 p-10 font-medium ">
              {{a.AuditDate | date: 'M/dd/yyyy'}}
            </span>
            }
            <span class="flex col-span-2 p-10">
              <span class="flex mr-auto text-xs font-bold uppercase ">
                <span>{{a.DisplayStatus | splitCamelCase: 0}}</span>
              </span>
            </span>
            <span class="flex justify-end col-span-1 p-10 gap-x-10">
              <button mat-button type="button" class="ml-20 button icon-button is-primary is-inverted" [ngClass]="{'text-orange': a.IsPriority}"
                      [matMenuTriggerFor]="flApplicationMenu">
                <i class="material-icons">more_horiz</i>
              </button>
              <mat-menu #flApplicationMenu="matMenu">
                <div class="flex flex-col items-start gap-y-10">
                  @if (a.Status.toLowerCase() !== 'complete') {
                  <button mat-menu-item type="button" class="flex items-center justify-start button is-lg "
                          (click)="goToActivity(a.ActivityId)" aria-label="Continue Firelight e-Application"
                          amplitudeEvent="Eapp History - Row Menu - Continue Firelight e-Application">
                    <span class="flex items-center">
                      <i class="material-icons mi-18">drive_file_rename_outline</i>
                      <span class="my-auto ml-10">Continue Application</span>
                    </span>
                  </button>
                  } @else {
                  <button mat-menu-item type="button" class="flex items-center justify-start button is-lg "
                          (click)="goToActivity(a.ActivityId)" aria-label="View Firelight e-Application"
                          amplitudeEvent="Eapp History - Row Menu - View Firelight e-Application">
                    <span class="flex items-center">
                      <i class="material-icons mi-18">drive_file_rename_outline</i>
                      <span class="my-auto ml-10">View Application</span>
                    </span>
                  </button>
                  }
                  <button mat-menu-item type="button" class="flex items-center justify-start button is-lg"
                          amplitudeEvent="Eapp History - Row Menu - Copy Firelight e-Application"
                          (click)="openCopyFireLightModal(a)" aria-label="Copy Firelight e-Application">
                    <span class="flex items-center">
                      <i class="material-icons mi-18">content_copy</i>
                      <span class="my-auto ml-10">Copy Application</span>
                    </span>
                  </button>
                  <button mat-menu-item type="button" class="flex items-center justify-start button is-lg"
                          amplitudeEvent="Eapp History - Row Menu - View Application Documents"
                          (click)="openEApplicationDownloadsModal(a)" aria-label="View Applications Documents">
                    <span class="flex items-center">
                      <i class="material-icons mi-18">description</i>
                      <span class="my-auto ml-10">Download Files</span>
                    </span>
                  </button>
                </div>
              </mat-menu>
            </span>
          </div>
          }

          @empty {
          <p class="w-full p-20 text-center md:px-30 bg-gray-lightest">No results found.</p>
          }
          } @else {
          @for (a of filterBySearchText(filteredApplications, searchText); track a; let last = $last) {
          <div class="grid items-center grid-cols-12 p-10 text-sm font-bold rounded bg-gray-lightest"
               [class]="a.IsPriority ? 'text-orange order-1' : !onloadOrder || a.DisplayStatus !== FirelightActivityCodeEnum.Complete ? 'text-gray-dark order-2' : 'text-gray-dark order-3'"> <!--'onloadOrder' used to sink down "Complete" Apps only on the initial load-->
            <span class="flex items-center col-span-4 p-10 ">
              @if (a.IsPriority) {
              <span class="mr-10 material-icons mi-32">warning_amber</span>
              }{{a.ActivityName}}
            </span>
            <span class="flex flex-col col-span-3 p-10">
              <span>{{a.ProductName}}</span>
            </span>
            @if (a.TransactionId) {
            <span class="col-span-2 p-10 font-medium">
              {{a.AuditDate | date: 'M/dd/yyyy' : 'UTC'}}
            </span>
            }@else {
            <span class="col-span-2 p-10 font-medium ">
              {{a.AuditDate | date: 'M/dd/yyyy'}}
            </span>
            }
            <span class="flex col-span-2 p-10">
              <span class="flex mr-auto text-xs font-bold uppercase ">
                <span>{{ a.IsPriority ? 'Pending Submission' : (a.DisplayStatus | splitCamelCase: 0)}}</span>
              </span>
            </span>
            <span class="flex justify-end col-span-1 p-10 gap-x-10">
              <button mat-button type="button" class="ml-20 button icon-button is-primary is-inverted" [ngClass]="{'text-orange': a.IsPriority}"
                      [matMenuTriggerFor]="flApplicationMenu">
                <i class="material-icons">more_horiz</i>
              </button>
              <mat-menu #flApplicationMenu="matMenu">
                <div class="flex flex-col items-start gap-y-10">
                  @if (a.Status.toLowerCase() !== 'complete') {
                  <button mat-menu-item type="button" class="flex items-center justify-start button is-primary is-lg is-inverted"
                          (click)="goToActivity(a.ActivityId)" aria-label="Continue Firelight e-Application"
                          amplitudeEvent="Eapp History - Row Menu - Continue Firelight e-Application">
                    <i class="material-icons">drive_file_rename_outline</i>
                    <span class="my-auto ml-10">Continue Application</span>
                  </button>
                  }
                  <button mat-menu-item type="button" class="flex items-center justify-start button is-primary is-lg is-inverted"
                          amplitudeEvent="Eapp History - Row Menu - Copy Firelight e-Application"
                          (click)="openCopyFireLightModal(a)" aria-label="Copy Firelight e-Application">
                    <i class="material-icons">content_copy</i>
                    <span class="my-auto ml-10">Copy Application</span>
                  </button>
                  <button mat-menu-item type="button" class="flex items-center justify-start button is-primary is-lg is-inverted"
                          amplitudeEvent="Eapp History - Row Menu - View Application Documents"
                          (click)="openEApplicationDownloadsModal(a)" aria-label="View Applications Documents">
                    <i class="material-icons">description</i>
                    <span class="my-auto ml-10">Download Files</span>
                  </button>
                </div>
              </mat-menu>
            </span>
          </div>
          }
          }
        </div>
      </mat-sidenav-content>
      <mat-sidenav class="w-full bg-white xs:w-1/2 sm:shadow sm:w-320" position="end" #sidenav
                   [mode]="sidenavConfig.Mode" [opened]="sidenavConfig.IsOpened">
        <div class="flex flex-col w-full p-10 md:p-20">
          <mat-button-toggle-group aria-label="Application Status" class="flex m-10 mb-20" [(ngModel)]="selectedFilter" (ngModelChange)="showStatus(selectedFilter)">
            <mat-button-toggle class="flex-grow" [value]="applicationStatusEnum.all">All</mat-button-toggle>
            <mat-button-toggle class="flex-grow" [value]="applicationStatusEnum.incomplete">Incomplete</mat-button-toggle>
            <mat-button-toggle class="flex-grow" [value]="applicationStatusEnum.complete">Complete</mat-button-toggle>
          </mat-button-toggle-group>
          <!-- WR-3795 - Hide for iComply user groups -->
          @if(canSeeFirelightSso) {
          <button type="button" class="flex items-center m-10 button is-lg is-inverted" aria-label="Firelight e-Applications" amplitudeEvent="Eapp History - Firelight SSO"
                  (click)="activateFirelightSso()" aria-label="Firelight e-Applications">
            <img class="w-24 h-24 mr-10" src="/v1/Images/Icons/firelight.svg" aria-label="Firelight Image" alt="iGo Image">
            <span>Launch Firelight</span>
          </button>
          }
          <ng-container *showIfOne="'IpipelineEApps:Access'">
            <button type="button" class="flex items-center m-10 button is-lg is-inverted" amplitudeEvent="Eapp History - IGO SSO"
                    aria-label="iGo e-Applications" (click)="activateIpipelineSso()" aria-label="iGo e-Applications">
              <img class="w-24 h-24 mr-10" src="/v1/Images/Icons/ipipeline.svg" aria-label="iGo Image" alt="iGo Image">
              <span>Launch IPipeline</span>
            </button>
          </ng-container>
          <button type="button" class="flex items-center m-10 button is-lg is-inverted" amplitudeEvent="Eapp History - Aspida SSO"
                  aria-label="Aspida e-Applications" (click)="activateAspidaSso()" aria-label="Aspida e-Applications">
            <img class="w-24 h-24 mr-10" src="./assets/images/icons/aspida-icon-color.svg" aria-label="Aspida Image" alt="iGo Image">
            <span>Launch Aspida</span>
          </button>
          <button type="button" class="flex items-center m-10 button is-lg is-inverted"
                  aria-label="Go to my business" (click)="openPaperAppModal()">
            <span class="w-24 mr-10 material-icons mi-18 text-gray">send</span>
            <span class="mr-auto">Upload Paper Application</span>
          </button>
        </div>
      </mat-sidenav>
    </mat-sidenav-container>
    } @else {
    <img src="/assets/images/loading/table-placeholder.svg" alt="loading" class=" animate-pulse">
    }
  </div>
</section>