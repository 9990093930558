<section class="flex flex-col flex-grow w-full h-full">
  <header class="flex items-center flex-none p-20 border-b border-solid border-gray-lighter">
    <h2 class="m-10 text-lg font-bold">E-Application Documents</h2>
    <button mat-button type="button" class="m-10 ml-auto button icon-button is-primary is-inverted" [mat-dialog-close]="true">
      <i class="material-icons">close</i>
    </button>
  </header>
  <section class="flex flex-col flex-grow p-20 overflow-auto gap-y-10">
    <!-- <button mat-button class="ml-auto button is-primary is-inverted" aria-label="Download All Files" (click)="downloadAllDocuments()">Download all</button> -->
    @if (!loadingDocuments) {
      @for (document of transactionDocuments; track document) {
        @if (document.Url) {
          <div class="flex flex-col gap-y-10">
            <a class="flex items-center p-10 font-bold transition-colors duration-150 rounded bg-gray-lightest md:gap-x-20 gap-x-10 hover:bg-blue-lightest" matTooltip="Download {{document.FileName}}"
              attr.aria-label="Download {{document.FileName}}"
              [href]="document.Url"
              target="_blank">
              <span class="material-icons text-gray">
                file_download
              </span>
              <span class="">{{!!document.FileName ? document.FileName : 'No Filename' }}</span>
            </a>
          </div>
        }
      }@empty {
        <div class="flex items-center p-10 font-bold transition-colors duration-150 rounded bg-gray-lightest">
          No files attached to this application.
        </div>
      }
    }@else {
      <div class="relative flex-grow p-40 m-auto loader-wrap" @fadeIn>
        <div class="loader-sm is-blue"></div>
      </div>
    }
  </section>
  @if (!environment.production) {
    <div class="flex flex-none p-20 mt-auto border-t border-solid border-gray-lighter">
      <!-- <button mat-button class="m-10 ml-auto button is-primary" [mat-dialog-close]="true">Close</button> -->
      <button mat-button type="button" class="flex items-center justify-center m-10 ml-auto button is-primary" aria-label="Download All Files" [disabled]="!!loadingDocuments || !transactionDocuments.length"
        (click)="downloadAllDocuments()">
        Download&nbsp;&nbsp;all{{!!transactionDocuments.length ? ' (' + transactionDocuments.length + ')' : ''}}
      </button>
    </div>
  }
</section>