export const externalLoadingHtml:string =
'<html>' +
'<head>' +
'<title></title>' +
'</head>' +
'<body>' +
'   <header>' +
'   <style>' +
'     html, body { margin: 0; padding: 0; background: #fff; color: #5c5c69 }' +
'      .centeredDiv { position: absolute; margin: auto; top: 0; right: 0; bottom: 0; left: 0; width: 400px; height: 400px; text-align: center; font-size:20px; }' +
'      .loader-wrap { height: 100%; width: 100%; display: flex; }' +
'      .loader-main { border: 12px solid #c8d8e3;border-radius: 50%; border-top: 12px solid #0c5acf; width: 96px; height: 96px; max-width: 40%; - moz - animation: spin 1.5s linear infinite; -o - animation: spin 1.5s linear infinite; -webkit - animation: spin 1.5s linear infinite; animation: spin 1.5s linear infinite; margin: auto; }' +
'      @keyframes spin { 0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); } } ' +
'   </style> ' +
'   </header> ' +
'   <div class=\'centeredDiv\'> ' +
'       <div class=\'loader-wrap\'> ' +
'           <div class=\'loader-main\'>' +
'       </div> ' +
'   </div >' +
'</body > ' +
'</html> ';