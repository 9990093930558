<div class="relative z-50 flex flex-col h-full">
  <div class="flex items-center text-lg font-bold text-left border-b border-gray-lighter p-30 text-gray-dark">
    <h1>{{headers.header}}</h1>
    <button mat-button mat-dialog-close="" class="ml-auto icon-button is-inverted is-primary" type="button">
      <i class="material-icons">close</i>
    </button>
  </div>
  <div class="flex justify-between">
    <div class="flex flex-col p-20 border-r pt-15 border-gray-lighter">
      @for (lob of lobList; track lob) {
        <button (click)="changeComparedProductDisplay(lob)" mat-button type="button" [ngClass]="lob === data ? 'bg-gray-lightest text-blue-dark' : 'text-gray-dark'"
          class="flex flex-col justify-center px-20 mt-10 text-base leading-none text-left capitalize transition-all duration-100 rounded py-15 weight-bold h-50 w-180 hover:bg-blue-lightest">
          <span class="mr-auto text-left">{{lob}}</span>
        </button>
      }
    </div>
    <div class="flex flex-col grow">
      <div class="flex flex-row justify-center p-30 grow gap-x-20">
        @for (product of content; track product) {
          <div class="flex flex-col rounded bg-gray-lightest min-h-360 w-320">
            <div class="flex p-20">
              <img [src]="product.logoUrl" [alt]="product.name" class="w-auto h-48 m-auto">
            </div>
            <div class="flex flex-col justify-between p-20 m-2 bg-white min-h-320 grow">
              <ul class="mb-auto list-disc">
                @for (listItem of product.verbiage; track listItem) {
                  <li class="pb-20 text-sm font-bold ml-15 text-gray-dark" [innerHtml]="listItem"></li>
                }
              </ul>
              @if (product.action) {
                <button
                  (click)="product.action()"
                  [amplitudeEvent]="'Compare Products Modal - ' + product.name"
                  mat-button type="button"
                  class="w-full button is-primary">
                  <span class="flex items-center">
                    Launch {{product.name}}
                    <span class="material-icons mi-18">chevron_right</span>
                  </span>
                </button>
              } @else {
                <a mat-button
                  [href]="product.url"
                  target="_blank"
                  [amplitudeEvent]="'Compare Products Modal - ' + product.name"
                  class="w-full button is-primary">
                  <span class="flex items-center">
                    Launch {{product.name}}
                    <span class="material-icons mi-18">chevron_right</span>
                  </span>
                </a>
              }
            </div>
          </div>
        }
      </div>
      <div class="flex flex-col items-center pt-0 p-30">
        @if (footerItems.length > 0) {
          <p class="text-lg font-medium text-gray-dark">{{headers.footer}}</p>
        }
        @if (footerItems.length > 0) {
          <div class="flex flex-row mt-20 gap-x-10">
            @for (item of footerItems; track item) {
              @if (item.action) {
                <button class="button bg-gray-lightest hover:bg-blue-lightest"
                  (click)="item.action()"
                  [amplitudeEvent]="'Compare Products Modal - ' + item.name"
                  mat-button type="button">
                  {{item.name}}
                </button>
              } @else {
                <a href="{{item.url}}"
                  target="_blank"
                  mat-button
                  type="button"
                  [amplitudeEvent]="'Compare Products Modal - ' + item.name"
                class="button bg-gray-lightest hover:bg-blue-lightest">{{item.name}}</a>
              }
            }
          </div>
        }
      </div>
    </div>
  </div>
</div>