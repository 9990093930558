<div class="flex flex-col w-full h-full mx-auto justify-center dialog-content">
  <section class="flex flex-col justify-center h-full gap-y-20" @fadeIn>
    @if (!isWealthboxConnected) {
      <div class="flex flex-col items-center m-auto gap-y-20 max-w-400">
        <img class="w-auto h-50" src="./assets/images/logos/wealthbox-logo-blue.svg" aria-label="Wealthbox Image" alt="Wealthbox logo">
        <p class="text-lg">Connect to your Wealthbox account below to enable searching for your Wealthbox clients in e-application forms.</p>
        <button type="button"
                class="button is-primary"
                [disabled]="isSubmitting"
                (click)="activateWealthbox()">
          Sign{{isSubmitting ? 'ing' : ''}} In
        </button>
      </div>
    }@else {
    <div class="flex flex-col w-full h-full">
      <div class="bg-green-50 border border-green-200 rounded flex items-center p-10">
        <i class="material-icons text-green-500">
          check_circle
        </i>
        <span class="w-full text-base font-bold ml-5">Connected to Wealthbox CRM.</span>
        <button mat-button type="button" class="ml-auto button px-25 is-outlined is-warning"
                (click)="deactivateWealthbox()"
                [disabled]="isSubmitting"
                appCyData="wealthboxDisconnectButton">
          Disconnect{{isSubmitting ? 'ing' : ''}}
        </button>
      </div>
      <span class="my-20 divider col-span-full"></span>
      <app-start-crm-eapp class="flex" [crmName]="'Wealthbox'"></app-start-crm-eapp>
    </div>
    }
  </section>
</div>