import { AngularEnvironmentModel } from 'src/app/shared/models/generic.models';

export const environment: AngularEnvironmentModel = {
  production: false,
  beta: false,
  qa: true, // QA is true even on localhost
  marketplaceOneUI: 'https://qa.marketplace-one.figdevteam.com/#',
  iComplyUI: 'https://qa.icomply.io/#',
  truelyticsSsoUrl: 'https://app.truelytics.com/user/sso?auto=true&id=',
  aspidaSso: 'https://sso.aspida.com/',
  equitrustAppLink: 'https://agents.equitrust.com/',
  amplitudeV1ApiKey: 'ca58378874aa15fac518523ee07b1d29', // TODO: Remove when finish migration to backend
  amplitudeV2ApiKey: 'a53c801165de68c01f3853619cefc50f'
};
