import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CompareProductModel } from 'src/app/shared/models/compare-products.model';
import { CompareProductsEnum } from 'src/app/shared/enums/compare-products.enum';
import { nationwideLifeHtml } from '../../constants/nationwide-life.constant';
import { SsoService } from 'src/app/core/services/sso.service';
import { PermissionService } from 'src/app/core/services/auth/permission.service';
import { AnnexusPermissions, AnnuityRateWatchPermissions, CareMatterQuotePermissions, CompareLobPermissions, EnsightPermissions, LctiQuotePermissions, LifeProductSearchPermissions, LumaPermissions, PolicyGeniusPermissions, RetireUpProPermissions, SPIADIAPermissions, TermLifeQuotePermissions, WinflexPermissions } from '../../constants/permissions-shorthand.constants';
import { CarrierAnnouncementComponent } from 'src/app/portal/features/dashboard-feature/components/feature-links/components/carrier-announcement/carrier-announcement.component';

@Component({
  selector: 'app-compare-products',
  templateUrl: './compare-products.component.html'
})

export class CompareProductsComponent implements OnInit, AfterViewInit {

  constructor(
    public dialogRef: MatDialogRef<CompareProductsComponent>,
    private dialog: MatDialog,
    private permissionService: PermissionService,
    private ssoService: SsoService,
    @Inject(MAT_DIALOG_DATA) public data?: CompareProductsEnum,
  ) { }

  headers: CompareProductModel = {
    header: CompareProductsEnum.header,
    subheader: CompareProductsEnum.subheader,
    footer: CompareProductsEnum.footer
  };

  lobList: CompareProductsEnum[] = [
    { Name: CompareProductsEnum.annuity, Permissions: CompareLobPermissions.annuity },
    { Name: CompareProductsEnum.life, Permissions: CompareLobPermissions.life },
    { Name: CompareProductsEnum.ltc, Permissions: CompareLobPermissions.ltc },
    { Name: CompareProductsEnum.notes, Permissions: CompareLobPermissions.notes },
  ].filter(x => {
    return x.Name === CompareProductsEnum.notes
      ? this.permissionService.hasAllPermissions(x.Permissions)
      : this.permissionService.hasOnePermission(x.Permissions);
  }).map(x => x.Name);

  compareProductsEnum = CompareProductsEnum;
  activeProductType?: CompareProductsEnum;
  content: CompareProductModel[] = [];
  footerItems: CompareProductModel[] = [];

  luma: CompareProductModel = {
    name: 'LUMA',
    logoUrl: './assets/images/logos/luma-logo.png',
    url: '/api/LumaSso?destination=Annmarketplace',
    verbiage: [
      'Supports FIA & MYGA Products',
      'Product Comparison for Accumulation and Income',
      'Determine the optimal allocation between crediting strategies for particular product through the Efficient Frontier tool',
      'Full Rate, Rider, and Product Feature Information',
      '<a class="text-blue-dark underline" target="_blank" aria-label="Luma Platform Overview link" href="https://www.figmarketing.com/files/resources/FIG_Luma_Partnership.pdf">Luma Platform Overview</a>'
    ],
    permissions: LumaPermissions,
  };

  arw: CompareProductModel = {
    name: 'Annuity Rate Watch',
    logoUrl: './assets/images/logos/awr-logo.png',
    action: () => {
      this.ssoService.annuityRateWatchSso();
    },
    verbiage: [
      'Supports FIA & MYGA Products',
      'Interest Rate History Across Crediting Strategies',
      'Rider Quoting and Comparison',
      'Rate Change Updates'
    ],
    permissions: AnnuityRateWatchPermissions
  };

  ensight: CompareProductModel = {
    name: 'Ensight',
    logoUrl: './assets/images/logos/ensight-logo.png',
    action: () => {
      this.ssoService.ensightSso();
    },
    verbiage: [
      'Simplified process to quote permanent Life Insurance products',
      'Compare numerous products side by side',
      'Ability to incorporate illustration numbers into Sales Stories'
    ],
    permissions: EnsightPermissions,
  };

  termlife: CompareProductModel = {
    name: 'Term Life Quote',
    logoUrl: './assets/images/logos/term-life-quote-logo.png',
    action: () => {
      this.dialog.open(CarrierAnnouncementComponent);
      //this.ssoService.iPipelineLifeQuotesSso();
    },
    verbiage: [
      'Quickly quote Term life',
      'Connect directly to desired application',
      'Compare products and features'
    ],
    permissions: TermLifeQuotePermissions,
  };

  winflex: CompareProductModel = {
    name: 'Winflex',
    logoUrl: './assets/images/logos/winflex-logo.png',
    url: 'https://www.winflexweb.com/',
    verbiage: [
      'Multi-carrier Life Insurance quote engine',
      'Quote UL, IUL, VUL, WL and Term',
      'Full NAIC compliant illustrations for use with clients'
    ],
    permissions: WinflexPermissions
  };

  Annexus: CompareProductModel = {
    name: 'Annexus Direct Quotes',
    action: () => {
      this.ssoService.annexusSso();
    },
    permissions: AnnexusPermissions,
  };

  LifeProductSearch: CompareProductModel = {
    name: 'Life Product Search',
    action: () => {
      this.ssoService.iPipelineLifeProductsSso();
    },
    permissions: LifeProductSearchPermissions,
  };

  Policygenius: CompareProductModel = {
    name: 'Policygenius',
    url: 'https://partners.policygenius.com/',
    permissions: PolicyGeniusPermissions
  };

  RetireupPro: CompareProductModel = {
    name: 'Retireup Pro',
    url: 'https://elite.retireup.com/login',
    permissions: RetireUpProPermissions,
  };

  SPIADIA: CompareProductModel = {
    name: 'SPIA/DIA Quotes',
    action: () => {
      this.ssoService.cannexSso();
    },
    permissions: SPIADIAPermissions,
  };

  ltciQuoteEngine: CompareProductModel = {
    name: 'LTCI Quote Engine',
    logoUrl: './assets/images/logos/ltci-partners-logo.png',
    url: 'https://www.ltcipartners.com/generate-a-quote-stratecision',
    verbiage: [
      'Get quotes for a single client or a couple, for virtually all current LTC insurance plans',
      'Choose levels for all benefits, including special riders unique to the policy',
      'Create single or comparative proposals, up to 6 on a page',
      'Show additional illustrations such as cost of waiting, how inflation protection increases benefits, and alternative premium tables',
      'Quotes are state specific and designed to mirror those of the carriers\' software'
    ],
    permissions: LctiQuotePermissions,
  };

  nationwideCareMatter: CompareProductModel = {
    name: 'Care Matters',
    logoUrl: './assets/images/logos/nationwide-logo.png',
    action: () => {
      const w = window.open();
      w?.document.write(nationwideLifeHtml);
    },
    verbiage: [
      'Hybrid/Combo Life/LTC policies are growing rapidly in market share',
      'This tool allows you to run every quote variation under the sun with Nationwide\'s Care Matter\'s product on a side-by-side basis',
      'It has all the advantages of the LTC tools, including portability, ease of use, and comprehensive cost and benefit comparisons'
    ],
    permissions: CareMatterQuotePermissions,
  };

  changeComparedProductDisplay(lobType?: CompareProductsEnum): void {
    if (!lobType) return;
    this.data = lobType;
    // this.headers.header = this.headers.header;
    // this.headers.subheader = this.headers.subheader;
    // this.headers.footer = this.headers.footer;
    if (lobType === CompareProductsEnum.annuity) {
      this.content = this.checkPermissions([this.luma, this.arw]);
      this.footerItems = this.checkPermissions([this.SPIADIA, this.Annexus, this.RetireupPro]);
    }
    if (lobType === CompareProductsEnum.life) {
      this.content = this.checkPermissions([this.termlife, this.winflex, this.ensight]);
      this.footerItems = this.checkPermissions([this.Policygenius, this.LifeProductSearch]);
    }
    if (lobType === CompareProductsEnum.ltc) {
      this.content = this.checkPermissions([this.ltciQuoteEngine, this.nationwideCareMatter]);
      this.footerItems = [];
    }
    if (lobType === CompareProductsEnum.notes) {
      this.footerItems = [];
      window.open('/api/LumaSso?destination=Newissues');
    }
  }

  ngOnInit(): void {
    this.changeComparedProductDisplay(this.data);
  }

  ngAfterViewInit(): void {
    this.dialogRef.updateSize('128rem');
  }

  checkPermissions(products: CompareProductModel[]): CompareProductModel[] {
    return products.filter(p => !p.permissions || this.permissionService.hasOnePermission(p.permissions));
  }
}
