import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WealthboxUserModel } from 'src/app/shared/models/wealthbox-user.models';

@Injectable({
  providedIn: 'root'
})
export class WealthboxApiService {

  constructor(private http: HttpClient) { }

  isConnected(): Observable<boolean> {
    return this.http.get<boolean>('/api/Wealthbox/Sso/IsConnected');
  }

  removeAuthentication(): Observable<boolean>{
    return this.http.delete<boolean>('/api/Wealthbox/Sso/Disconnect');
  }

  getUserInfo(): Observable<WealthboxUserModel> {
    return this.http.get<WealthboxUserModel>('/api/Wealthbox/Api/UserInfo');
  }
}
