export interface ListResponse<T> {
  Entries: T[];
}

export interface SingleResponse<T> {
  Entries: T;
}

export interface EntryResponse<T> {
  Entry: T;
}

export enum EnvironmentEnum {
  'local',
  'qa',
  'beta',
  'prod'
}

export interface BasicModel {
  Id: number;
  Title: string;
}

export interface AchVendorModel {
  VendorId: number;
  Vendor: string;
  EmailAddress: string;
}

export interface StateBasicModel {
  ShortName: string;
  LongName: string;
}

export interface State {
  StateID: number | null;
  State: string;
  StateName: string | null;
  IsVisible: boolean | null;
}
export interface GenericNameValue {
  name: string;
  value: string | number | Date | unknown;
}
export interface GenericNameValueString extends GenericNameValue {
  name: string;
  value: string;
}

export interface GenericNameValueNumber extends GenericNameValue {
  value: number;
}

export interface AngularEnvironmentModel {
  production: boolean,
  beta: boolean,
  qa: boolean,
  marketplaceOneUI: string,
  iComplyUI: string,
  truelyticsSsoUrl: string,
  aspidaSso: string,
  equitrustAppLink: string,
  amplitudeV1ApiKey: string,
  amplitudeV2ApiKey: string,
}
