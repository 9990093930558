import { Route } from '@angular/router';
import { BusinessRouteParamReportTypeEnum } from 'src/app/portal/components/business/enums/policy-v2.enums';

/**
 * Custom Portal `Route` model, extending addtional `route.data` properties
 * @param data Custom route data
 */
export interface CustomPortalRoute extends Omit<Route, 'children' | 'data'> {
  data?: CustomRouteData;
  children?: CustomPortalRoute[];
}

/**
 * Allows for more strict property access in route data. Ctrl+click to see all property descriptions
 *
 * V1 designates old nav-only props. V2 designates new nav-only props. Others may be used in both
 *
 */
export interface CustomRouteData {
  /** Page title. Can be used for the nav & with title service */
  title?: string;
  /** Used in Business pages. TODO: Can it be removed in V2? */
  reportType?: BusinessRouteParamReportTypeEnum;
  /** List out permissions associated with this route. Note: Use array of permissions instead as pipe-separated string will soon be deprecated */
  permissions?: string | string[];
  /** Used in conjunction with permissions in route guarding */
  reRoute?: string;
  /** Used in conjunction with permissions in route guarding */
  denyPermissions?: string | string[];
  /** Used in conjunction with permissions in route guarding */
  allOrOne?: allOrOneEnum;
}

/**
 * @prop `internal` Angular route path
 * @prop `external` External URL
 * @prop `action` Some function to be executed
 * @prop `none` No action available. Only used as a top-lvl folder
 */
export enum RouteTypeEnum {
  internal = 'Internal',
  external = 'External',
  action = 'Action',
  none = 'None',
}

export enum allOrOneEnum {
  showIfAll = 'showIfAll',
  showIfOne = 'showIfOne'
}
