import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trimDecimal',
  standalone: false
})

export class TrimDecimalPipe implements PipeTransform {
  /**
 * WARNING: This pipe only cuts off excess decimal places without rounding
 * Also it's mainly used for trimming CPCs so not recommended for non-currency numerical values
 * @param decimalPlaces specify the number of decimal places to keep
 */
  transform(number = 0, decimalPlaces?: number): string {
    const rounding = Math.pow(10, decimalPlaces || 2);
    const result = Math.floor(number * rounding) / rounding;
    return result.toLocaleString();
  }
}