/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { AuthService } from '../core/services/auth/auth.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { LeaderboardComponent } from './modals/leaderboard/leaderboard.component';
import { MatDialog } from '@angular/material/dialog';
import { PermissionService } from '../core/services/auth/permission.service';
import { RedeemMarketingDollarsComponent } from './modals/redeem-marketing-dollars/redeem-marketing-dollars.component';
import { Router, ActivatedRoute, RouterOutlet } from '@angular/router';
import { SupportComponent } from './modals/support/support.component';
import { User, UserDelegate } from '../shared/models/user.models';
import { UserApiService } from '../core/services/http/user-api.service';
import { UserGroup } from '../shared/models/usergroups.models';
import { SessionStoreService } from '../core/services/stores/session-store.service';
import { SsoService } from '../core/services/sso.service';
import { fadeIn, fadeInOut, routerTransition, slideInOut } from '../animations';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { sidenavConfig } from '../shared/models/sidenav-config.model';
import { AgentMarketingDollarsResponse } from '../shared/models/marketingdollar.models';
import { MarketingDollarApiService } from '../core/services/http/marketingdollar-api.service';
import { MarketingExpenseHistoryComponent } from './modals/marketing-expense-history/marketing-expense-history/marketing-expense-history.component';
import { CpcApiService } from '../core/services/http/cpc-api.service';
import { CpcModel } from '../shared/models/cpc.models';
import { StartIllustrationComponent } from './modals/start-illustration/start-illustration/start-illustration.component';
import { environment } from 'src/environments/environment';
import { UserLogoUploadComponent } from './modals/user-logo-upload/user-logo-upload.component';
import { figLogoUrl } from 'src/app/shared/constants/misc.constants';
import { ConfirmationComponent } from './modals/confirmation/confirmation.component';
import { ConfirmationData } from '../shared/models/modal-data.models';
import { EnrollmentFormApiService } from '../core/services/http/enrollment-form-api.service';
import { RenewalFormComponent } from './modals/renewal-form/renewal-form.component';
import { SurveyRenewalModel } from '../shared/models/surveyRenewal.models';
import { OnboardingModel, OnboardingService } from '../core/services/onboarding.service';
import { OnboardingAgentLicensesOptOut, OnboardingTermsOfUse } from '../shared/models/onboarding.models';
import { renewalStatusEnum } from '../shared/enums/renewal-status.enum';
import { termsOfUseUrl } from '../shared/constants/fig-terms-of-use.constants';
import { ManageIntegrationsModalComponent } from './modals/manage-integrations-modal/manage-integrations-modal.component';
import { ManageIntegrationsEnum } from '../shared/enums/integrations.enum';
import { StartApplicationFormComponent } from './features/e-application/components/start-application-form/start-application-form.component';
import { MatSidenav } from '@angular/material/sidenav';
import { SubmitPaperApplicationV2Component } from './modals/submit-paper-application-v2/submit-paper-application-v2.component';
import { CpcReportingPermissions, ExplorePermissions } from '../shared/constants/permissions-shorthand.constants';
import { PortalCookies } from '../shared/constants/browser-cookie.constants';
import { AnnualStatementAnnouncementComponent } from './features/lifecycle/components/annual-statement-announcement/annual-statement-announcement.component';

@Component({
  animations: [fadeInOut, fadeIn, slideInOut, routerTransition],
  selector: 'app-portal',
  templateUrl: './portal.component.html',
})

export class PortalComponent implements OnInit {
  showVismeBanner = localStorage.getItem('VismeHidden') ? false : true;
  figLogoUrl = figLogoUrl;
  termsOfUseUrl = termsOfUseUrl.figOnly;
  completedTrainingUrl = '/Portal/ProductRD/Education/ProductTraining?searchValue=completed';
  cpcStartDate: Date = this.getCPCStartDate();
  cpcEndDate: Date = this.getCPCMaxEndDate();
  userCPCs?: CpcModel;
  confirmResult = false;
  delegates: UserDelegate[] = [];
  delegateId!: string | null;
  fromOnboarding!: boolean;
  hasCompletedOnboardingCheck = false;
  hasSubmitMarketingExpensesPermissions = (this.permissionService.hasAllPermissions('AccessMarketingPoints:View|PointAdjustments:View'));
  hasCPCPermission = (this.permissionService.hasOnePermission(CpcReportingPermissions));
  loading = false;
  logo!: string | null;
  menuOpen = false;
  onBehalfOfUser!: User | null;
  sideNavIsOpen = true;
  user!: User;
  userGroup!: UserGroup | null;
  userMarketingDollars!: AgentMarketingDollarsResponse | null;
  viewingUser!: User;
  userSidenavConfig: sidenavConfig = {
    IsOpened: false,
    Mode: 'side',
  };
  portalSidenavConfig: sidenavConfig;
  mediaMobile = false;
  @ViewChild('userSidenav') userSidenav!: MatSidenav;
  @ViewChild('portalSidenav') portalSidenav!: MatSidenav;
  environment = environment;
  termsOfUse: OnboardingTermsOfUse | undefined;
  npnCrdOptOut: OnboardingAgentLicensesOptOut | undefined;
  canSeeIllustrationButton = false;
  clockTick = Date.now();
  selectedUserId: string | 1 = 1;
  userHasOpenRenewals = false;
  renewals?: SurveyRenewalModel[];
  explorePermissions = ExplorePermissions;
  icomplyUser = this.sessionStore.IsIcomplyUser;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private breakpointObserver: BreakpointObserver,
    private cpcApiService: CpcApiService,
    private dialog: MatDialog,
    private enrollmentFormApiService: EnrollmentFormApiService,
    private permissionService: PermissionService,
    private router: Router,
    private marketingDollarApiService: MarketingDollarApiService,
    private onboardingService: OnboardingService,
    private sessionStore: SessionStoreService,
    private ssoService: SsoService,
    private userApiService: UserApiService,
  ) {
    //define main sidenav config here to ensure it loads first to avoid stuttering issue
    this.portalSidenavConfig = {
      IsOpened: true,
      Mode: 'over',
    };

    this.router.events
      .subscribe(() => {
        const sideNavContainer = document.querySelector('.mat-sidenav-content');
        if (sideNavContainer) sideNavContainer.scrollTop = 0;
      });
  }

  ngOnInit(): void {
    this.loading = true;
    this.fromOnboarding = this.activatedRoute.snapshot.queryParamMap.get('fromOnboarding') === 'true' ? true : false;
    this.configureUserInfo();
    this.activatedRoute.data
      .subscribe(resolve => {
        this.termsOfUse = resolve.userTermsOfUse;
        this.npnCrdOptOut = resolve.userAgentLicensesOptOut;
        if (!this.sessionStore.OnBehalfOfUser && !this.fromOnboarding) {
          const resolveOnboardingModel: OnboardingModel = {
            userMasterAdvisorAgreement: resolve.userMasterAdvisorAgreement,
            userMasterAdvisorAgreementRequired: resolve.userMasterAdvisorAgreementRequired,
            userAgentLicensesOptOut: resolve.userAgentLicensesOptOut,
            userContracting: resolve.userContracting,
            termsOfUse: resolve.userTermsOfUse
          };
          this.runOnboardingCheck(resolveOnboardingModel);
        } else {
          this.hasCompletedOnboardingCheck = true;
        }
        this.setCanSeeIllustrationButton();
        this.getMarketingDollars();
        this.getCPCs();
        this.configureLayOut();
        if (this.router.url === '/Portal/') {
          this.router.navigateByUrl('Portal/Dashboard');
        }
        this.loading = false;
        this.openAnnouncementPopup();
      });

    if (this.hasCompletedOnboardingCheck) this.checkForRenewals();
  }

  openAnnouncementPopup(): void {
    const hasSeenModal = localStorage.getItem(PortalCookies.annualStatementAnnouncementSeen);
    if (!hasSeenModal) {
      this.dialog.open<AnnualStatementAnnouncementComponent>(AnnualStatementAnnouncementComponent);
    }
  }

  configureUserInfo(): void {
    this.user = this.sessionStore.User;
    this.onBehalfOfUser = this.sessionStore.OnBehalfOfUser;
    this.userGroup = this.sessionStore.OnBehalfOfUserGroup ?? this.sessionStore.UserGroup;
    this.logo = this.determineUserLogo();
    this.userApiService.getUsersForDelegateUser(this.user.Id).subscribe(res => {
      this.delegates = res;
      this.delegateId = this.permissionService.getUserDelegateId();
      if (this.delegateId !== null && this.onBehalfOfUser === null) {
        const delegate = this.delegates.find(d => d.Id === this.delegateId);
        this.onBehalfOfUser = delegate === undefined ? null : delegate.OnBehalfOfUser;
      }
      this.selectedUserId = this.delegateId ? this.delegateId : this.onBehalfOfUser ? this.onBehalfOfUser.Id : 1;
    });
  }

  getLogo(): string | null {
    return this.logo;
  }

  determineUserLogo(): string | null {
    return this.sessionStore.UserLogo;
  }

  getCPCs(): void {
    this.cpcApiService.getUserCPCs(this.cpcStartDate, this.cpcEndDate)
      .subscribe(res => {
        this.userCPCs = res;
      });
  }

  getMarketingDollars(): void {
    this.marketingDollarApiService.getUserMarketingDollars()
      .subscribe(res => {
        this.userMarketingDollars = res;
      });
  }

  // TODO: Mark for delete
  configureLayOut(): void {
    this.breakpointObserver.observe([
      Breakpoints.TabletLandscape,
      Breakpoints.WebLandscape
    ]).subscribe(result => {
      if (result.matches) {
        this.mediaMobile = false;
        this.portalSidenavConfig.IsOpened = true;
        this.portalSidenavConfig.Mode = 'side';
        this.userSidenavConfig.Mode = 'over';
      } else {
        this.mediaMobile = true;
        this.portalSidenavConfig.IsOpened = false;
        this.portalSidenavConfig.Mode = 'push';
        this.userSidenavConfig.Mode = 'push';
      }
    }, () => {
      console.log('error');
    });
  }

  runOnboardingCheck(resolve: OnboardingModel): void {
    const onboardingStepModel = this.onboardingService.getRequiredOnboardingSteps(resolve);
    if (onboardingStepModel.RequiredSteps.length > 0) {
      this.router.navigate(['/Onboarding/Welcome'], { queryParams: { requiredSteps: encodeURIComponent(JSON.stringify(onboardingStepModel.RequiredSteps)), alternateView: onboardingStepModel.ShowAlternateView } });
    } else {
      this.hasCompletedOnboardingCheck = true;
    }
  }

  setCanSeeIllustrationButton(): boolean {
    if (environment.qa) return true;
    else return false;

    // let hasOptedIn = this.hasOptedIn;
    // let hasMarketPlaceAnnuityPermission = this.permissionService.hasOnePermission('MarketplaceAnnuity:View');
    // if (!hasOptedIn || !hasMarketPlaceAnnuityPermission) return;

    // this.canSeeIllustrationButton = this.sessionStore.OnBehalfOfUser ? !!this.sessionStore.OnBehalfOfUser.AgentLicenses.Npn : !!this.sessionStore.User.AgentLicenses.Npn;
  }

  activateFirelightSso(): void {
    this.ssoService.firelightSso();
  }

  activateIpipelineSso(): void {
    this.ssoService.iPipelineEAppsSso();
  }

  activateAspidaSso(): void {
    this.ssoService.aspidaSso();
  }

  getState(outlet: RouterOutlet): string | unknown | null {
    const ret = outlet.activatedRoute.component;
    return ret;
  }

  logout(): void {
    this.dialog.open<ConfirmationComponent, ConfirmationData>(ConfirmationComponent, {
      data: {
        message: 'Are you sure you want to log out?',
        isWarning: true,
        confirmationButtonText: 'Log Out'
      }
    }).afterClosed().subscribe(res => {
      if (res) {
        this.authService.logout();
      } else return;
    });
  }

  /**Get the end date that will be used to calculate the agent CPCs from the first of the year till today
   * We're setting the end date to be today + 1 since ARC reporting counts it as less than tomorrow
   * @return Date - today, but limited to 0 hr so that values don't change as the time changes during a day | 11/2023
   */
  getCPCMaxEndDate(): Date {
    const today = new Date();
    return new Date(today.getUTCFullYear(), today.getMonth(), today.getDate() + 1);
  }

  getCPCStartDate(): Date {
    const today = new Date();
    const dayOfMonth = today.getDate();
    const monthOfYear = today.getMonth();
    let startDate = null;
    if (dayOfMonth >= 1 && dayOfMonth <= 15 && monthOfYear <= 1) {
      startDate = new Date(new Date().getFullYear() - 1, 0, 1);
    } else {
      startDate = new Date(new Date().getFullYear(), 0, 1);
    }

    return startDate;
  }

  getUserCPCs(): void {
    this.cpcApiService.getUserCPCs(this.cpcStartDate, this.cpcEndDate)
      .subscribe(res => {
        this.userCPCs = res;
      });
  }

  // TODO: Mark for delete
  openAlert(): void {
    this.dialog.open(ConfirmationComponent, {
      data: {
        title: 'Alert', //optional
        message: 'Due to a nationwide outage by our host provider, some resources in the Agent Portal may be negatively impacted.',
        confirmationButtonText: 'close', //optional
        isWarning: true, //optional
        hideCancelButton: true, //optional
      }
    });
  }

  openLeaderboardModal(): void {
    this.dialog.open(LeaderboardComponent, {
      height: '74rem',
    });
  }

  openPaperApplicationModal(): void {
    this.dialog.open(SubmitPaperApplicationV2Component, {
      height: '74rem',
    });
  }

  openRedeemMarketingDollars(): void {
    this.dialog.open(RedeemMarketingDollarsComponent, {
      height: '74rem',
    });
  }

  openEAppGuide(): void {
    window.open('https://www.figmarketing.com/files/resources/EApp_Quick_Start_Guide.pdf');
  }

  openSupportModal(): void {
    this.dialog.open(SupportComponent, {
      height: '74rem',
    });
  }

  openMarketingExpenseHistoryModal(): void {
    this.dialog.open(MarketingExpenseHistoryComponent, {
      data: {
        marketingExpenseHistory: this.userMarketingDollars ? this.userMarketingDollars.Details : []
      },
      height: '74rem',
    });
  }

  openStartIllustrationModal(): void {
    this.dialog.open(StartIllustrationComponent);
  }

  openStartEAppModal(): void {
    this.dialog.open<StartApplicationFormComponent, { isModalView: boolean }>(StartApplicationFormComponent, {
      data: {
        isModalView: true,
      }
    });
  }

  // TODO: Mark for delete
  redirectToIcomplyLite(): void {
    this.ssoService.iComplyLiteSso();
  }

  // TODO: Mark for delete
  redirectToMarketplaceOne(): void {
    this.ssoService.marketPlaceOneSsoV2();
  }

  selectMeAsUser(): void {
    this.delegateId = null;
    this.viewingUser = this.user;
    this.permissionService.removeStoredOnBehalfOfUserId();
    window.location.href = '/Portal';
  }

  selectOnBehalfOfUser(delegateId: string): void {
    const delegate = this.delegates.find((row) => row.Id === delegateId);
    if (delegate) {
      this.viewingUser = delegate.User;
      this.delegateId = delegateId;
      this.permissionService.setOnBehalfOfUserId(delegate.OnBehalfOfUserId);
      this.permissionService.setUserDelegateId(delegate.Id);
      window.location.href = '/Portal';
    }
    return;
  }

  selectActiveUser(id: string | 1): void {
    this.sessionStore.clearSessionStoreLocalStorage();
    if (id === 1) this.selectMeAsUser();
    else this.selectOnBehalfOfUser(id);
  }

  togglePortalSidenav(): void {
    if (this.mediaMobile) {
      this.portalSidenav.toggle();
      if (this.userSidenav.opened) this.userSidenav.close();
    }
  }

  toggleUserSidenav(): void {
    this.userSidenav.toggle();
    if (this.mediaMobile) {
      if (this.portalSidenav.opened) this.portalSidenav.close();
    }
  }

  openUploadLogoModal(): void {
    const ref = this.dialog.open(UserLogoUploadComponent, {
      data: {
        logo: this.logo
      },
      minHeight: '60rem',
    });
    ref.afterClosed().subscribe(r => {
      if (!!r && r.refresh) {
        this.user.HasUserLogo = r.hasPhoto;
        this.clockTick = Date.now();
        this.logo = this.determineUserLogo();
      }
    });
  }

  checkForRenewals(): void {
    this.enrollmentFormApiService.getSurveyRenewals(null).subscribe(result => {
      this.renewals = result;
      // 1 === 'Open', 4 === 'Ignored'
      if (result.some(r => r.SurveyRenewalStatusId === renewalStatusEnum.Ignored)) this.userHasOpenRenewals = true;
      if (result.some(r => r.SurveyRenewalStatusId === renewalStatusEnum.Open)) this.openEnrollmentModal(result, true);
    });
  }

  openEnrollmentModal(renewals: SurveyRenewalModel[], disableClose = false): void {
    if (this.renewals)
      this.dialog.open(RenewalFormComponent, {
        width: '72rem',
        disableClose: disableClose,
        data: {
          SurveyRenewals: renewals,
          FilterBySurveyId: null
        }
      }).afterClosed().subscribe(() => {
        this.checkForRenewals();
      });
  }

  openManageIntegrationsModal(): void {
    this.dialog.open<ManageIntegrationsModalComponent, ManageIntegrationsEnum, boolean>(ManageIntegrationsModalComponent, {
      data: ManageIntegrationsEnum.Envestnet
    });
  }
}
