import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { fadeIn } from 'src/app/animations';
import { PermissionService } from 'src/app/core/services/auth/permission.service';

@Component({
  selector: 'app-carriers-training',
  templateUrl: './carriers-training.component.html',
  animations: [fadeIn],
  standalone: false
})
export class CarriersTrainingComponent implements OnInit {
  resourceUrl = '';
  showAppointments = true;

  constructor(
    public permissionService: PermissionService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.resourceUrl = this.route.snapshot.data.title as string;
    this.showAppointments = this.permissionService.hasAllPermissions('Reports:Contracting'); //expand Get Contracted and General Training if no Appointments reporting permission
  }
}
