import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FeatureFlagModel } from 'src/app/shared/models/feature-flag.model';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagApiService {

  constructor(private http: HttpClient) { }

  /**
   * Return the combined feature flag obj from the 2 calls above
   * @param feature Flag ID. Ex: `show_chatbot`
   * @returns `{Enabled: boolean; FlagValue: string}`
   */
  getFlag(feature: string): Observable<FeatureFlagModel> {
    return this.http.get<FeatureFlagModel>(`/api/FeatureFlag/Flags/${feature}`);
  }

  getAllFlags(): Observable<FeatureFlagModel[]> {
    return this.http.get<FeatureFlagModel[]>('/api/FeatureFlag/Flags');
  }
}