import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { fadeIn } from 'src/app/animations';
import { EAppService } from 'src/app/core/services/e-app.service';
import { EAppWorkflowStoreService } from 'src/app/core/services/stores/e-app-workflow-store.service';
import { EAppErrorType, ExternalVendorProduct } from 'src/app/shared/models/eapps.models';
import { CreateActivityBasicResponse } from 'src/app/shared/models/firelightApi.models';
import { PaperApplicationMatDialogData } from 'src/app/shared/models/paper-application.model';
import { SubmitPaperApplicationV2Component } from '../submit-paper-application-v2/submit-paper-application-v2.component';

@Component({
  animations: [fadeIn],
  selector: 'app-eapp-workflow-start-application',
  templateUrl: './eapp-workflow-start-application.component.html',
  standalone: false
})
export class EAppWorkflowStartApplicationComponent implements OnInit {
  iPipelineId = 4;
  firelightId = 1;
  isStartingApplication!: boolean;
  isSkippingTraining = false;
  errorMessage!: string;
  product!: ExternalVendorProduct | null;

  constructor(private dialog: MatDialog,
    private eAppStore: EAppWorkflowStoreService,
    private eAppService: EAppService) { }

  ngOnInit(): void {
    this.product = this.eAppStore.Product;
    if (!this.product?.ProductCusip) this.errorMessage = EAppErrorType.ProductNotAvailableForEappSubmissionError;
  }

  skipTraining(): void {
    this.isSkippingTraining = true;
    if (this.eAppStore.ActivityId) {
      this.eAppService.goToFirelight(this.eAppStore.ActivityId, { isNew: this.eAppStore.IsNew });
      this.dialog.closeAll();
    }
  }

  goBackToTraining(): void {
    this.dialog.closeAll();
  }

  openPaperApp(): void {
    this.dialog.open<SubmitPaperApplicationV2Component, PaperApplicationMatDialogData, boolean>(SubmitPaperApplicationV2Component, {
      data: {
        fromWorkflow: true
      }
    });
  }

  startEApplication(): void {
    if (this.eAppStore.Product) {
      if (this.eAppStore.Product.ExternalVendorID === this.firelightId && this.eAppStore.Product.ProductCusip) this.startFirelightEApplication();
      else if (this.eAppStore.Product.ExternalVendorID === this.iPipelineId && this.eAppStore.Product.ProductCusip) this.startIPipelineEApplication();
      else this.errorMessage = EAppErrorType.ProductNotAvailableForEappSubmissionError;
    } else {
      this.errorMessage = EAppErrorType.StartApplicationError;
    }
  }

  startFirelightEApplication(): void {
    this.isStartingApplication = true;
    if (this.eAppStore.Product && this.eAppStore.State && this.eAppStore.ClientFirstName && this.eAppStore.ClientLastName) {
      this.eAppService.startFirelightEApplication(this.eAppStore.Product, this.eAppStore.State, this.eAppStore.RedtailContact, this.eAppStore.ClientFirstName, this.eAppStore.ClientLastName)
        .subscribe({
          next: (activity: CreateActivityBasicResponse) => {
            this.eAppService.goToFirelight(activity.ActivityId, { isNew: this.eAppStore.IsNew });
            this.isStartingApplication = true;
            this.dialog.closeAll();
          },
          error: () => {
            this.isStartingApplication = false;
            this.errorMessage = EAppErrorType.StartApplicationError;
          }
        });
    } else {
      this.errorMessage = EAppErrorType.StartApplicationError;
    }
  }

  startIPipelineEApplication(): void {
    if (this.eAppStore.State && this.eAppStore.Product) {
      this.eAppService.startiPipelineEApplication(this.eAppStore.State.ShortName, this.eAppStore.Product.ProductCusip, this.eAppStore.Product.ExternalVendorProductTypeID.toString());
    } else {
      this.errorMessage = EAppErrorType.StartApplicationError;
    }
  }
}
