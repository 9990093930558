import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import orderBy from 'lodash-es/orderBy';
import { LodashSortOrder } from 'src/app/shared/constants/lodash-sort-order.constant';
import { TaskStatusEnum } from '../../enums/policy-v2.enums';
import { TaskNoteV2 } from '../../models/policy-v2.models';
import { UpdateHistoryConfigModel } from './models/updates-history-config.model';

@Component({
  selector: 'app-updates-history',
  templateUrl: './updates-history.component.html',
  styles: [
  ]
})
export class UpdatesHistoryComponent implements OnInit, AfterViewInit {
  taskStatusId = TaskStatusEnum;
  noteThread: TaskNoteV2[] = [];
  newNotes: TaskNoteV2[] = [];
  receivedTooltip = 'Your submission has been received by your operation specialist. You will receive a response within 24 hours, if applicable';

  constructor(
    private dialogRef: MatDialogRef<UpdatesHistoryComponent, TaskNoteV2[]>,
    @Inject(MAT_DIALOG_DATA) public config?: UpdateHistoryConfigModel,
  ) { }

  ngOnInit(): void {
    this.createHistoryViewData();
  }

  ngAfterViewInit(): void {
    this.updateDialogProp();
  }

  updateDialogProp(): void {
    this.dialogRef.updateSize('64rem', '76rem');
  }

  /**
   * If there have been new messages/uploads from agents, pass them back out so the parent UI knows to update the list entries
   */
  closeDialog(): void {
    const newNotes = this.noteThread.filter(note => note.IsNew);
    if (newNotes?.length) this.dialogRef.close(newNotes);
    else this.dialogRef.close(undefined);
  }

  updateThreadUI(uploadEvent?: TaskNoteV2): void {
    if (!this.noteThread.length || !uploadEvent) return;
    this.noteThread.unshift(uploadEvent);
  }

  createHistoryViewData(): void {
    if (!this.config?.MainTaskId) return;
    const createdOn = 'CreatedOn';
    this.noteThread = orderBy(this.config?.Notes ?? [], createdOn, LodashSortOrder.desc);
  }
}