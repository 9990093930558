<div class="flex flex-wrap flex-grow integrations" [ngClass]="{'h-full': data?.isModalView, 'card': !data?.isModalView}">
  @if (!data?.isModalView) {
    <div class="flex flex-col items-center justify-center w-full md:w-1/2 bg-blue-lightest p-30 md:order-2">
      <span class="m-20 h-256 w-256" @fadeIn>
        <img [ngClass]="{'hidden': isLoading}" src="./assets/images/can-sell-v2.svg"
          class="w-full h-full" alt="Can sell image" />
        <img [ngClass]="{'hidden': !isLoading}" src="./assets/images/can-sell-v2-animate.svg"
          class="w-full h-full" alt="Can sell image" />
      </span>
      <p class="flex flex-col m-20 mt-0 font-bold text-center md:text-lg" @fadeIn>
        <span>Welcome to the E-Application Wizard.</span>
      </p>
    </div>
  }
  <form [formGroup]="startEAppForm" (ngSubmit)="submit()" @fadeIn class="flex flex-col flex-grow w-full h-full overflow-hidden md:w-1/2 md:order-1" autocomplete="off">
    @if (!isLoading) {
      <div class="flex items-center flex-none border-b p-30 gap-x-20 border-gray-lighter">
        @if (data?.isModalView) {
          <h1 class="mr-auto text-lg font-bold">Start E-Application</h1>
        }
        <app-integrations-menu (integrationsChanged)="updateIntegrations($event)" tourAnchor="integrations1"></app-integrations-menu>
        @if (data?.isModalView) {
          <button type="button" mat-button class="icon-button is-inverted is-primary" mat-dialog-close matTooltip="Close dialog">
            <i class="material-icons">close</i>
          </button>
        }
      </div>
      <div class="grid grid-cols-2 overflow-auto p-30 gap-x-20 gap-y-20">
        @if (startEAppForm.controls.clientName.enabled) {
          <div class="grid grid-cols-2 gap-x-20 gap-y-5 col-span-full" formGroupName="clientName">
            <div class="flex flex-col col-span-1 gap-y-5">
              <label for="firstName" class="text-sm font-semibold text-gray">Client First Name</label>
              <input formControlName="firstName" type="text" class="input" />
            </div>
            <div class="flex flex-col col-span-1 gap-y-5">
              <label for="lastName" class="text-sm font-semibold text-gray">Client Last Name</label>
              <input formControlName="lastName" type="text" class="input" />
            </div>
            @if (startEAppForm.controls.clientName.touched && startEAppForm.controls.clientName.invalid) {
              <span class="text-xs text-red col-span-full">These fields are required</span>
            }
          </div>
        }
        @if (startEAppForm.controls.contact.enabled) {
          <div class="flex flex-col col-span-full gap-y-5">
            <span class="text-sm font-semibold label text-gray">Client Name</span>
            <app-contact-search-form [parentCtrl]="startEAppForm.controls.contact" [connectedCrms]="connectedCrms" class="block" (switchToManualClientInput)="switchToManualClientInput($event)"></app-contact-search-form>
          </div>
        }
        <div class="flex flex-col col-span-full gap-y-5">
          <label for="lob" class="text-sm font-semibold text-gray">Line Of Business</label>
          <mat-radio-group class="flex flex-wrap gap-x-10"
            formControlName="lob"
            aria-label="Select an option">
            @for (lob of linesOfBusiness; track lob; let index = $index) {
              <mat-radio-button class="md:w-full" [value]="lob" required>
              {{lob.Name}}</mat-radio-button>
            }
          </mat-radio-group>
        </div>
        <app-autocomplete-search class="flex flex-col col-span-full" [formCtrl]="startEAppForm.controls.state" [label]="'State'" [displayProperty]="'LongName'" [items]="autocompleteStates"></app-autocomplete-search>
        <app-autocomplete-search class="flex flex-col col-span-full" [formCtrl]="startEAppForm.controls.carrier" [label]="'Carrier'" [displayProperty]="'Business'" [items]="autocompleteCarriers"></app-autocomplete-search>
        <app-autocomplete-search class="flex flex-col col-span-full" [formCtrl]="startEAppForm.controls.product" [label]="'Product'" [displayProperty]="'Product'" [items]="autocompleteProducts"></app-autocomplete-search>
      </div>
      <div class="flex items-center flex-none mt-auto border-t p-30 gap-x-20 border-gray-lighter">
        @if (selectedProductType === externalVendorProductType.paperApp) {
          <app-message-notification class="ml-0 mr-auto">This product only supports paper applications</app-message-notification>
        }
        <button type="submit" mat-button class="ml-auto button is-primary" [disabled]="isSubmitting">
          <span class="flex items-center justify-center gap-x-5">
            @if (!isSubmitting) {
              <span>Start Application</span>
            }@else {
              <span>Starting Application...</span>
            }
            <i class="ml-5 material-icons mi-18">send</i>
          </span>
        </button>
      </div>
    }@else {
      <div class="p-20 m-auto loader-sm is-blue"></div>
    }
  </form>
</div>