import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { Subscription } from 'rxjs';
import { fadeIn } from 'src/app/animations';
import { PermissionService } from 'src/app/core/services/auth/permission.service';
import { ManageIntegrationsEnum } from 'src/app/shared/enums/integrations.enum';
import { sidenavConfig } from 'src/app/shared/models/sidenav-config.model';

@Component({
  animations: [fadeIn],
  selector: 'app-manage-integrations-modal',
  templateUrl: './manage-integrations-modal.component.html'
})
export class ManageIntegrationsModalComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    private breakpointObserver: BreakpointObserver,
    private dialogRef: MatDialogRef<ManageIntegrationsModalComponent>,
    private permissionService: PermissionService,
    @Inject(MAT_DIALOG_DATA) public data?: ManageIntegrationsEnum,
  ) { }
  mediaMobile = false;
  integrationComponents: IntegrationsComponent[] = [
    {
      Id: ManageIntegrationsEnum.Redtail,
      Name: 'Redtail',
      Img: 'redtail-icon.svg',
    },
    {
      Id: ManageIntegrationsEnum.Salesforce,
      Name: 'Salesforce',
      Img: 'salesforce-icon.svg',
    },
    {
      Id: ManageIntegrationsEnum.Wealthbox,
      Name: 'Wealthbox',
      Img: 'wealthbox-icon.svg',
    },
  ];
  selectedIntegration?: IntegrationsComponent;
  manageIntegrationsConfig: sidenavConfig = {
    IsOpened: true,
    Mode: 'side',
  };
  @ViewChild('manageIntegrationsSidenav') manageIntegrationsSidenav?: MatSidenav;
  breakPointSubscription: Subscription = new Subscription();
  integrationId = ManageIntegrationsEnum;

  ngOnInit(): void {
    this.setupDefaultTab();
    this.configureLayOut();
  }

  ngOnDestroy(): void {
    this.breakPointSubscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    const width = '72rem',
      height = '60rem';
    this.dialogRef.updateSize(width, height);
  }

  configureLayOut(): void {
    this.breakPointSubscription = this.breakpointObserver.observe([
      Breakpoints.TabletLandscape,
      Breakpoints.WebLandscape
    ]).subscribe({
      next: result => {
        if (result.matches) {
          this.mediaMobile = false;
          this.manageIntegrationsConfig.Mode = 'side';
        } else {
          this.mediaMobile = true;
          this.manageIntegrationsConfig.Mode = 'push';
        }
      }
    });
  }

  setupDefaultTab(): void {
    this.integrationComponents = this.integrationComponents.filter(component => (!component.Permissions || this.permissionService.hasAllPermissions(component.Permissions)));
    this.manageIntegrationsConfig.IsOpened = !this.integrationComponents.length ? false : this.manageIntegrationsConfig.IsOpened;
    const tab = this.integrationComponents.find(x => x.Id === this.data) || this.integrationComponents[0];
    this.selectIntegration(tab);
  }

  selectIntegration(integration?: IntegrationsComponent | undefined): void {
    this.selectedIntegration = integration;
    this.data = this.integrationComponents.find(x => x.Id === integration.Id).Id;
  }

  toggleUserSidenav(): void {
    if (this.manageIntegrationsSidenav) this.manageIntegrationsSidenav.toggle();
  }
}

export interface IntegrationsComponent {
  Id: ManageIntegrationsEnum;
  Name: string;
  Img?: string;
  Permissions?: string;
  FeatureFlag?: string;
}