import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MarketplaceOneApiService {

  constructor(private http:HttpClient) { }

  getToken(): Observable<string>{
    return this.http.get<string>('/api/MarketplaceOne/Token', {responseType: 'text' as 'json'});
  }

  getTokenV2(): Observable<string>{
    return this.http.get<string>('/api/MarketplaceOne/TokenV2', {responseType: 'text' as 'json'});
  }
}
