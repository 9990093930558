import { Component } from '@angular/core';

@Component({
  selector: 'app-stage-history-timeline',
  templateUrl: './stage-history-timeline.component.html',
  styles: [],
  standalone: false
})
export class StageHistoryTimelineComponent {

  constructor() { }
}
