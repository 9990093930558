import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable} from 'rxjs';
import {SureLcSsoUser} from '../../../shared/models/sso.models';
import { iGoApplication } from 'src/app/shared/models/iGo-application.models';

@Injectable({
  providedIn: 'root'
})
export class SsoApiService {

  constructor(private http:HttpClient) { }

  private xmlResponseType = {responseType: 'text' as 'json'};

  getSureLcUser(): Observable<SureLcSsoUser>{
    return this.http.get<SureLcSsoUser>('/api/SureLcSso/User');
  }

  initiateAnnuityRateWatchSso():Observable<string>{
    return this.http.post<string>('/api/AnnuityRateWatchSso', {});
  }

  initiateAnnexusSso():Observable<string>{
    return this.http.post<string>('/api/AnnexusSso', {}, this.xmlResponseType);
  }

  initiateCannexSso():Observable<string>{
    return this.http.post<string>('/api/CannexSso', {}, this.xmlResponseType);
  }

  // TODO: Double-check V2
  initiateEnsightSso():Observable<string>{
    return this.http.post<string>('/api/EnsightSso', {}, this.xmlResponseType);
  }

  initiateFirelightSso(activityId?:string):Observable<string>{
    return this.http.post<string>(`/api/FirelightSso?activityId=${activityId}`, {}, this.xmlResponseType);
  }

  initiateIPipelineEappsSso(appModel: iGoApplication|null):Observable<string>{
    let model = {};
    if (appModel !== null) model = appModel;
    return this.http.post<string>('/api/IpipelineSso/EApps', model, this.xmlResponseType);
  }

  initiateIPipelineSso(relayStateId:string):Observable<string>{
    return this.http.post<string>(`/api/IpipelineSso/Resources/${relayStateId}`, {}, this.xmlResponseType);
  }

  initiateLtcSelectSso():Observable<string>{
    return this.http.post<string>('/api/LtcSelectSso', {}, this.xmlResponseType);
  }

  initiateMapleLifeCalculatorSso():Observable<string>{
    return this.http.post<string>('/api/MapleLifeSsoV2', {}, this.xmlResponseType);
  }

  initiateOfficeDepotSso():Observable<string>{
    return this.http.post<string>('/api/OfficeDepotSso', {}, this.xmlResponseType);
  }

  initiatePortalSso(accessToken:string):Observable<void>{
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${accessToken}`
    });
    return this.http.post<void>('/api/Login/SwitchAuth', {}, {headers: headers});
  }

  initiatePowerOfZeroSso():Observable<string>{
    return this.http.post<string>('/api/PowerOfZeroSso/Token', {}, this.xmlResponseType);
  }

  initiateRetireUpSso():Observable<string>{
    return this.http.post<string>('/api/RetireUpSso', {}, this.xmlResponseType);
  }

  initiateRightBridgeSso():Observable<string>{
    return this.http.post<string>('/api/RightBridgeSso', {}, this.xmlResponseType);
  }

  initiateSureLcSso(lastName:string):Observable<string>{
    return this.http.post<string>(`/api/SureLcSso?ln=${lastName}`, {}, this.xmlResponseType);
  }

  initiateTaylorMethodSso():Observable<string>{
    return this.http.post<string>('/api/TaylorMethodSso', {}, this.xmlResponseType);
  }
}
