import { CarrierTrainingAndContract, WritingNumber } from 'src/app/shared/models/carrier.models';
import { Component, Input, OnInit } from '@angular/core';
import { Contract } from 'src/app/shared/models/contract.models';
import { downloadFile } from 'src/app/shared/helpers/filedownloader.helper';
import { fadeIn, fadeInOut, slideInOut } from 'src/app/animations';
import orderBy from 'lodash-es/orderBy';
import { ReportsApiService } from 'src/app/core/services/http/reports-api.service';
import { environment } from 'src/environments/environment';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { ToastClassEnum } from 'src/app/core/services/snackbar/snackbar.models';
import { strictSearch } from 'src/app/shared/helpers/search.helpers';

@Component({
  selector: 'app-my-carriers',
  templateUrl: './my-carriers.component.html',
  animations: [fadeIn, fadeInOut, slideInOut],
  standalone: false
})
export class MyCarriersComponent implements OnInit {
  environment = environment;
  @Input() cardHeader = 'Carrier Contracts';
  searchText = '';
  toastErrorMessage = 'Sorry, an error has occurred. Please try again.';
  columnName = 'Carrier';
  downloadIncrement = 0;
  exportingList = false;
  loading = true;
  carrierList: CarrierTrainingAndContract[] = [];
  private fullCarrierList: CarrierTrainingAndContract[] = [];
  reverseOrder = false;

  constructor(
    private reportsApiService: ReportsApiService,
    private snackbar: SnackbarService,
  ) {
  }

  ngOnInit(): void {
    this.getMyCarrierList();
  }

  getMyCarrierList(): void {
    this.loading = true;
    this.reportsApiService.getContracts().subscribe({
      next: res => {
        this.mapCarrierContracts(res);
      },
      error: () => {
        this.carrierList = [];
        this.snackbar.openSnackbar(this.toastErrorMessage, ToastClassEnum.warning);
      }
    }).add(() => this.loading = false);

  }

  exportContractReport(): void {
    this.exportingList = false;
    this.reportsApiService.exportContractingReport()
      .subscribe(res => {
        this.exportingList = false;
        downloadFile(res, 'ContractData.xlsx');
      });
  }

  searchFilter(searchText: string): void {
    if (!searchText) this.carrierList = this.fullCarrierList;
    this.carrierList = strictSearch(this.fullCarrierList, searchText, ['Carrier', 'CorpWritingNumbers', 'WritingNumbers', 'LineOfBusinessName']);
  }

  // TODO: Might bring back in Q3/4
  getStateAppointments(carrier: CarrierTrainingAndContract): void {
    if (carrier.ShowDetails) {
      carrier.ShowDetails = false;
      return;
    }

    carrier.ShowDetails = true;
    carrier.GettingDetails = true;

    this.reportsApiService.getContractDetail(carrier.ContractGuid, carrier.LineOfBusinessGuid)
      .subscribe({
        next: res => {
          carrier.ContractDetails = res;
        },
        error: () => {
          this.snackbar.openSnackbar(this.toastErrorMessage, ToastClassEnum.warning);
        }
      }).add(() => carrier.GettingDetails = false);
  }

  orderRows(columnName: string): void {
    if (columnName) {
      this.reverseOrder = (columnName === this.columnName) ? !this.reverseOrder : false;
      this.columnName = columnName;
    } else {
      columnName = this.columnName;
    }
    this.carrierList = orderBy(this.carrierList, columnName, this.reverseOrder ? ['desc'] : ['asc']) as CarrierTrainingAndContract[];
    this.fullCarrierList = orderBy(this.fullCarrierList, columnName, this.reverseOrder ? ['desc'] : ['asc']) as CarrierTrainingAndContract[];
  }

  mapCarrierContracts(contracts: Contract[]): void {
    contracts.forEach(contract => {
      const carrier: CarrierTrainingAndContract = { ...contract, ...{ ContractDetails: null, GettingDetails: false, ShowDetails: false, WritingNumberArray: [] } };

      const indvNumbers: WritingNumber[] = !carrier.WritingNumbers ? [] : carrier.WritingNumbers.split(', ').map(number => {
        return {
          value: number,
          type: 'Individual',
          icon: 'person'
        };
      });
      const corpNumbers: WritingNumber[] = !carrier.CorpWritingNumbers ? [] : carrier.CorpWritingNumbers.split(', ').map(number => {
        return {
          value: number,
          type: 'Corporate',
          icon: 'business'
        };
      });
      carrier.WritingNumberArray = indvNumbers.concat(corpNumbers);
      this.carrierList.push(carrier);
      this.fullCarrierList = this.carrierList;
    });
  }
}
