import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { TextFieldModule } from '@angular/cdk/text-field';
import { ShowIfOneDirective } from './directives/show-if-one.directive';
import { ShowIfAllDirective } from './directives/show-if-all.directive';
import { HideIfOneDirective } from './directives/hide-if-one.directive';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { AmplitudeEventDirective } from './directives/amplitude-event.directive';
import { SimonPoweredComponent } from './components/simon-powered/simon-powered.component';
import { SplitCamelCasePipe } from './pipes/split-camel-case.pipe';
import { CallbackPipe } from './pipes/callback/callback.pipe';
import { InlineSnackbarComponent } from './components/inline-snackbar/inline-snackbar.component';
import { CountdownTimerComponent } from './components/countdown-timer/countdown-timer.component';
import { MessageNotificationPanelComponent } from './components/message-notification/message-notification.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatNativeDateModule } from '@angular/material/core';
import { NgArrayPipesModule, NgPipesModule } from 'ngx-pipes';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShortNumberSuffixPipe } from './pipes/short-number-suffix/short-number-suffix.pipe';
import { RouterModule } from '@angular/router';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ConfirmationComponent } from '../portal/modals/confirmation/confirmation.component';
import { NewFeatureComponent } from './components/new-feature/new-feature.component';
import { DobToAgePipe } from './pipes/dob-to-age.pipe';
import { TrimDecimalPipe } from './pipes/trim-decimal.pipe';
import { CyDirective } from './directives/cy.directive';
import { RedtailSearchFormComponent } from './components/redtail-search-form/redtail-search-form.component';
import { RemoveIfFeatureOffDirective } from './directives/hide-if-feature-flag.directive';
import { ApplicationHistoryTableComponent } from './components/application-history-table/application-history-table.component';
import { StringToCapitalizedPipe } from './pipes/string-to-capitalized.pipe';
import { CompareProductsComponent } from './components/compare-products/compare-products.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { BoldPipe } from './pipes/bold.pipe';
import { NgxCurrencyDirective } from 'ngx-currency';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip'; //matTooltipPosition=[“above”, “below”, “left”, “right”]
import { MatSliderModule } from '@angular/material/slider';
import { ContactSearchFormComponent } from './components/contact-search-form/contact-search-form.component';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { HyphenateCamelCasePipe } from './pipes/hyphenate-camel-case.pipe';


const matImports = [
  MatChipsModule,
  MatAutocompleteModule,
  MatButtonModule,
  MatCheckboxModule,
  MatDialogModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatRadioModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatToolbarModule,
  MatTooltipModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatExpansionModule,
  MatButtonToggleModule,
  FormsModule
];

const coreModules = [
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule
];

const commonPipeModules = [
  CurrencyPipe,
  DatePipe,
];

@NgModule({
  declarations: [
    AmplitudeEventDirective,
    ConfirmationComponent,
    HideIfOneDirective,
    SafeUrlPipe,
    ShowIfAllDirective,
    ShowIfOneDirective,
    SimonPoweredComponent,
    SplitCamelCasePipe,
    HyphenateCamelCasePipe,
    CallbackPipe,
    InlineSnackbarComponent,
    CountdownTimerComponent,
    MessageNotificationPanelComponent,
    ShortNumberSuffixPipe,
    NewFeatureComponent,
    DobToAgePipe,
    TrimDecimalPipe,
    CyDirective,
    RedtailSearchFormComponent,
    RemoveIfFeatureOffDirective,
    ApplicationHistoryTableComponent,
    StringToCapitalizedPipe,
    CompareProductsComponent,
    BoldPipe,
    ContactSearchFormComponent,
  ],
  imports: [
    ...coreModules,
    ...matImports,
    ...commonPipeModules,
    NgxCurrencyDirective,
    NgPipesModule,
    ScrollingModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    }),
    TourMatMenuModule,
  ],
  providers: [
    SplitCamelCasePipe,
    HyphenateCamelCasePipe,
    DobToAgePipe,
    StringToCapitalizedPipe,
    BoldPipe,
  ],
  exports: [
    ...coreModules,
    ...matImports,
    ...commonPipeModules,
    TextFieldModule,
    NgArrayPipesModule,
    AmplitudeEventDirective,
    HideIfOneDirective,
    SafeUrlPipe,
    ShowIfAllDirective,
    ShowIfOneDirective,
    SimonPoweredComponent,
    SplitCamelCasePipe,
    HyphenateCamelCasePipe,
    CallbackPipe,
    InlineSnackbarComponent,
    CountdownTimerComponent,
    MessageNotificationPanelComponent,
    ShortNumberSuffixPipe,
    ScrollingModule,
    ConfirmationComponent,
    NewFeatureComponent,
    DobToAgePipe,
    TrimDecimalPipe,
    CyDirective,
    RedtailSearchFormComponent,
    ApplicationHistoryTableComponent,
    RemoveIfFeatureOffDirective,
    StringToCapitalizedPipe,
    NgxEchartsModule,
    BoldPipe,
    NgPipesModule,
    NgxCurrencyDirective,
    ContactSearchFormComponent,
    TourMatMenuModule //https://hakimio.github.io/ngx-ui-tour/md-menu
  ]
})
export class SharedModule { }
