export const DOBToAgeHelper = {
  /**Pipe for converting DOB to age using todays date
   * @param dob as string, null or undefined
   * @return age as number or undefined
  */
  convertToAge: (dob: Date | null | undefined): number | undefined => {
    if (!dob) return undefined;
    else {
      const today = new Date();
      const birthDate = new Date(dob);
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    }
  }
};