<form class="w-full h-full" [formGroup]="paperAppForm" (ngSubmit)="onSubmit(paperAppForm)">
  <section class="flex flex-col w-full h-full overflow-hidden">
    <header class="items-center flex-none border-b border-gray-lighter p-30">
      <span class="text-lg font-bold">Upload Paper Application</span>
      <button mat-button
        matTooltip="Close dialog"
        type="button"
        [mat-dialog-close]="isSuccess"
        class="ml-auto icon-button is-primary is-inverted"><i class="material-icons">close</i></button>
    </header>
    <section class="flex flex-col h-full overflow-auto p-30 gap-y-20 dialog-body">
      <!-- Loading -->
      @if (isSubmitting || isLoading) {
        <div class="relative m-auto">
          <div class="loader-wrap">
            <div class="loader-sm is-blue"></div>
          </div>
          @if (isSubmitting) {
            <p class="m-10 text-base leading-loose text-center">Uploading application. This may take a moment...</p>
          }
        </div>
      }
      <!-- Success -->
      @if (isSuccess && !isSubmitting && !isError) {
        <div [@fadeIn] class="flex flex-col items-center my-auto text-lg font-bold text-center"
          >
          <span class="flex items-center justify-center w-64 h-64 m-10 rounded-full bg-green-lightest text-green">
            <i class="material-icons mi-36">verified</i>
          </span>
          <p class="m-10 leading-loose text-center">Application Uploaded Successfully!</p>
          <p class="m-10 text-base leading-loose">
            Click <button type="button" class="no-underline cursor-pointer text-blue" (click)="reset()">here</button> to upload a new application. <br />
          </p>
        </div>
      }
      <!-- Error -->
      @if (isError && !isSubmitting && !isSuccess) {
        <app-message-notification class="w-full my-auto" [panelConfig]="{type: 'error'}">
          Error uploading Application. <button type="button" class="no-underline cursor-pointer text-blue" (click)="reset()">Click here</button> to try to upload a new application.
        </app-message-notification>
      }
      @if (!isSubmitting && !isSuccess && !isError && !isLoading) {
        <section class="flex flex-col">
          <p class="mb-20">
            To submit a paper application to FIG for processing, please complete the information below and upload the application.
          </p>
          <p class="mb-20 text-orange">
            Please only include one application per upload.
          </p>
          @if (data?.fromWorkflow) {
            <app-message-notification [panelConfig]="panelConfig" class="w-full mb-20">{{workflowMessage}}</app-message-notification>
          }
          <section class="flex flex-col gap-y-20" name="paperApp">
            <div class="flex flex-col flex-wrap w-full md:flex-row">
              <div class="flex w-full" flex="100">
                <div class="flex flex-col flex-grow">
                  <label for="notes" class="mb-10 text-sm font-semibold text-gray">Notes &amp; Memos <span
                  class="font-normal">(optional)</span></label>
                  <textarea formControlName="Notes" class="px-10 py-5 textarea" name="notes" rows="4"
                    placeholder="Provide any additional information regarding the application"
                  aria-label="Notes input"></textarea>
                </div>
              </div>
            </div>
            <div class="flex flex-col flex-grow">
              <label for="fileUpload" class="mb-10 text-sm font-semibold text-gray required-label">Upload Policy Documents</label>
              <div class="relative flex flex-col items-center justify-center p-20 text-center transition duration-150 border-dashed rounded cursor-pointer border-3 bg-gray-lightest border-gray-light min-h-128 hover:text-blue hover:border-blue text-gray"
                [class]="dragover? 'border-blue text-blue':''">
                <input class="absolute top-0 left-0 w-full h-full opacity-0" accept=".pdf, .png, .jpg, .jpeg" name="fileUpload"
                  (dragover)="dragOverHandler($event)" type="file" multiple id="file"
                  (change)="uploadFiles($event)">
                <span class="mb-10 text-3xl material-icons-outlined"> file_upload </span>
                <span class="text-sm font-semibold label">Click or drag a file here to upload the paper application:</span>
                <span class="text-sm font-bold label">Please only include one application per upload.</span>
                <span class="text-sm font-semibold label">(PDF, PNG, JPEG. Max Size 30MB)</span>
              </div>
            </div>
            @if (uploadingFiles.length) {
              <div class="flex flex-wrap flex-none">
                @for (file of uploadingFiles; track file) {
                  <div class="w-full p-5 sm:w-1/2">
                    <div class="flex items-center px-10 py-5 rounded hover:bg-gray-lightest">
                      <p class="mr-10 text-xs">{{file.name}}</p>
                      <button class="ml-auto icon-button is-warning is-inverted is-sm" aria-label="Delete file button"
                        type="button" (click)="removeFile(file)">
                        <span class="material-icons mi-18">close</span>
                      </button>
                    </div>
                  </div>
                }
              </div>
            }
            <div class="mt-5 text-sm text-red" [hidden]="!(removedAFile && uploadingFiles.length === 0)">
              Please Upload a File
            </div>
          </section>
        </section>
      }
    </section>
    <footer class="footer p-30">
      <button mat-button class="ml-auto button is-primary is-lg" type="submit"
        name="submitApplication" aria-label="Submit Paper Application"
        [disabled]="!paperAppForm.valid || uploadingFiles.length === 0 || isSubmitting || isUploading || isSuccess || isError">
        <span class="flex items-center justify-center">
          <span>Submit{{ isUploading || isSubmitting ? 'ting' : ''}} Paper Application</span>
          <i class="ml-5 material-icons mi-18">send</i>
        </span>
      </button>
    </footer>
  </section>
</form>