<section class="flex w-full h-full mx-auto max-w-1440 p-30" @fadeIn>
    <div class="flex flex-col flex-grow w-auto h-full overflow-auto card">
        <div
             class="flex flex-row items-center justify-start p-10 border-b border-solid lg:p-20 border-gray-lighter">
            <span class="m-10 mr-0 material-icons mi-24 text-blue-dark" aria-label="Calculator">calculate</span>
            <div class="h-24 m-10 mr-20">
                <h2 class="text-lg font-bold">Annuity Rate Watch - Lifetime Income Tool</h2>
            </div>
        </div>
        <div class="flex flex-col items-center p-20 md:p-30">
            <img class="h-auto mx-auto mb-20 w-400 md:mb-40"
                 src="./assets/images/logos/annuity-rate-watch-logo_lg.png" alt="ARW logo" @fadeIn>
            <iframe class="w-full mb-20 h-1/2 min-h-300 md:mb-40" [src]="iframeUrl | safeUrl" @fadeIn></iframe>
        </div>
    </div>
</section>