import { Component } from '@angular/core';

@Component({
  selector: 'app-elevate',
  templateUrl: './elevate.component.html',
  standalone: false
})
export class ElevateComponent {

  constructor() { }

}
