import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
/**
 * Takes in an array of items and autocomplete search throught list items
 */
@Component({
  selector: 'app-autocomplete-search',
  templateUrl: './autocomplete-search.component.html'
})

export class AutocompleteSearchComponent<T1, T2 extends keyof T1, T3> {

  /** array of autocomplete items */
  @Input() items: T1[] = [];
  /** label for input */
  @Input() label?: string;
  /** Form control passed in from parent
   * TODO: We could probably move some of the actual autocomplete logic based on valueChanges here to declutter the parent form
   */
  @Input() formCtrl?: FormControl<T3>;
  /** display property that's used in the autocomplete & also used to sort the results */
  @Input() displayProperty?: T2;
  labelLower = '';
  constructor(){
    this.labelLower = this.label?.toLowerCase();
  }

  /**
   * Returns the string display value when the selected option is an object. Using arrow function to have access to `this`
   * @param value selected option from autocomplete dropdown
   * @returns display value `string`
   */
  getDisplayValue = (value: T1): string => {
    if (this.displayProperty && value)
      return value[this.displayProperty] as unknown as string;
    else
      return '';
  };
}