import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EAppProduct, ExternalVendorProduct, ExternalVendorProductV2 } from 'src/app/shared/models/eapps.models';
import { SimonTrainingStatus } from 'src/app/shared/models/training.models';

@Injectable({
  providedIn: 'root'
})
export class ProductsApiService {

  constructor(private http: HttpClient) { }

  get(): Observable<EAppProduct[]> {
    return this.http.get<EAppProduct[]>('/api/Products');
  }

  getPermittedProducts(): Observable<ExternalVendorProduct[]> {
    return this.http.get<ExternalVendorProduct[]>('/api/Products/Permitted');
  }

  getPermittedProductsV2(): Observable<ExternalVendorProductV2[]> {
    return this.http.get<ExternalVendorProductV2[]>('/api/Products/PermittedV2');
  }

  getPermittedFirelightProducts(): Observable<ExternalVendorProduct[]> {
    const firelightVendorId = 1;
    return this.http.get<ExternalVendorProduct[]>(`/api/Products/Permitted?vendorId=${firelightVendorId}`);

  }

  getSimonTrainingStatus(cusip: string): Observable<SimonTrainingStatus> {
    return this.http.get<SimonTrainingStatus>(`/api/Products/${cusip}/SimonTrainingStatus`);
  }
}
