
export enum AmplitudeEventTypeListEnum {
  custom = 'Custom',
  undefined = 'Undefined Event',
  click = 'Click',
  navigation = 'Navigation',
  businessReportingByReportType = 'Business Reporting by Report Type',
  paperAppUpload = 'Paper App Upload'
}

export enum PaperAppUploadStepAmplitudeEnum {
  resetForm = 'Reset form',
  openModal = 'Open modal',
  uploadSuccess = 'Upload success',
  uploadFail = 'Upload fail',
  userAbort = 'User abort',
}

export enum PaperAppUploadClickTargetAmplitudeEnum {
  dashboardDragDrop = 'Dashboard: drag-drop area',
  dashboardButton = 'Dashboard: upload button',
  close = 'Close button or backdrop',
  submit = 'Submit button',
  other = 'Other',
}