import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortNumberSuffix'
})

export class ShortNumberSuffixPipe implements PipeTransform {
  /**
   * @param input Value to pass into this pipe
   * @param decimalPoints Number of decimal points to add after shortened `result`. Default is `1`
   * @param prefix Add any currency symbol before shortened `result`. Default is `$`
   * @returns `string` if valid input, `typeof input` if invalid input
   */
  transform(input: number|null|undefined, decimalPoints = 1, prefix = '$'): string | number | null | undefined {
    // If invalid input types, return input as-is
    if (input === null || input === undefined || isNaN(input) ) return input;

    // If 0, treat it as 0K. This is subject to change depending on actual usage
    if (input === 0) return prefix + '0K';

    else {
      /** Get absolute value of `input`, strip negative `-` sign */
      let absolute = Math.abs(input);
      /** Not sure how to explain this, see for loop below */
      const rounder = Math.pow(10, decimalPoints);
      /** Determine whether or not to add `-` sign before `result`*/
      const isNegative = input < 0;
      /** Pipe output. `string` if valid input, `typeof input` if invalid input*/
      let result: string | undefined = undefined;
      /** Different increment milestone shorthands ('K' for 'thousandth', 'M' for 'millionth', etc.) */
      let key = '';

      /** List of different increment milestones (thousandth, millionth, etc.) and their shorthands */
      const powers: Array<{ key: string; value: number; }> = [
        { key: 'Q', value: Math.pow(10, 15) },
        { key: 'T', value: Math.pow(10, 12) },
        { key: 'B', value: Math.pow(10, 9) },
        { key: 'M', value: Math.pow(10, 6) },
        { key: 'K', value: 1000 }
      ];

      // This loop starts with dividing our absolute input by the different increment milestones (thousandth, millionth, etc.)
      // and then round it up by the number of decimal places specified
      for (let index = 0; index < powers.length; index++) {
        // if input < increment value, division result will be less than 1 (ex: 100 / 1000 = 0.1);
        let reduced = absolute / powers[index].value;
        reduced = Math.round(reduced * rounder) / rounder;

        // If our input is equal or greater than the increment value then the division result must be >= 1, take that increment key (ex: 1100 / 1000 = 1.1)
        if (reduced >= 1) {
          absolute = reduced;
          key = powers[index].key;
          result = prefix + (isNegative ? '-' : '') + absolute + key;
          break;
        }
      }

      // Redundancy check in case for loop does not match a valid increment
      // In theory this shouldn't happen but if it does, return input as-is
      return result ?? input;
    }
  }
}
