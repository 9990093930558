import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AgentLicenses } from 'src/app/shared/models/agent.models';

@Injectable({
  providedIn: 'root'
})
export class AgentLicenseApiService {

  constructor(
    private http:HttpClient
  ) { }

  getUserAgentLicenses():Observable<AgentLicenses>{
    return this.http.get<AgentLicenses>('/api/User/AgentLicense');
  }

  getLoggedInUserAgentLicenses():Observable<AgentLicenses>{
    return this.http.get<AgentLicenses>('/api/User/AgentLicense/LoggedInUser');
  }

  updateUserAgentLicenses(Crd:string | null, Npn:string | null): Observable<null>{
    const body:AgentLicenses = {Crd: Crd, Npn: Npn};
    return this.http.post<null>('/api/User/AgentLicense', body);
  }
}
