import { Component, OnInit } from '@angular/core';
import { fadeIn } from 'src/app/animations';
import { environment } from 'src/environments/environment';

@Component({
  animations: [fadeIn],
  selector: 'app-visme-presentation-dialog',
  templateUrl: './visme-presentation-dialog.component.html',
  standalone: false
})
export class VismePresentationDialogComponent implements OnInit {
  environment = environment;
  url = 'portal.figmarketingusercontent.com/visme-presentation.template.html';
  iframeUrl = environment.production ? `https://${this.url}` : `https://qa.${this.url}`;

  ngOnInit(): void {
    localStorage.setItem('VismeCookie', 'true');
  }
}
