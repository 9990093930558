import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hyphenateCamelCase'
})
export class HyphenateCamelCasePipe implements PipeTransform {

  transform(value: string | undefined, trimCount?:number): string {
    if (!value) return '';
    if(!trimCount || trimCount <= 0) return value.replace(/([A-Z])/g, '-$1').trim();
    const stringArr = value.replace(/([A-Z])/g, '-$1').trim().split('-');
    return stringArr.slice(0, trimCount).join('-');
  }
}
