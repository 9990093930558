<section class="flex flex-col h-full overflow-hidden">
  <div class="flex items-center justify-start p-10 border-b border-solid lg:p-20 border-gray-lighter">
    <h3 class="m-10 mr-auto text-lg font-bold leading-none">Get Contracted</h3>
    <button mat-button type="button" mat-dialog-close class="m-10 icon-button is-primary is-inverted" matTooltip="Close dialog">
      <i class="material-icons">close</i>
    </button>
  </div>
  <div class="flex flex-col flex-auto p-10 overflow-auto">
    <div class="flex flex-col flex-none text-center">
      <div class="flex flex-col items-center m-10">
        <span class="flex flex-row items-center justify-center flex-none m-10 rounded-full w-36 h-36 bg-gray-dark">
          <span class="font-bold leading-none text-white">1</span>
        </span>
        <div class="flex flex-col">
          <p class="m-10 font-bold">Watch the Quick Tutorial</p>
          <div class="m-10">
            <iframe width="350" height="175" src="https://www.youtube.com/embed/UmEPdqEMS3o"
              title="YouTube video player" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
          </div>
        </div>
      </div>
      <div class="flex flex-col items-center m-10">
        <span class="flex flex-row items-center justify-center flex-none m-10 rounded-full w-36 h-36 bg-gray-dark">
          <span class="font-bold leading-none text-white">2</span>
        </span>
        <div class="flex flex-col flex-grow">
          <p class="m-10 font-bold">Prepare Needed Documents</p>
          <div class="flex flex-col m-10">
            @for (item of checklist; track item) {
              <span class="flex flex-row items-start content-start w-full pb-10 text-base">
                <i class="mr-10 leading-10 fig-24px fig-check-circle-filled text-green"></i><span class="leading-10"
              [innerHtml]="item"></span>
            </span>
          }
        </div>
      </div>
    </div>
  </div>
  </div>
  <div class="flex flex-col p-20 border-t border-solid lg:p-30 border-gray-lighter">
    <button mat-button type="button" class="w-full button is-success is-lg" (click)="goToSureLc()"
    aria-label="Go to SureLC">Launch</button>
  </div>
</section>