import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { typeGuard } from '../helpers/type-guard.helper';

/**
 * Validate each item in form `control.value` of type `string[]` against a regular expression to see if it passes. Ex: emails, phone numbers, etc.
 * @param regex the regular expression used to compare each item in `control.value`
 * @returns `null` if passes or `error: {invalidMultiStringPattern: {value: control.value}}` if fails
 */
export function ValidateMultiStringPattern(regex: RegExp): ValidatorFn {
  return (control: AbstractControl<string[]>): ValidationErrors | null => {
    if (control.value.length === 0 || control.value === undefined) return null;
    const error = {
      invalidMultiStringPattern: { value: new Array<string>() }
    };
    control.value.forEach(val => {
      const match = val.match(regex);
      if (!match?.length) error.invalidMultiStringPattern.value.push(val);
    });
    return error.invalidMultiStringPattern.value.length ? error : null;
  };
}

/**
 * Validate form `control.value.length` of type `T[]` against the maximum length `maxLength`. Ex: `control.value<number> = [1, 2, 3]` cannot exceed `maxLength: 3`
 * @param maxLength the maximum length in the `value` array
 * @returns `null` if passes or `error: {invalidArrayMaxLength: {value: control.value}}` if fails
 */
export function ValidateArrayMaxLength<T>(maxLength: number): ValidatorFn {
  return (control: AbstractControl<T[]>): ValidationErrors | null => {
    if (control.value.length === 0 || control.value === undefined) return null;
    const error = {
      invalidArrayMaxLength: { value: control.value }
    };
    return control.value.length > maxLength ? error : null;
  };
}

/**
 * Validate form control to check if `control.value` is of type `T`
 * @param prop the property that we're expecting the value of that type to have. Ex: `{person: firstName?: string; lastName: string}`. `person` should always have `person.lastName` while `person.firstName` is optional. `prop` is `'lastName'` in this case
 * @returns `null` if passes or `error: {invalidType: {value: control.value}}` if fails
 */
export function ValidateObjectType<T>(prop: keyof T): ValidatorFn {
  return (control: AbstractControl<T>): ValidationErrors | null => {
    const error = {
      invalidType: { value: control.value }
    };
    if (typeGuard<T>(control.value, prop))
      return null;
    else
      return error;
  };
}

