export const countMapString = (count: number): string=> {
  if (1 <= count && count <= 100) return '1-100';
  if (101 <= count && count <= 200) return '101-200';
  if (201 <= count && count <= 300) return '201-300';
  if (301 <= count && count <= 400) return '301-400';
  if (401 <= count && count <= 500) return '401-500';
  if (501 <= count && count <= 750) return '501-750';
  if (751 <= count && count <= 1000) return '751-1000';
  if (1001 <= count && count <= 1500) return '1001-1500';
  if (1501 <= count && count <= 2000) return '1501-2000';
  if (2001 <= count && count <= 3000) return '2000-3000';
  if (3001 <= count) return '> 3000';
  return '0';
};