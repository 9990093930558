<section class="flex flex-row items-center p-20 border-b border-solid border-gray-lighter gap-x-20">
  <h2 class="flex-none text-lg font-bold">{{cardHeader}}</h2>
  <span class="flex-none mx-0 divider is-vertical"></span>
  <div class="relative flex flex-row items-center justify-start sm:flex-grow-0 sm:w-384 ">
    <i class="absolute transform -translate-y-1/2 material-icons top-1/2 left-5 text-gray">search</i>
    <input type="search" class="w-full input is-lg pl-36" placeholder="Search..." [(ngModel)]="searchText"
           (ngModelChange)="searchFilter(searchText)" />
  </div>
  <div class="flex flex-row items-center ml-auto">
    <button mat-button type="button" amplitudeEvent="Contracting Export" class="flex items-center justify-center w-full button is-primary is-outlined"
            (click)="exportContractReport()" aria-label="Export Carriers List">
      <span class="flex items-center gap-x-5">
        <span>Export List</span>
        <i class="material-icons mi-18">file_download</i>
      </span>
    </button>
  </div>
</section>
@if (!loading) {
  <div class="flex flex-col flex-grow overflow-auto min-h-256" [@fadeIn]>
    <ul class="sticky top-0 grid grid-cols-6 cursor-pointer">
      <li
          class="flex flex-row items-center justify-start col-span-2 p-10 text-xs font-semibold uppercase border-b border-solid cursor-pointer min-w-110 border-gray-lighter text-gray focus:outline-none hover:bg-blue-lightest"
          (click)="orderRows('Carrier')">
        <span class="">Carrier</span>
        @if ('Carrier' === columnName) {
          <span class="ml-5 material-icons mi-18">{{reverseOrder ? 'arrow_drop_down' :
            'arrow_drop_up'}}</span>
        }
      </li>
      <li
          class="flex flex-row items-center justify-start col-span-1 p-10 text-xs font-semibold uppercase border-b border-solid min-w-80 border-gray-lighter text-gray focus:outline-none hover:bg-blue-lightest"
          (click)="orderRows('LineOfBusinessName')">
        <span>LOB</span>
        @if ('LineOfBusinessName' === columnName) {
          <span class="ml-5 material-icons mi-18">{{reverseOrder ?
            'arrow_drop_down' : 'arrow_drop_up'}}</span>
        }
      </li>
      <li
          class="flex flex-row items-center justify-start col-span-2 p-10 text-xs font-semibold uppercase border-b border-solid cursor-pointer min-w-80 border-gray-lighter text-gray focus:outline-none hover:bg-blue-lightest"
          (click)="orderRows('ContractStatus')">
        <span>Status</span>
        @if ('ContractStatus' === columnName) {
          <span class="ml-5 material-icons mi-18">{{reverseOrder ? 'arrow_drop_down'
            : 'arrow_drop_up'}}</span>
        }
      </li>
      <li
          class="flex flex-row items-center justify-start col-span-1 p-10 text-xs font-semibold uppercase border-b border-solid cursor-pointer min-w-110 border-gray-lighter text-gray focus:outline-none hover:bg-blue-lightest"
          (click)="orderRows('WritingNumbers')">
        <span>Writing Numbers</span>
        @if ('WritingNumbers' === columnName) {
          <span class="ml-5 material-icons mi-18">{{reverseOrder ? 'arrow_drop_down'
            : 'arrow_drop_up'}}</span>
        }
      </li>
    </ul>
    <div class="flex flex-col flex-grow pb-20 overflow-auto">
      @for (c of carrierList; track c; let first = $first; let last = $last; let index = $index) {
        <div class="flex flex-col"
            @fadeIn>
          <div class="grid grid-cols-6 border-solid border-gray-lighter focus:outline-none"
              [class]="last ? '' : 'border-b'">
            <div class="flex flex-row items-center justify-start col-span-2 p-10 text-sm font-bold min-w-110">
              <span>{{c.Carrier}}</span>
            </div>
            <div class="flex flex-row items-center justify-start col-span-1 p-10 text-sm min-w-80">
              <span>{{c.LineOfBusinessName}}</span>
            </div>
            <div class="flex flex-row items-center justify-start col-span-2 p-10 text-sm min-w-80"
                [class]="'Active'=== c.ContractStatus ? 'text-green font-semibold' : ''">
              <span [innerText]="c.ContractStatus"></span>
            </div>
            <div class="flex flex-col items-stretch justify-center col-span-1 p-10 text-sm min-w-110">
              @for (num of c.WritingNumberArray; track num; let last = $last) {
                <span class="flex flex-row items-center justify-start" [ngClass]="{'mb-5':!last}"
                      attr.aria-label="{{num.type}}">
                  <span class="mr-5 material-icons mi-18 text-gray">{{num.icon}}</span>
                  <span>{{num.value}}</span>
                </span>
              }
            </div>
          </div>
          <!-- TODO: Might bring back in Q3/4 -->
          <!-- <div *ngIf="!!c.ShowDetails">
            <div class="grid grid-cols-6 bg-opacity-25 bg-gray-lighter"
              [ngClass]="{'pb-5 border-b border-solid border-gray-lighter': last, 'pt-5': first}"
              *ngFor="let a of c?.ContractDetails?.Appointments; let first = first; let last = last">
              <div class="col-span-1 px-10 py-5 text-sm"></div>
              <div class="col-span-2 px-10 py-5 text-sm font-semibold" [innerText]="a.StateName"></div>
              <div class="col-span-1 px-10 py-5 text-sm " [innerText]="a.LineOfBusiness"></div>
              <div class="col-span-1 px-10 py-5 text-sm "
                [ngClass]="{'text-green font-semibold' : a.AppointmentStatus === 'Active'}"
              [innerText]="a.AppointmentStatus"></div>
            </div>
          </div> -->
        </div>
      }
      @if (!carrierList.length) {
        <div @fadeInOut>
          <p class="w-full p-40 text-center">No carriers are available.</p>
        </div>
      }
    </div>
  </div>
} @else {
  <img src="/assets/images/loading/table-placeholder.svg" alt="loading" class="w-full animate-pulse">
}