<form class="relative w-full">
	<i class="absolute mx-10 mt-5 transform material-icons text-gray">search</i>
	<input [formControl]="parentCtrl" class="w-full px-10 pl-40 capitalize input" type="text"
				 aria-label="Search Redtail Contacts" placeholder="Search Redtail Contacts"
				 [matAutocomplete]="redtailSearchAuto" #trigger="matAutocompleteTrigger" />
	<mat-autocomplete #redtailSearchAuto="matAutocomplete"
										class="flex flex-col p-10 bg-white gap-y-5 select min-h-256 is-inverted md-no-underline wrap-shadow-none"
										[displayWith]="setDisplayValue">
		<mat-option class="text-center" *ngIf="isSearching" [value]="null">
			<mat-progress-bar mode="indeterminate"></mat-progress-bar>
		</mat-option>
		<ng-container *ngIf="!isSearching">
			<mat-option class="flex w-full p-10 overflow-visible custom-mat-option" *ngFor="let c of contacts" [value]="c" (click)="trigger.closePanel();">
				<span class="capitalize" [innerText]="c.FullName.toLowerCase()"></span>&nbsp;
				<span class="text-gray">{{c.Dob ? ' - (' + (c.Dob|date:'M/d/yyyy') + ') ' : ''}}</span>&nbsp;
				<span class="text-gray" [innerText]="c.PrimaryAddress1 ? ' - ' + c.PrimaryAddressCity + ', ' + c.PrimaryAddressState: ''"></span>
			</mat-option>
			<mat-option class="flex w-full h-full p-10 overflow-visible rounded custom-mat-option" *ngIf="noContactsFound"
									(click)="trigger.closePanel();">
				<span class="flex w-full p-10 overflow-visible cursor-pointer hover:bg-blue-lightest custom-mat-option bg-gray-lightest" (click)="requestManualInput()" (keypress)="requestManualInput()">
					{{hasError ? "An error occurred." : "No results."}} Click here to manually enter.
				</span>
			</mat-option>
		</ng-container>
	</mat-autocomplete>
	<span *ngIf="parentCtrl.touched && parentCtrl.errors?.required"class="text-xs text-red">This field is required</span>
	<span *ngIf="parentCtrl.touched && parentCtrl.errors?.invalidType" class="text-xs text-red">>Please enter a valid Redtail contact</span>
	<span class="absolute right-0 flex items-center justify-center h-24 px-10 mx-10 text-sm text-white transform -translate-y-1/2 rounded-full bg-green gap-x-5 top-1/2" *ngIf="isRedtailContact">
		<i class="material-icons mi-18">check</i>
		<span>Redtail Contact</span>
	</span>
</form>