import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { fadeIn } from 'src/app/animations';
import { ReportsApiService } from 'src/app/core/services/http/reports-api.service';
import { ToastClassEnum } from 'src/app/core/services/snackbar/snackbar.models';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { PaperApplicationHistoryModel } from './models/paper-application-history.model';

@Component({
  animations: [fadeIn],
  selector: 'app-paper-application-upload-history',
  templateUrl: './paper-application-upload-history.component.html',
  standalone: false
})
export class PaperApplicationUploadHistoryComponent implements AfterViewInit, OnInit {
  isLoading = true;
  paperUploads?: PaperApplicationHistoryModel[];

  constructor(
    private dialogRef: MatDialogRef<PaperApplicationUploadHistoryComponent>,
    private reportsApiService: ReportsApiService,
    private snackbarService: SnackbarService
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.reportsApiService.getPaperApplicationHistory()
      .subscribe({
        next: (applications) => this.paperUploads = applications,
        error: () => {
          this.snackbarService.openSnackbar('There was an error accessing paper app history.', ToastClassEnum.warning);
        }}
      )
      .add(()=> this.isLoading = false);
  }

  ngAfterViewInit(): void {
    const width = '60rem';
    const height = '60rem';
    this.dialogRef.updateSize(width, height);
  }
}
