import { Indexable } from '../models/indexable.model';
import { getFlatValues } from './object.helpers';

/**
 * Searches through array and returns new array of objects that match all search term/s
 * @param array - Array of data to search through
 * @param searchTerm - String or array of strings to match on
 * @param properties - Specific properties to limit search operation to
 * @returns Array of objects that contain search term/s
 */
export const strictSearch = <T>(array:T[], searchTerm:string|string[], properties?:string[]): T[] => {
  if (isSearchTermNullOrEmpty(searchTerm)) return array;
  return array.filter(item => hasAll<T>(item, searchTerm, properties));
};

/**
 * Searches through array and returns new array of objects that match at least one search term/s
 * @param array - Array of data to search through
 * @param searchTerm - String or array of strings to match on
 * @param properties - Specific properties to limit search operation to
 * @returns Array of objects that contain search term/s
 */
export const search = <T>(array:T[], searchTerm:string|string[], properties?:string[]): T[] => {
  if (isSearchTermNullOrEmpty(searchTerm)) return array;
  return array.filter(item => hasOne<T>(item, searchTerm, properties));
};


/**
 * Searches through array and returns new array of objects that match at least one search term/s ordered by number of occurrences for the given search terms
 * @param array - Array of data to search through
 * @param searchTerm - String or array of strings to match on
 * @param properties - Specific properties to limit search operation to
 * @returns Array of objects that contain search term/s
 */
export const weightedSearch = <T>(array:T[], searchTerm:string|string[], properties?:string[]): T[] => {
  if (isSearchTermNullOrEmpty(searchTerm)) return array;
  const itemByMatchCount = new Map<T, number>();
  array.forEach(item => itemByMatchCount.set(item, matchCount<T>(item, searchTerm, properties)));
  return array.filter(item => (itemByMatchCount.get(item) || 0) >= 1).sort((a,b) => itemByMatchCount.get(b) - itemByMatchCount.get(a));
};

/**
 * Determines if all search terms exist on object
 * @param  data - Object to search
 * @param searchTerm - String or array of strings to match on
 * @param properties - Specific properties to limit search operation to
 * @returns Boolean
 */
export const hasAll = <T>(data:T, searchTerm:string|string[], properties?:string[]): boolean => {
  if (typeof data !== 'object' || Array.isArray(data)) throw Error('Data must be object');
  if (isSearchTermNullOrEmpty(searchTerm)) return true;

  const searchTermArray =  getSearchTermArray(searchTerm);
  const values: string[] = getFlatValues(data as Indexable, properties).map(i => i.toLowerCase());
  return searchTermArray.every(searchTerm => values.some(value => value.includes(searchTerm.toLowerCase())));
};

/**
 * Determines if at least one search terms exist on object
 * @param  data - Object to search
 * @param searchTerm - String or array of strings to match on
 * @param properties - Specific properties to limit search operation to
 * @returns Boolean
 */
export const hasOne = <T>(data:T, searchTerm:string|string[], properties?:string[]): boolean => {
  if (typeof data !== 'object' || Array.isArray(data)) throw Error('Data must be object');
  if (isSearchTermNullOrEmpty(searchTerm)) return true;

  const searchTermArray =  getSearchTermArray(searchTerm);
  const values: string[] = getFlatValues(data as Indexable, properties).map(i => i.toLowerCase());
  return searchTermArray.some(searchTerm => values.some(value => value.includes(searchTerm.toLowerCase())));
};

/**
 * Finds the number of occurrences for a given search term/s within an object
 * @param  data - Object to search
 * @param searchTerm - String or array of strings to match on
 * @param properties - Specific properties to limit search operation to
 * @returns Number
 */
export const matchCount = <T>(data:T, searchTerm:string|string[], properties?:string[]): number => {
  if (typeof data !== 'object' || Array.isArray(data)) throw Error('Data must be object');
  if (isSearchTermNullOrEmpty(searchTerm)) return 1;

  const searchTermArray =  getSearchTermArray(searchTerm);
  const values: string[] = getFlatValues(data as Indexable, properties).map(i => i.toLowerCase());
  const weight = values.filter(value => searchTermArray.some(term => value.includes(term.toLowerCase()))).length;
  const exactMatchModifier = values.find(v => searchTermArray.every(term => v.includes(term.toLowerCase()))) ? 10 : 1; // if every search term exists within one of the values, weigh this item higher
  return weight * exactMatchModifier;
};

const getSearchTermArray = (searchTerm:string|string[]): string[] => {
  let array = [];
  if (typeof searchTerm === 'string') array = searchTerm.trim().split(' ');
  else  array = searchTerm;
  return array.map(x => x.toLowerCase());
};
const isSearchTermNullOrEmpty = (searchTerm: string|string[]): boolean =>
  !searchTerm || searchTerm == '' || !searchTerm.length;
