import { TimeUnit } from '../models/timeUnit.models';

export const getTimeRemaining = (countdownUTCTime: number): TimeUnit => {
  // Get today's date and time
  const nowDate = new Date();
  const now = nowDate.getTime() + nowDate.getTimezoneOffset(),
    interval: any = 0;
  let result = '',
    results: TimeUnit = {};

  // Find the distance between now and the count down date
  const distance = countdownUTCTime - now;

  // Time calculations for days, hours, minutes and seconds
  const days = Math.floor(distance / (1000 * 60 * 60 * 24)),
    hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
    minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
    seconds = Math.floor((distance % (1000 * 60)) / 1000);

  // return the result
  result = days + 'd ' + hours + 'h ' + minutes + 'm ' + seconds + 's ';
  if (distance <= 0) {
    result = '0d 0h 0m 0s';
    clearInterval(interval); // stop timer
  }
  results = { Days: days, Hours: hours, Minutes: minutes, Seconds: seconds, Result: result };
  return results;
};