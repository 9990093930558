import { Component, Input } from '@angular/core';
import { SearchResultModel } from '../../services/models/search-result.model';

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  standalone: false
})
export class SearchResultComponent {
  @Input() searchResult?: SearchResultModel;
}
