import { Indexable } from '../models/indexable.model';

/**
 * Takes an object or array and flattens all values to a top level array
 * @param data - Array or object
 * @param properties - Specific properties to limit operation to
 * @returns Array of flattened values
 */
export const getFlatValues = (data:Indexable, properties?:string[]):string[] => {
  if (!isStrictObject(data) && !Array.isArray(data)) {
    throw Error('Data must be object or array');
  }

  return Object.entries(data)
    .reduce((acc:string[], [key, value]) => {
      if (isNullOrUndefined(value)) {
        return acc;
      }
      if (isStrictObject(value) || isArrayWithNestedObject(value)){
        return acc.concat(getFlatValues(value as Indexable, properties as string[]));
      }
      if ((properties && properties.includes(key)) || !properties) {
        return acc.concat(value as string[]|string).map(x => String(x));
      }
      return acc;
    }, []) || [];
};


const isNullOrUndefined = (item:unknown): boolean => item === null || typeof item === 'undefined';
const isStrictObject = (item:unknown): boolean => typeof item === 'object' && !Array.isArray(item);
const isArrayWithNestedObject = (item:unknown): boolean => Array.isArray(item) && item.some((i) => typeof i === 'object');
