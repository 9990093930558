import { ExternalVendorProductState } from '../models/eapps.models';
import { StateBasicModel } from '../models/generic.models';

// TODO: refactor these states constants to be defined in one list

export const states:StateBasicModel[] = [
  { ShortName: 'AL', LongName: 'Alabama' },
  { ShortName: 'AK', LongName: 'Alaska' },
  { ShortName: 'AZ', LongName: 'Arizona' },
  { ShortName: 'AR', LongName: 'Arkansas' },
  { ShortName: 'CA', LongName: 'California' },
  { ShortName: 'CO', LongName: 'Colorado' },
  { ShortName: 'CT', LongName: 'Connecticut' },
  { ShortName: 'DE', LongName: 'Delaware' },
  { ShortName: 'FL', LongName: 'Florida' },
  { ShortName: 'GA', LongName: 'Georgia' },
  { ShortName: 'HI', LongName: 'Hawaii' },
  { ShortName: 'ID', LongName: 'Idaho' },
  { ShortName: 'IL', LongName: 'Illinois' },
  { ShortName: 'IN', LongName: 'Indiana' },
  { ShortName: 'IA', LongName: 'Iowa' },
  { ShortName: 'KS', LongName: 'Kansas' },
  { ShortName: 'KY', LongName: 'Kentucky' },
  { ShortName: 'LA', LongName: 'Louisiana' },
  { ShortName: 'ME', LongName: 'Maine' },
  { ShortName: 'MD', LongName: 'Maryland' },
  { ShortName: 'MA', LongName: 'Massachusetts' },
  { ShortName: 'MI', LongName: 'Michigan' },
  { ShortName: 'MN', LongName: 'Minnesota' },
  { ShortName: 'MS', LongName: 'Mississippi' },
  { ShortName: 'MO', LongName: 'Missouri' },
  { ShortName: 'MT', LongName: 'Montana' },
  { ShortName: 'NE', LongName: 'Nebraska' },
  { ShortName: 'NV', LongName: 'Nevada' },
  { ShortName: 'NH', LongName: 'New Hampshire' },
  { ShortName: 'NJ', LongName: 'New Jersey' },
  { ShortName: 'NM', LongName: 'New Mexico' },
  { ShortName: 'NY', LongName: 'New York' },
  { ShortName: 'NC', LongName: 'North Carolina' },
  { ShortName: 'ND', LongName: 'North Dakota' },
  { ShortName: 'OH', LongName: 'Ohio' },
  { ShortName: 'OK', LongName: 'Oklahoma' },
  { ShortName: 'OR', LongName: 'Oregon' },
  { ShortName: 'PA', LongName: 'Pennsylvania' },
  { ShortName: 'RI', LongName: 'Rhode Island' },
  { ShortName: 'SC', LongName: 'South Carolina' },
  { ShortName: 'SD', LongName: 'South Dakota' },
  { ShortName: 'TN', LongName: 'Tennessee' },
  { ShortName: 'TX', LongName: 'Texas' },
  { ShortName: 'UT', LongName: 'Utah' },
  { ShortName: 'VT', LongName: 'Vermont' },
  { ShortName: 'VA', LongName: 'Virginia' },
  { ShortName: 'WA', LongName: 'Washington' },
  { ShortName: 'WV', LongName: 'West Virginia' },
  { ShortName: 'WI', LongName: 'Wisconsin' },
  { ShortName: 'WY', LongName: 'Wyoming' }
];

export const statesAndTerritories:StateBasicModel[] = states.concat(
  [
    {ShortName: 'DC', LongName: 'District of Columbia'},
    {ShortName: 'VI', LongName: 'Virgin Islands'},
    {ShortName: 'PR', LongName: 'Puerto Rico'},
  ]
);


export const stateLongNameByShortName:Record<string,string> = {
  'AK': 'Alaska',
  'AL': 'Alabama',
  'AR': 'Arkansas',
  'AZ': 'Arizona',
  'CA': 'California',
  'CO': 'Colorado',
  'CT': 'Connecticut',
  'DC': 'District of Columbia',
  'DE': 'Delaware',
  'FL': 'Florida',
  'GA': 'Georgia',
  'HI': 'Hawaii',
  'IA': 'Iowa',
  'ID': 'Idaho',
  'IL': 'Illinois',
  'IN': 'Indiana',
  'KS': 'Kansas',
  'KY': 'Kentucky',
  'LA': 'Louisiana',
  'MA': 'Massachusetts',
  'MD': 'Maryland',
  'ME': 'Maine',
  'MI': 'Michigan',
  'MN': 'Minnesota',
  'MO': 'Missouri',
  'MS': 'Mississippi',
  'MT': 'Montana',
  'NC': 'North Carolina',
  'ND': 'North Dakota',
  'NE': 'Nebraska',
  'NH': 'New Hampshire',
  'NJ': 'New Jersey',
  'NM': 'New Mexico',
  'NV': 'Nevada',
  'NY': 'New York',
  'OH': 'Ohio',
  'OK': 'Oklahoma',
  'OR': 'Oregon',
  'PA': 'Pennsylvania',
  'RI': 'Rhode Island',
  'SC': 'South Carolina',
  'SD': 'South Dakota',
  'TN': 'Tennessee',
  'TX': 'Texas',
  'UT': 'Utah',
  'VA': 'Virginia',
  'VI': 'Virgin Islands',
  'VT': 'Vermont',
  'WA': 'Washington',
  'WI': 'Wisconsin',
  'WV': 'West Virginia',
  'WY': 'Wyoming',
};

export const externalVendorProductStateMap: Record<string, ExternalVendorProductState> = {
  'AK' : { 'JurisdictionId': 2, 'StateAbbrev': 'AK' },
  'AL' : { 'JurisdictionId': 1, 'StateAbbrev': 'AL' },
  'AR' : { 'JurisdictionId': 5, 'StateAbbrev': 'AR' },
  'AZ' : { 'JurisdictionId': 4, 'StateAbbrev': 'AZ' },
  'CA' : { 'JurisdictionId': 6, 'StateAbbrev': 'CA' },
  'CO' : { 'JurisdictionId': 7, 'StateAbbrev': 'CO' },
  'CT' : { 'JurisdictionId': 8, 'StateAbbrev': 'CT' },
  'DC' : { 'JurisdictionId': 10, 'StateAbbrev': 'DC' },
  'DE' : { 'JurisdictionId': 9, 'StateAbbrev': 'DE' },
  'FL' : { 'JurisdictionId': 12, 'StateAbbrev': 'FL' },
  'GA' : { 'JurisdictionId': 13, 'StateAbbrev': 'GA' },
  'HI' : { 'JurisdictionId': 15, 'StateAbbrev': 'HI' },
  'IA' : { 'JurisdictionId': 19, 'StateAbbrev': 'IA' },
  'ID' : { 'JurisdictionId': 16, 'StateAbbrev': 'ID' },
  'IL' : { 'JurisdictionId': 17, 'StateAbbrev': 'IL' },
  'IN' : { 'JurisdictionId': 18, 'StateAbbrev': 'IN' },
  'KS' : { 'JurisdictionId': 20, 'StateAbbrev': 'KS' },
  'KY' : { 'JurisdictionId': 21, 'StateAbbrev': 'KY' },
  'LA' : { 'JurisdictionId': 22, 'StateAbbrev': 'LA' },
  'MA' : { 'JurisdictionId': 26, 'StateAbbrev': 'MA' },
  'MD' : { 'JurisdictionId': 25, 'StateAbbrev': 'MD' },
  'ME' : { 'JurisdictionId': 23, 'StateAbbrev': 'ME' },
  'MI' : { 'JurisdictionId': 27, 'StateAbbrev': 'MI' },
  'MN' : { 'JurisdictionId': 28, 'StateAbbrev': 'MN' },
  'MO' : { 'JurisdictionId': 30, 'StateAbbrev': 'MO' },
  'MS' : { 'JurisdictionId': 29, 'StateAbbrev': 'MS' },
  'MT' : { 'JurisdictionId': 31, 'StateAbbrev': 'MT' },
  'NC' : { 'JurisdictionId': 38, 'StateAbbrev': 'NC' },
  'ND' : { 'JurisdictionId': 39, 'StateAbbrev': 'ND' },
  'NE' : { 'JurisdictionId': 32, 'StateAbbrev': 'NE' },
  'NH' : { 'JurisdictionId': 34, 'StateAbbrev': 'NH' },
  'NJ' : { 'JurisdictionId': 35, 'StateAbbrev': 'NJ' },
  'NM' : { 'JurisdictionId': 36, 'StateAbbrev': 'NM' },
  'NV' : { 'JurisdictionId': 33, 'StateAbbrev': 'NV' },
  'NY' : { 'JurisdictionId': 37, 'StateAbbrev': 'NY' },
  'OH' : { 'JurisdictionId': 41, 'StateAbbrev': 'OH' },
  'OK' : { 'JurisdictionId': 42, 'StateAbbrev': 'OK' },
  'OR' : { 'JurisdictionId': 43, 'StateAbbrev': 'OR' },
  'PA' : { 'JurisdictionId': 45, 'StateAbbrev': 'PA' },
  'PR' : { 'JurisdictionId': 46, 'StateAbbrev': 'PR' },
  'RI' : { 'JurisdictionId': 47, 'StateAbbrev': 'RI' },
  'SC' : { 'JurisdictionId': 48, 'StateAbbrev': 'SC' },
  'SD' : { 'JurisdictionId': 49, 'StateAbbrev': 'SD' },
  'TN' : { 'JurisdictionId': 50, 'StateAbbrev': 'TN' },
  'TX' : { 'JurisdictionId': 51, 'StateAbbrev': 'TX' },
  'UT' : { 'JurisdictionId': 52, 'StateAbbrev': 'UT' },
  'VA' : { 'JurisdictionId': 55, 'StateAbbrev': 'VA' },
  'VT' : { 'JurisdictionId': 53, 'StateAbbrev': 'VT' },
  'WA' : { 'JurisdictionId': 56, 'StateAbbrev': 'WA' },
  'WI' : { 'JurisdictionId': 58, 'StateAbbrev': 'WI' },
  'WV' : { 'JurisdictionId': 57, 'StateAbbrev': 'WV' },
  'WY' : { 'JurisdictionId': 59, 'StateAbbrev': 'WY' },
  'VI' : { 'JurisdictionId': 54, 'StateAbbrev': 'VI' }
};