import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { externalLoadingHtml } from 'src/app/shared/constants/externalLoading.constants';
import { ExternalVendorProduct, ExternalVendorProductV2 } from 'src/app/shared/models/eapps.models';
import { CreateActivityBasicResponse, CreateActivityRequest } from 'src/app/shared/models/firelightApi.models';
import { StateBasicModel } from 'src/app/shared/models/generic.models';
import { iGoApplication } from 'src/app/shared/models/iGo-application.models';
import { AmplitudeEventService } from './amplitude/amplitude-event.service';
import { PermissionService } from './auth/permission.service';
import { FirelightApiService } from './http/firelight-api.service';
import { SsoApiService } from './http/sso-api.service';
import { SsoService } from './sso.service';
import { externalVendorProductStateMap } from 'src/app/shared/constants/states.constants';
import { Contact } from 'src/app/shared/models/contact.models';
import { AmplitudeStartEAppFunnelModel } from 'src/app/shared/models/amplitude.models';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EAppService {

  constructor(
    private firelightApiService: FirelightApiService,
    private permissionService: PermissionService,
    private ssoApiService: SsoApiService,
    private amplitudeEventService: AmplitudeEventService,
    private ssoService: SsoService,
  ) { }

  /**
   * Submit iPipeline (Life/LTC) App
   * @param stateCode
   * @param productId
   * @param productTypeId
   * @param amplitudeFunnelModel Used for Amplitude E-App Funnel tracking
   */
  startiPipelineEApplication(stateCode: string, productId: string, productTypeId: string, amplitudeFunnelModel?: AmplitudeStartEAppFunnelModel, contact?: Contact | null,): void {
    if (this.permissionService.hasOnePermission('IpipelineEApps:Access')) {
      const request: iGoApplication = {
        StateCode: stateCode,
        ProductId: productId,
        ProductTypeId: productTypeId
      };

      if (contact) {
        request.Client = {
          FirstName: contact.FirstName,
          LastName: contact.LastName,
          Dob: contact.Dob,
          EmailAddress: contact.PrimaryEmailAddress,
          PrimaryEmailAddress: contact.PrimaryEmailAddress,
          PrimaryPhoneNumber: contact.PrimaryPhoneNumber,
          Gender: contact.Gender,
          MaritalStatus: contact.MaritalStatus,
          ResidentialAddress1: contact.PrimaryAddress1,
          ResidentialAddressCity: contact.PrimaryAddressCity,
          ResidentialAddressCountry: contact.PrimaryAddressCountry,
          ResidentialAddressState: contact.PrimaryAddressState,
          ResidentialAddressZipCode: contact.PrimaryAddressZipcode,
          Ssn: contact.Ssn,
        };
      }
      const w = window.open();
      w?.document.write(externalLoadingHtml);
      this.ssoApiService.initiateIPipelineEappsSso(request)
        .subscribe(data => {
          w?.document.write(data);
          w?.document.forms.namedItem('samlform')?.submit();
          if (amplitudeFunnelModel) {
            this.amplitudeEventService.logEAppFunnel('End Workflow Success', amplitudeFunnelModel);
          }

        }, () => w?.document.write(' Failed.  There was an error accessing this resource.'));
    }
  }

  startFirelightEApplication(product: ExternalVendorProduct | ExternalVendorProductV2, state: StateBasicModel, contact?: Contact | null, clientFirstName?: string | null, clientLastName?: string | null): Observable<CreateActivityBasicResponse> {

    const request: CreateActivityRequest = {
      ActivityName: `${clientFirstName || contact?.FirstName || ''}${clientLastName || contact?.LastName || ''}${clientFirstName || contact?.FirstName || clientLastName || contact?.LastName ? '-' : ''}${product.Business}-${product.Product}`,
      CarrierCode: product.CarrierCode,
      CUSIP: product.ProductCusip,
      Jurisdiction: externalVendorProductStateMap[state.ShortName].JurisdictionId.toString(),
      Client: {
        FirstName: clientFirstName || '',
        LastName: clientLastName || ''
      },
    };
    if (contact) {

      request.Client = {
        FirstName: contact.FirstName,
        LastName: contact.LastName,
        Alias: contact.Alias,
        Dob: contact.Dob,
        EmailAddress: contact.PrimaryEmailAddress,
        EmployerName: contact.EmployerName,
        FullName: contact.FullName,
        Gender: contact.Gender,
        MaritalStatus: contact.MaritalStatus,
        MiddleName: contact.MiddleName,
        Occupation: contact.Occupation,
        PrimaryEmailAddress: contact.PrimaryEmailAddress,
        PrimaryPhoneNumber: contact.PrimaryPhoneNumber,
        ResidentialAddress1: contact.PrimaryAddress1,
        ResidentialAddressCity: contact.PrimaryAddressCity,
        ResidentialAddressCountry: contact.PrimaryAddressCountry,
        ResidentialAddressState: contact.PrimaryAddressState,
        ResidentialAddressZipCode: contact.PrimaryAddressZipcode,
        Ssn: contact.Ssn,
        Suffix: contact.Suffix,
      };
    }
    return this.firelightApiService.postFirelightApplication(request);
  }

  goToFirelight(activityId: string, options?: Options): void {
    // TODO: Remove `options` when safe
    if (environment.qa) {
      console.log(options);
    }
    // TODO: Hotfix to SSO everyone into native Firelight
    this.ssoService.firelightSso(activityId);
  }
}

export interface Options {
  isNew: boolean
}
