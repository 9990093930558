<div class="flex flex-col w-full h-full text-base">
  @if (!formParametersComponent) {
    <header class="flex items-center p-20 border-b border-solid border-gray-lighter">
      <h2 class="m-10 text-lg font-bold">{{!surveyRenewals.length ? 'No ' : ''}}Action Required</h2>
      <button mat-button type="button" class="m-10 ml-auto icon-button is-primary is-inverted" aria-label="Close dialog"
        mat-dialog-close (click)="closeDialog()" matTooltip="Ignore all"><i class="material-icons">close</i></button>
    </header>
  }
  <section class="flex flex-col p-20 overflow-auto gap-y-20" @fadeIn>
    @if (!onBehalfOfUser && surveyRenewals.length) {
      @if (!showPaymentSection && !showCompletedSection) {
        <section class="flex flex-col">
          <p class="m-10 text-lg">
            Our records indicate that your enrollment in the following program@if (surveyRenewals.length > 1) {<span>s</span>}
            will expire soon. Please select how you would like to proceed.
          </p>
          <div class="flex flex-col m-10 gap-y-20">
            @for (renewal of surveyRenewals; track renewal) {
              <div
                class="flex flex-row items-center rounded p-15 min-h-48 bg-gray-lightest">
                <span class="mr-20 font-bold w-128">{{renewal.Name}}</span>
                @if (!renewal.ActionTaken) {
                  <div class="flex flex-row items-center ml-auto gap-x-10">
                    @if ((renewal.SurveyRenewalStatusId === this.renewalStatus.Open || renewal.SurveyRenewalStatusId === this.renewalStatus.Ignored) && renewal.OrderId === null) {
                      <button mat-button type="button"
                        (click)="setSurveyRenewalAsAccepted(renewal)" class="button is-primary is-sm">
                        Renew Now
                      </button>
                    }
                    @if ((renewal.SurveyRenewalStatusId === this.renewalStatus.Open || renewal.SurveyRenewalStatusId === this.renewalStatus.Ignored) && renewal.OrderId === null) {
                      <button mat-button type="button"
                        (click)="openDeclinedModal(renewal)" class="button is-warning is-outlined is-sm">
                        I'm Not Renewing
                      </button>
                    }
                    @if (!filterBySurveyId && (renewal.SurveyRenewalStatusId === this.renewalStatus.Open && renewal.OrderId === null)) {
                      <button mat-button type="button"
                        (click)="setSurveyRenewalAsIgnored(renewal)" class="button is-primary is-outlined is-sm">
                        Ignore
                      </button>
                    }
                    @if (renewal.SurveyRenewalStatusId=== this.renewalStatus.Declined) {
                      <div>
                        <em class="mx-10 text-base">{{renewal.RenewalStatus}} on
                        {{renewal.RenewModifiedOn | date : 'shortDate'}}</em>
                      </div>
                    }
                    @if (renewal.SurveyRenewalStatusId=== this.renewalStatus.Accepted && renewal.OrderId === null) {
                      <div>
                        <em class="mx-10 text-base">{{renewal.RenewalStatus}} & Signed as of
                        {{renewal.RenewModifiedOn | date : 'shortDate'}}</em>
                      </div>
                    }
                    @if (renewal.SurveyRenewalStatusId=== this.renewalStatus.Accepted && renewal.OrderComplete) {
                      <div>
                        <em class="mx-10 text-base">{{renewal.RenewalStatus}}, Paid & Signed as of
                        {{renewal.RenewModifiedOn | date : 'shortDate'}}</em>
                      </div>
                    }
                    @if ((renewal.SurveyRenewalStatusId === this.renewalStatus.Open && renewal.OrderId !== null && !renewal.OrderComplete)) {
                      <div>
                        <em class="mx-10 text-base">Order In Progress</em>
                        <button mat-button type="button" (click)="setSurveyRenewalAsAccepted(renewal); renewal.ActionTaken = true;"
                        class="button is-primary is-outlined">Go to Payment</button>
                      </div>
                    }
                    @if (renewal.SurveyRenewalStatusId===1 && renewal.OrderComplete) {
                      <button mat-button type="button"
                        (click)="openSignatureModal(renewal); renewal.ActionTaken = true;"
                        class="button is-primary is-outlined">
                        Sign Agreement
                      </button>
                    }
                  </div>
                }
              </div>
            }
          </div>
          <button mat-button type="button" class="m-10 ml-auto button is-primary is-outlined" aria-label="Close dialog"
          mat-dialog-close (click)="closeDialog()" matTooltip="Ignore all">Ignore All</button>
        </section>
      }
      @if (showPaymentSection) {
        <div @fadeIn>
          <app-purchase-page [purchaseModel]="purchaseModel" (purchaseCompleted)="payComponentCallback($event)"
            (purchaseCanceled)="closeDialog()">
          </app-purchase-page>
        </div>
      }
      @if (showCompletedSection) {
        <div class="flex flex-col items-center p-20 text-center gap-y-20" @fadeIn>
          <span class="flex items-center justify-center w-48 h-48 rounded-full bg-green-lightest">
            <i class="material-icons text-green">check_circle</i>
          </span>
          <div class="text-lg">
            <p>Thank You!</p>
            <p>You can also manage your enrollments via the user menu to the right.</p>
          </div>
          <button mat-button type="button" mat-dialog-close class="mt-10 button is-primary">Close</button>
        </div>
      }
    }
    @if (onBehalfOfUser) {
      <p class="m-10 text-lg">You cannot manage enrollment renewals on behalf of someone else. Please log in as yourself to make changes.</p>
    }
    @if (!onBehalfOfUser && !surveyRenewals.length) {
      <p class="m-10 text-lg">You have no enrollments expiring in the next 30 days.</p>
    }
  </section>
</div>