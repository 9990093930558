@if (!integrations.length) {
  <button mat-button class="button is-primary is-outlined" [matMenuTriggerFor]="integrationSelection" type="button" (click)="endTour()">
    {{integrations.length === 0 ? 'Connect Your CRM' : integrations.length === 1 ? 'Connected to ' + integrations[0] + ' ': 'Connected to ' + integrations.length + ' Apps '}} <i class="fig-18px fig-triangle-down-filled"></i>
  </button>
  <mat-menu #integrationSelection="matMenu">
    <button mat-menu-item (click)="openRedtailIntegrationModal()" type="button">
      <div class="flex items-center justify-between min-w-200">
        <span class="flex items-center justify-start w-full">
          <img class="w-24 h-24" src="assets/images/icons/redtail-icon.svg" aria-label="Redtail logo" alt="Redtail logo">
          <span class="my-auto ml-10">Redtail</span>
        </span>
        <span class="my-auto text xs">
          @if (isRedtailUser) {
            <span class="text-green-dark">Connected</span>
          }@else {
            <span>Connect</span>
          }
        </span>
      </div>
    </button>
    <button mat-menu-item (click)="activateWealthbox()" type="button">
      <div class="flex items-center justify-between min-w-200">
        <span class="flex items-center justify-start w-full">
          <img class="w-24 h-24" src="assets/images/icons/Wealthbox-icon-RGB-1 Blue.png" aria-label="Wealthbox logo" alt="Wealthbox logo">
          <span class="my-auto ml-10">Wealthbox</span>
        </span>
        <span class="my-auto text xs">
          @if (isWealthboxUser) {
            <span class="text-green-dark">Connected</span>
          }@else {
            <span>Connect</span>
          }
        </span>
      </div>
    </button>
    <button mat-menu-item (click)="activateSalesforce()" type="button">
      <div class="flex items-center justify-between min-w-200">
        <span class="flex items-center justify-start w-full">
          <img class="w-24 h-24" src="assets/images/icons/salesforce-icon.svg" aria-label="Salesforce icon" alt="Salesforce icon">
          <span class="my-auto ml-10">Salesforce</span>
        </span>
        <span class="my-auto text xs">
          @if (isSalesforceUser) {
            <span class="text-green-dark">Connected</span>
          }@else {
            <span>Connect</span>
          }
        </span>
      </div>
    </button>
  </mat-menu>
} @else {
  <div class="flex items-center gap-x-5">
    <p class="text-sm font-semibold text-gray">Connected To: </p>
    <div class="flex items-center border rounded border-gray-lighter bg-blue-lightest">
      @if (isRedtailUser) {
        <button mat-button type="button" class="button is-primary is-inverted" (click)="openManageIntegrationModal(manageIntegrationEnum.Redtail)">
          <span class="flex items-center gap-x-5">
            <img class="w-18 h-18" src="assets/images/icons/redtail-icon.svg" aria-label="Redtail logo" alt="Redtail logo">
            <span>Redtail</span>
          </span>
        </button>        
      }
      @if (isSalesforceUser) {
        <button mat-button type="button" class="button is-primary is-inverted" (click)="openManageIntegrationModal(manageIntegrationEnum.Salesforce)">
          <span class="flex items-center gap-x-5">
            <img class="w-18 h-18" src="assets/images/icons/salesforce-icon.svg" aria-label="Wealthbox logo" alt="Salesforce logo">
            <span>Salesforce</span>
          </span>
        </button>
      }
      @if (isWealthboxUser) {
        <button mat-button type="button" class="button is-primary is-inverted" (click)="openManageIntegrationModal(manageIntegrationEnum.Wealthbox)">
          <span class="flex items-center gap-x-5">
            <img class="w-18 h-18" src="assets/images/icons/Wealthbox-icon-RGB-1 Blue.png" aria-label="Wealthbox logo" alt="Wealthbox logo">
            <span>Wealthbox</span>
          </span>
        </button>
      }
    </div>
  </div>
}

