import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { FirelightApiService } from 'src/app/core/services/http/firelight-api.service';
import { Carrier, FirelightState, CreateActivityRequest, ActivityDetails } from 'src/app/shared/models/firelightApi.models';
import uniqBy from 'lodash-es/uniqBy';
import { forkJoin } from 'rxjs';
import { stateLongNameByShortName } from 'src/app/shared/constants/states.constants';
import { ExternalVendorProduct } from 'src/app/shared/models/eapps.models';
import { CopyApplicationModalData } from './copy-application-modal-data.model';
import { EAppService } from 'src/app/core/services/e-app.service';
import { ProductsApiService } from 'src/app/core/services/http/products-api.service';

@Component({
  selector: 'app-copy-application-modal',
  templateUrl: './copy-application-modal.component.html',
})
export class CopyApplicationModalComponent implements OnInit {
  applicationId?: string;
  activityName?: string;
  carrier?: Carrier;
  firelightStates?: FirelightState[];
  errorMessage = '';
  isLoading = false;
  isSubmitting = false;
  isError = false;
  carriers?: Carrier[];
  product?: ExternalVendorProduct;
  products?: ExternalVendorProduct[];
  productList?: ExternalVendorProduct[];
  state?: FirelightState;
  stateShortNameByLongName: Record<string, string> = stateLongNameByShortName;

  constructor(
    public dialogRef: MatDialogRef<CopyApplicationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CopyApplicationModalData,
    private firelightApiService: FirelightApiService,
    private productsApiService: ProductsApiService,
    private eAppService: EAppService
  ) { }

  ngOnInit(): void {
    this.applicationId = this.data?.activityId;
    this.isLoading = true;
    forkJoin({
      activity: this.firelightApiService.getFirelightApplicationDetails(this.applicationId),
      products: this.productsApiService.getPermittedFirelightProducts(),
      firelightStates: this.firelightApiService.getFirelightStates()
    }).subscribe({
      next: results => {
        this.productList = results.products;
        this.firelightStates = results.firelightStates.filter(state => this.stateShortNameByLongName[state.StateAbbrev]);
        this.setCarriers();
        this.prefillForm(results.activity);
      },
      error: () => {
        this.isError = true;
        this.errorMessage = 'An error ocurred while loading data. Close this window and try again';
      }
    })
      .add(() => this.isLoading = false);
  }


  setCarriers(): void {
    const carrierList = this.productList?.map(item => {
      return {
        CarrierName: item.Business,
        CarrierCode: item.CarrierCode ?? ''
      };
    });
    const uniqueCarriers = uniqBy(carrierList, 'CarrierName');
    this.carriers = uniqueCarriers;
  }

  prefillForm(dataItems: ActivityDetails): void {
    this.activityName = dataItems.DataItems.find(i => i.DataItemId === 'FLI_ACTIVITY_NAME')?.Value;
    const cusip = dataItems.DataItems.find(i => i.DataItemId === 'FLI_PRODUCT_CUSPID')?.Value;
    const stateCode = dataItems.DataItems.find(i => i.DataItemId === 'FLI_ISSUED_STATE_CODE')?.Value;
    this.state = this.firelightStates?.find(s => s.JurisdictionId.toString() === stateCode?.toString());

    const originalProduct = this.productList?.find(p => p.ProductCusip === cusip);
    this.carrier = this.carriers?.find(c => c.CarrierCode === originalProduct?.CarrierCode && c.CarrierName === originalProduct?.Business);
    this.setProducts();

    // Verify product is available for selected state
    if (originalProduct?.States.find(s => s.JurisdictionId.toString() === this.state?.JurisdictionId.toString())) {
      /*       In order for the product to properly be shown in the UI dropdown
         it must be an item from the same array(or array reference) that's being used to populate the UI,
       */
      this.product = this.products?.find(p => p.ProductCusip === cusip);
    }
  }

  setProducts(): void {
    this.product = undefined;
    const filteredProducts = this.productList?.filter(p => {
      let passes = true;
      if (this.carrier?.CarrierName && (p.Business !== this.carrier?.CarrierName)) passes = false;
      if (this.state && (!p.States.map(s => s.JurisdictionId).includes(this.state?.JurisdictionId))) passes = false;
      return passes;
    });
    this.products = filteredProducts;
  }

  submitEApplication(): void {
    this.isLoading = true;

    let request: CreateActivityRequest | null = null;
    if (this.activityName && this.carrier?.CarrierCode && this.product?.ProductCusip && this.state) {
      request = {
        ActivityName: this.activityName,
        CarrierCode: this.carrier.CarrierCode,
        CUSIP: this.product.ProductCusip ?? '',
        Jurisdiction: this.state.JurisdictionId.toString(),
        Client: {
          FirstName: '',
          LastName: '',
        }
      };
    }

    if (!request || !this.applicationId) {
      this.isLoading = false;
      this.isError = true;
      this.errorMessage = 'Unable to upload application';
      return;
    }

    this.isSubmitting = true;
    this.firelightApiService.copyFirelightApplication(this.applicationId, request).subscribe({
      next: (activity) => {
        this.eAppService.goToFirelight(activity.ActivityId, { isNew: true });
        this.dialogRef.close();
      },
      error: () => {
        this.isError = true;
        this.errorMessage = 'An error ocurred while uploading the application';
      }
    })
      .add(() => {
        this.isLoading = false;
        this.isSubmitting = false;
      });
  }
}