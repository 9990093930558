<div class="flex flex-col flex-grow w-full h-full overflow-hidden">
  <section id="copyApplicationModal" class="flex items-center justify-between flex-none border-b p-30 border-gray-lighter">
    <h2 class="text-lg font-bold">Copy Firelight E-Application</h2>
    <button class="icon-button is-primary is-inverted" aria-label="Close"
      [mat-dialog-close] type="button">
      <i class="material-icons">close</i>
    </button>
  </section>
  @if (isLoading || isSubmitting) {
    <div class="relative p-20 m-auto">
      <div class="loader-wrap">
        <div class="loader-sm is-blue"></div>
      </div>
      @if (isSubmitting) {
        <p class="m-10 text-base leading-loose text-center">Uploading application. This may take a moment...</p>
      }
    </div>
  }
  @if (isError && !isSubmitting) {
    <div [@fadeIn] class="flex flex-col items-center my-auto text-center">
      <span class="flex items-center justify-center w-64 h-64 m-10 rounded-full bg-orange-lightest text-orange">
        <i class="material-icons mi-36">warning</i>
      </span>
      <p class="m-10 leading-loose">
        {{errorMessage}}
      </p>
    </div>
  }
  @if (!isLoading && !isError && !isSubmitting) {
    <section class="flex flex-col overflow-auto p-30 dialog-content gap-y-30">
      <p class="text-base">
        To submit a electronic application to Firelight, please complete the information below and click submit.
      </p>
      <form class="grid grid-cols-1 sm:grid-cols-2 gap-x-20 gap-y-20" name="firelightApp"
        (ngSubmit)="submitEApplication()">
        <div class="flex flex-col flex-grow">
          <label for="applicationTitle" class="mb-10 text-sm font-semibold text-gray">E-Application Title</label>
          <input class="h-40 px-10 border border-solid input is-inverted border-gray-light" type="text"
            name="applicationTitle" [(ngModel)]="activityName"
            aria-label="E-Application Title" required minlength="1" />
        </div>
        <div class="flex flex-col flex-grow">
          <label for="carrier" class="mb-10 text-sm font-semibold text-gray">Carrier</label>
          <mat-select [disableOptionCentering]="true" (selectionChange)="setProducts()" name="carrier" [(value)]="carrier"
            class="h-40 px-10 m-0 border border-solid select is-inverted md-no-underline border-gray-light"
            required placeholder="Select Carrier" aria-label="Select Carrier">
            @for (c of carriers; track c) {
              <mat-option [value]="c"
                required>
                {{c.CarrierName}}
              </mat-option>
            }
          </mat-select>
        </div>
        <div class="flex flex-col flex-grow">
          <label for="state" class="mb-10 text-sm font-semibold text-gray">State</label>
          <mat-select [disableOptionCentering]="true" (selectionChange)="setProducts()" name="state" [(value)]="state"
            class="h-40 px-10 m-0 border border-solid select is-inverted md-no-underline border-gray-light"
            required placeholder="Select State" aria-label="Select State">
            @for (s of firelightStates; track s) {
              <mat-option [value]="s"
                required>
                {{stateShortNameByLongName[s.StateAbbrev]}}
              </mat-option>
            }
          </mat-select>
        </div>
        <div class="flex flex-col flex-grow">
          <label for="product" class="mb-10 text-sm font-semibold text-gray">Product</label>
          <mat-select [disabled]="!carrier || !state" [disableOptionCentering]="true" name="product" [(value)]="product"
            class="h-40 px-10 m-0 border border-solid select is-inverted md-no-underline border-gray-light"
            required placeholder="Select Product" aria-label="Select Product">
            @for (p of products; track p) {
              <mat-option [value]="p"
                required>
                {{p.Product}}
              </mat-option>
            }
          </mat-select>
        </div>
      </form>
    </section>
  }
  <section class="flex flex-none p-20 border-t border-solid border-gray-lighter">
    <button class="m-10 ml-auto button is-primary is-lg" type="submit"
      name="submitApplication" aria-label="Submit E-Application"
      (click)="submitEApplication();"
      [disabled]="!activityName || !carrier || !product || !state || isSubmitting || isError || isLoading ">
      <span class="flex items-center justify-center">
        <span>Start{{ isSubmitting ? 'ing' : ''}} E-Application</span>
        <i class="ml-5 material-icons mi-18">send</i>
      </span>
    </button>
  </section>
</div>