import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionService } from 'src/app/core/services/auth/permission.service';

@Directive({
  // disabling as the rule won't fit our existing, and legacy applications of the directive
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[showIfOne]'
})
export class ShowIfOneDirective {

  private hasView = false;

  constructor(
    private permissionService: PermissionService,
    private templateRef: TemplateRef<unknown>,
    private viewContainerRef: ViewContainerRef) { }

  @Input() set showIfOne(permissions: string | string[]) {

    const isAuthorized = this.permissionService.hasOnePermission(permissions);
    if (isAuthorized && !this.hasView) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!isAuthorized && this.hasView) {
      this.viewContainerRef.clear();
      this.hasView = false;
    }
  }
}
