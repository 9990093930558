<div class="flex flex-col w-full h-full mx-auto justify-center dialog-content">
  <section class="flex flex-col justify-center h-full gap-y-20" @fadeIn>
    @if (!isRedtailAuthenticated) {
      <div class="flex flex-col items-center m-auto gap-y-20 max-w-400">
        <div class="flex items-center justify-center mx-10">
          <img class="w-auto h-60" src="./assets/images/logos/redtail-logo.svg" alt="Redtail Logo">
        </div>
        <span class="m-10 text-lg">
          Welcome to Redtail. Enter your <strong>Username</strong> and <strong>Password</strong> below to continue.
        </span>
        <form [formGroup]="redtailAuthForm" class="flex-col w-full">
          <div class="flex flex-col flex-shrink-0 mx-10 mb-20 gap-y-5">
            <!-- <label for="username" class="text-sm label text-gray" for="username">Username</label> -->
            <input class="input" type="text" placeholder="Username" name="Username" formControlName="username" appCyData="redtailUsername" />
            @if (usernameCtrl.touched && usernameCtrl.errors) {
              <span class="w-full text-xs text-red">
                @if (usernameCtrl.hasError('required')) {
                  <span>This field is required.</span>
                }
              </span>
            }
          </div>
          <div class="flex flex-col flex-shrink-0 mx-10 gap-y-5">
            <!-- <label for="password" class="text-sm label text-gray" for="password">Password</label> -->
            <input class="input" type="password" placeholder="Password" formControlName="password" appCyData="redtailPassword" />
            @if (passwordCtrl.touched && passwordCtrl.errors) {
              <span class="w-full text-xs text-red">
                @if (passwordCtrl.hasError('required')) {
                  <span>This field is required.</span>
                }
              </span>
            }
          </div>
          <div class="flex items-center justify-center mx-10 mt-20 gap-y-10">
            <button mat-button class="ml-auto button is-primary" type="submit"
                    (click)="authenticateWithRedTail()"
                    [disabled]="isSubmitting"
                    appCyData="redtailConnectButton">
              Sign{{isSubmitting ? 'ing' : ''}} in
            </button>
          </div>
        </form>
      </div>
    } @else {
      <div class="flex flex-col w-full h-full">
        <div class="bg-green-50 border border-green-200 rounded flex items-center p-10">
          <i class="material-icons text-green-500">
            check_circle
          </i>
          <span class="w-full text-base font-bold ml-5">Connected to Redtail CRM.</span>
          <button mat-button type="button" class="ml-auto button px-25 is-outlined is-warning"
            (click)="deactivateRedTail()"
            [disabled]="isSubmitting"
            appCyData="redtailDisconnectButton">
            Disconnect{{isSubmitting ? 'ing' : ''}}
          </button>
        </div>
        <span class="my-20 divider col-span-full"></span>
        <app-start-crm-eapp class="flex" [crmName]="'Redtail'"></app-start-crm-eapp>
      </div>
    }
  </section>
</div>