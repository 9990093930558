import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { fadeIn } from 'src/app/animations';

@Component({
  animations: [fadeIn],
  selector: 'app-disability',
  templateUrl: './disability.component.html',
  standalone: false
})
export class DisabilityComponent {
  disabilityBlocks: DisabilityBlock[] = [
    {
      Img: 'easy_income.png',
      Title: 'Easy Income Protection for Medical Resident',
      Icon: 'https://www.figmarketing.com/Images/Logos/Carriers/TheStandard.png',
      Description: 'Platinum Advantage\'s quality income protection allows residents to obtain a benefit amount that\'s right for them, no matter the size.',
      Url: 'https://www.figmarketing.com/files/resources/Easy_Income_Protection_for_Medical_Residents.pdf'
    },
    {
      Img: 'group_meeting.jpg',
      Title: 'These Improvements Make GSI Even Easier to Sell',
      Icon: 'https://www.figmarketing.com/Images/Logos/Carriers/TheStandard.png',
      Description: 'Faster processes, increased flexibility and more information for your clients.',
      Url: 'https://www.figmarketing.com/files/resources/These_Improvements_Make_GSI_Even_Easier_to_Sell.02.pdf'
    }
  ];
  carriers: CarrierProduct[] = [
    {
      ImgUrl: 'https://www.figmarketing.com/Images/Logos/Carriers/IllinoisMutual.png',
      Title: 'Illinois Mutual',
      Url: 'http://www.illinoismutual.com/'
    },
    {
      ImgUrl: 'https://www.figmarketing.com/Images/Logos/Carriers/MutualOfOmaha.png',
      Title: 'Mutual of Omaha',
      Url: 'http://mutualofomaha.com/'
    },
    {
      ImgUrl: 'https://www.figmarketing.com/Images/Logos/Carriers/PetersenInternational.png',
      Title: 'Petersen International Underwriters',
      Url: 'https://www.piu.org/'
    },
    {
      ImgUrl: 'https://www.figmarketing.com/Images/Logos/Carriers/PrincipalFinancialGroup.png',
      Title: 'Principal Financial Group',
      Url: 'https://advisors.principal.com/wps/portal/advisor/home/%21ut/p/a1/04_Sj9CPykssy0xPLMnMz0vMAfGjzOLNjbwtPEyCDTwNDFxNDTydzLz8LXxdjJwtTYAKIoEKDHAARwNC-sP1o9CUhJm4Gnh6mQd4uJv6GxkYmEEV4LGiIDfCINNRUREA1QFZGQ%21%21/dl5/d5/L2dBISEvZ0FBIS9nQSEh/'
    },
    {
      ImgUrl: 'https://www.figmarketing.com/Images/Logos/Carriers/TheStandard.png',
      Title: 'The Standard',
      Url: 'https://www.standard.com/financial-professional?utm_referrer=https%3A%2F%2Flogin.standard.com%2F'
    },
  ];

  constructor(
    private activatedRoute: ActivatedRoute
  ) { }

  getResourceUrl(): string | null {
    return this.activatedRoute.snapshot.paramMap.get('resource');
  }

  openUrl(url: string): void {
    if (url) window.open(url, '_blank');
  }
}

export interface DisabilityBlock {
  Img?: string,
  ImgUrl?: string,
  Title: string,
  Icon?: string,
  Description?: string,
  Url: string
}
export interface CarrierProduct {
  ImgUrl?: string,
  Title: string,
  Url?: string
}