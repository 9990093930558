export enum CompareProductsEnum {
  logo = './assets/images/icons/compare-products-logo.svg',
  header = 'Compare & Analyze Products',
  subheader = 'Select a product line below',
  footer = 'You can also explore our other product comparison tools below:',
  // any lines of business
  annuity = 'Annuity',
  life = 'Life',
  ltc = 'Care Planning',
  notes = 'Notes',
}