import { Component } from '@angular/core';
import { fadeIn } from 'src/app/animations';

@Component({
  animations: [fadeIn],
  selector: 'app-simon-powered',
  templateUrl: './simon-powered.component.html',
  standalone: false
})
export class SimonPoweredComponent{}
