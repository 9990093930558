import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { SurveyModel, SurveyAgentAnswerModel } from 'src/app/shared/models/survey.models';
import { SalesConsultantModel } from 'src/app/shared/models/salesConsultant.models';
import { SurveyRenewalModel } from 'src/app/shared/models/surveyRenewal.models';
import { CookieService } from 'ngx-cookie-service';
import { EntryResponse, ListResponse } from 'src/app/shared/models/generic.models';
import { renewalStatusEnum } from 'src/app/shared/enums/renewal-status.enum';

@Injectable({
  providedIn: 'root'
})
export class EnrollmentFormApiService {

  constructor(private http: HttpClient, private cookieService: CookieService) { }

  getSurvey(surveyId: number): Observable<SurveyModel> {
    return this.http.get<EntryResponse<SurveyModel>>(`/api/EligibilityForms/${surveyId}`)
      .pipe(map((x: EntryResponse<SurveyModel>) => x.Entry));
  }

  getSurveys(): Observable<SurveyModel[]> {
    return this.http.get<ListResponse<SurveyModel>>('/api/EligibilityForms').pipe(map((x: ListResponse<SurveyModel>) => x.Entries));
  }

  isEnrolled(surveyId: number): Observable<boolean> {
    return this.http.get<boolean>(`/api/EligibilityForms/${surveyId}/IsEnrolled`);
  }

  getAgentEnrollment(surveyId: number): Observable<boolean> {
    return this.http.get<boolean>(`/api/EligibilityForms/${surveyId}/Enrollment`);
  }

  submit(surveyId: number, answers: SurveyAgentAnswerModel[]): Observable<any> {
    return this.http.post(`/api/EligibilityForms/${surveyId}/Answers`, answers);
  }

  getSalesConsultants(): Observable<SalesConsultantModel[]> {
    return this.http.get<ListResponse<SalesConsultantModel>>('/api/SalesConsultants')
      .pipe(
        map((x: ListResponse<SalesConsultantModel>) => x.Entries),
        tap((salesConsultants: SalesConsultantModel[]) => salesConsultants.sort((a, b) => a.LastName.localeCompare(b.LastName)))
      );
  }

  getSurveyRenewals(filterBySurveyId?: number | null): Observable<SurveyRenewalModel[]> {
    return this.http.get<SurveyRenewalModel[]>('/api/Survey/AgentSurveyRenewals').pipe(map(x => {
      if (typeof x === 'undefined' || x === null) return [];
      if (filterBySurveyId !== null) {
        return x.filter(renewal => renewal.SurveyId === filterBySurveyId);
      }
      return x.map(renewal => {
        const renewalCopy = renewal;
        const cookieString = this.cookieService.get('surveyrenewal' + renewal.SurveyRenewalId);
        const renewalDate = new Date(cookieString);
        // verify that this a valid date
        if (Object.prototype.toString.call(renewalDate) === '[object Date]') {
          // Date is valid
          if (!isNaN(renewalDate.getTime())) {
            // set open renewals that has ignored cookie to ignored status on UI
            if (renewal.SurveyRenewalStatusId === renewalStatusEnum.Open) {
              renewalCopy.RenewalStatus = 'Ignored';
              renewalCopy.SurveyRenewalStatusId = renewalStatusEnum.Ignored;
              renewalCopy.RenewModifiedOn = renewalDate;
            } else {
              // remove lingering cookies for accepted & declined renewals
              this.cookieService.delete('surveyrenewal' + renewal.SurveyRenewalId);
            }
          }
        }
        return renewalCopy;
      });
    }));
  }

  getSurveyRenewalByOrderId(orderId: string): Observable<SurveyRenewalModel> {
    return this.http.get<SurveyRenewalModel>(`/api/Survey/AgentSurveyRenewalByOrderId/${orderId}`);
  }

  getSurveyRenewalBySurveyRenewalId(surveyRenewalId: number): Observable<SurveyRenewalModel> {
    return this.http.get<SurveyRenewalModel>(`/api/Survey/AgentSurveyRenewalByRenewalId/${surveyRenewalId}`);
  }

  createSurveyRenewalEvent(surveyRenewalId: number, renewalEventText: string): Observable<any> {
    const obj: any = {
      'SurveyRenewalId': surveyRenewalId,
      'RenewalEventText': renewalEventText
    };
    return this.http.post('/api/Survey/SubmitRenewal', obj);
  }

  surveyRenewalAccepted(surveyRenewalId: number, SurveyRenewalStatusId: number): Observable<any> {
    const obj: any = {
      'SurveyRenewalId': surveyRenewalId,
      'SurveyRenewalStatusId': SurveyRenewalStatusId
    };
    return this.http.post('/api/Survey/SubmitRenewal', obj);
  }

  surveyRenewalDeclined(surveyRenewalId: number, SurveyRenewalStatusId: number): Observable<any> {
    const obj: any = {
      'SurveyRenewalId': surveyRenewalId,
      'SurveyRenewalStatusId': SurveyRenewalStatusId
    };
    return this.http.post('/api/Survey/SubmitRenewal', obj);
  }

  surveyRenewalIgnored(surveyRenewalId: number): Observable<any> {
    const minutes = 120;
    const d = new Date();
    const dateString = (d.getMonth() + 1) + '/' + d.getDate() + '/' + d.getFullYear();
    d.setMinutes(d.getMinutes() + minutes);
    const expireDate = d;
    this.cookieService.set('surveyrenewal' + surveyRenewalId, dateString, { 'expires': expireDate });

    const obj: any = {
      'SurveyRenewalId': surveyRenewalId,
      'RenewalEventText': 'Renewal Ignored'
    };
    return this.http.post('/api/Survey/SubmitRenewal', obj);
  }
}
