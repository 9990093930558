import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'boldMatch'
})
export class BoldPipe implements PipeTransform {

  constructor(
    private sanitizer: DomSanitizer
  ) {}

  transform(value: string|null, regex: string|null): string|null {
    return this.sanitize(this.replace(value, regex?.trim() ?? ''));
  }

  replace(str:string|null, regex:string|null): string|null {
    return str?.replace(new RegExp(`(${regex})`, 'gi'), '<b>$1</b>') ?? null;
  }

  sanitize(str:string|null): string|null {
    return this.sanitizer.sanitize(SecurityContext.HTML, str);
  }
}